import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogsRESTAPIUrl = 'https://zvolv.com/blog/wp-json/wp/v2/posts?_embed'
  blogsList: any = []

  dateFormatOptions: any = { year: 'numeric', month: 'long', day: 'numeric' };

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    this.httpService.get(this.blogsRESTAPIUrl)
      .subscribe(
        (data) => {
          this.blogsList = data
          this.blogsList = [...this.blogsList]
          this.blogsList.splice(3);
        },
        error => {
          console.log("Error fetching blogs list", error)
        }
      );
  }

  getFormattedDate(dateStr) {
    var d = new Date(dateStr);
    return d.toLocaleDateString("en-US", this.dateFormatOptions)
  }

}
