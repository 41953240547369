import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScheduleDemoModalComponent } from './schedule-demo-modal/schedule-demo-modal.component';
import { SavingsCalculatorModalComponent } from './savings-calculator-modal/savings-calculator-modal.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { SavingsCalculatorComponent } from './savings-calculator/savings-calculator.component';

import { RecaptchaModule } from 'ng-recaptcha';
import { ScheduleEnterpriseDemoModalComponent } from './schedule-enterprise-demo-modal/schedule-enterprise-demo-modal.component';
import { BlogsComponent } from './blogs/blogs.component';
import { DownloadManagerModalComponent } from './download-manager-modal/download-manager-modal.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    ScheduleDemoModalComponent,
    SavingsCalculatorModalComponent,
    TestimonialsComponent,
    BlogsComponent,
    SavingsCalculatorComponent,
    ScheduleEnterpriseDemoModalComponent,
    DownloadManagerModalComponent
  ],
  imports: [
    RecaptchaModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CarouselModule
  ],
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    ScheduleDemoModalComponent,
    ScheduleEnterpriseDemoModalComponent,
    SavingsCalculatorComponent,
    SavingsCalculatorModalComponent,
    TestimonialsComponent,
    BlogsComponent
  ],
  entryComponents: [
    ScheduleDemoModalComponent,
    ScheduleEnterpriseDemoModalComponent,
    SavingsCalculatorModalComponent,
    DownloadManagerModalComponent
  ]
})
export class SharedModule { }
