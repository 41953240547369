<div class="modal-header my-auto">
    <h4 class="card-title">Application for {{jobTitle}}</h4>
    <button type="button" class="float-right close p-3" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12 pt-2 pt-sm-0">
            <p class="para-desc">
                Please fill out the below form and our team will reach out to you shortly after reviewing your
                application.
            </p>
            <div class="custom-form mt-4">
                <div id="message"></div>

                <form [formGroup]=" applicationForm">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Name <span class="text-danger">*</span></label>
                            <i class="mdi mdi-account icons text-muted mx-auto my-auto"></i>
                            <input type="text" formControlName="name" class="form-control pl-5"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <label>Email <span class="text-danger">*</span></label>
                            <i class="mdi mdi-email-outline icons text-muted mx-auto my-auto"></i>
                            <input type="email" formControlName="email" class="form-control pl-5"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email
                                    address</div>
                            </div>
                        </div>

                        <div class="form-group col-12">
                            <label>Phone <span class="text-danger">*</span></label><br>
                            <input type="tel" formControlName="phone" class="form-control pl-5"
                                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Phone number is required</div>
                            </div>
                        </div>

                        <div class="form-group col-12">
                            <label>Resume / CV <span class="text-danger">*</span></label><br>
                            <input type="file" formControlName="resume" (change)="onFileChange($event)"
                                class="form-control pl-5" [ngClass]="{ 'is-invalid': submitted && f.resume.errors }" />
                            <div *ngIf="submitted && f.resume.errors" class="invalid-feedback">
                                <div *ngIf="f.resume.errors.required">Resume / CV is required</div>
                            </div>
                        </div>
                    </div>

                    <re-captcha #captchaRefDC="reCaptcha" (resolved)="resolved($event)"
                        siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ" size="invisible" name="dc_hiddenRecaptcha"
                        id="dc_hiddenRecaptcha">
                    </re-captcha>

                    <div *ngIf="!showSuccess" class="text-center mt-2">
                        <button class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="showLoader">
                            Apply
                            <i *ngIf="showLoader" class="mdi mdi-loading mdi-spin ml-1"></i>
                        </button>
                    </div>
                    <div *ngIf="showSuccess" class="alert alert-success mt-2" role="alert">
                        Thank you for applying with Zvolv. We will get back to you as soon as possible.
                        <button type="button" class="float-right close" aria-label="Close"
                            (click)="showSuccess = false;onReset();dismiss();">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div *ngIf="showError" class="alert alert-danger mt-2" role="alert">
                        <button type="button" class="float-right close" aria-label="Close" (click)="showError = false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        Error while submitting form. Please try again.
                        <div *ngIf="errorMsg">
                            {{errorMsg}}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>