import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

declare var $: any;

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  $video;

  customOwlOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    }
  }

  constructor(private modalService: NgbModal, public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    // Uncomment this to hide controls on video on mouseover/leave
    // this.$video = $('.video');
    // this.$video.on('mouseover', this.show);
    // this.$video.on('mouseleave', this.hide);
  }

  gotoZvolvAppSignup(): void {

    // Send data to Google analytics
    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/jemoCMb0lfABEOb10t4B");

    window.open("https://app.zvolv.com/signup/create", "_blank");
  }

  workEmail = null
  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
    modalRef.componentInstance.email = this.workEmail
  }

  show() {
    $(this).attr('controls', '');
  }

  hide() {
    var isPlaying = false;
    if (!$('.video').get(0).paused) {
      isPlaying = true;
    }
    if (!isPlaying) {
      $(this).removeAttr('controls');
    }
  }

}
