<!-- Navbar Start -->
<header id="topnav" class="defaultscroll sticky">
  <div
    *ngIf="showBanner"
    class="alert alert-primary p-1 m-0 align-items-center justify-content-center text-center l-light"
    style="border-radius: 0 !important; cursor: pointer"
    role="alert"
  >
    <span class="align-items-center justify-content-center text-center">
      <b>No-code for speed, Low-code for flexibility.</b>&nbsp;&nbsp;&nbsp;
      Build your first enterprise application in 100 hours.
      <a
        class="btn btn-sm btn-secondary ml-4"
        (click)="
          openDownloadCaseStudyModal(
            'https://zvolv.com/assets/documents/Zvolv-7-day-action-plan.pdf'
          )
        "
        ><b>Download 7-Day Action Plan <i class="mdi mdi-arrow-right"></i></b
      ></a>
    </span>
    <button
      type="button"
      class="float-right close"
      aria-label="Close"
      (click)="showBanner = false"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="w-100 bg-dark">
    <!-- Logo container-->
    <div>
      <a class="logo pl-lg-5" [routerLink]="['/home']">
        <img
          src="assets/images/branding/logo-zvolv-light.png"
          class="l-dark"
          height="32"
          alt=""
        />
        <img
          src="assets/images/branding/logo-zvolv-light.png"
          class="l-light"
          height="32"
          alt=""
        />
      </a>
    </div>
    <div class="buy-button pr-lg-5">
      <a (click)="openScheduleDemoModal()">
        <div class="btn btn-primary">Schedule a demo</div>
      </a>
    </div>
    <!--end login button-->
    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a
          class="navbar-toggle"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-controls="navigation"
        >
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation" class="navbar-collapse collapse">
      <!-- Navigation Menu-->
      <ul class="navigation-menu">
        <li
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="ultr" [routerLink]="['/why-zvolv']">Why Zvolv?</a>
        </li>
        <li
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="ultr" [routerLink]="['/platform']">Platform</a>
        </li>

        <li
          class="has-submenu"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="dropdown-toggle-z" [routerLink]="['/solutions']"
            >Solutions</a
          ><span class="menu-arrow"></span>
          <ul class="submenu megamenu p-4">
            <li>
              <ul>
                <li><span class="font-weight-bold mb-5">By Industry</span></li>
                <li>
                  <a fragment="finance" [routerLink]="['/solutions']"
                    >Finance</a
                  >
                </li>
                <li>
                  <a fragment="sales" [routerLink]="['/solutions']"
                    >Sales and Marketing</a
                  >
                </li>
                <li>
                  <a fragment="scm" [routerLink]="['/solutions']"
                    >Supply Chain Management</a
                  >
                </li>
                <li><a fragment="hr" [routerLink]="['/solutions']">HR</a></li>
                <li>
                  <a fragment="support" [routerLink]="['/solutions']"
                    >Operations and Support</a
                  >
                </li>
                <li>
                  <a fragment="retail" [routerLink]="['/solutions']">Retail</a>
                </li>
                <li>
                  <a fragment="fab" [routerLink]="['/solutions']">F & B</a>
                </li>
                <li>
                  <a fragment="manufacturing" [routerLink]="['/solutions']"
                    >Manufacturing</a
                  >
                </li>
                <li>
                  <a fragment="healthcare" [routerLink]="['/solutions']"
                    >Healthcare</a
                  >
                </li>
                <li>
                  <a fragment="fmcg" [routerLink]="['/solutions']">FMCG/CPG</a>
                </li>
                <li>
                  <a fragment="services" [routerLink]="['/solutions']"
                    >Services</a
                  >
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <span class="font-weight-bold mb-5">By Application</span>
                </li>
                <li>
                  <a fragment="expansion" [routerLink]="['/solutions']"
                    >Expansion</a
                  >
                </li>
                <li>
                  <a fragment="auditing" [routerLink]="['/solutions']"
                    >Auditing</a
                  >
                </li>
                <li>
                  <a fragment="innovation" [routerLink]="['/solutions']"
                    >Innovation</a
                  >
                </li>
                <li>
                  <a fragment="planning" [routerLink]="['/solutions']"
                    >Planning</a
                  >
                </li>
                <li>
                  <a fragment="execution" [routerLink]="['/solutions']"
                    >Execution</a
                  >
                </li>
                <li>
                  <a fragment="operations" [routerLink]="['/solutions']"
                    >Operations</a
                  >
                </li>
                <li>
                  <a fragment="projects" [routerLink]="['/solutions']"
                    >Projects</a
                  >
                </li>
                <li>
                  <a fragment="optimization" [routerLink]="['/solutions']"
                    >Optimization</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="ultr" [routerLink]="['/pricing']">Pricing</a>
        </li>

        <li
          class="has-submenu"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="dropdown-toggle-z" href="javascript:void(0)">Resources</a
          ><span class="menu-arrow"></span>
          <ul class="submenu p-3">
            <li><a href="https://zvolv.com/blog">Blog</a></li>
            <li><a [routerLink]="['/newsroom']">Press & Media</a></li>
            <li>
              <a [routerLink]="['/partners/become-a-partner']"
                >Become A Partner</a
              >
            </li>
            <li><a [routerLink]="['/faq']">FAQs</a></li>
            <li>
              <a [routerLink]="['/docs/api']" target="_blank">Zvolv API</a>
            </li>
            <li><a href="https://app.zvolv.com/help">Documentation</a></li>
            <li><a [routerLink]="['/about-us']">About Us</a></li>
            <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
          </ul>
        </li>

        <li class="buy-button">
          <a class="ultr login-btn-primary" (click)="gotoZvolvAppSignup()"
            >Sign Up</a
          >
          <a
            class="ultr login-btn-light"
            href="https://app.zvolv.com/signup/find"
            >Login</a
          >
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->
  </div>
</header>
<!--end header-->
<!-- Navbar End -->
