<!-- Start Home -->
<section class="d-table w-100 h-100 overflow-hidden bg-dark pb-0" id="home">
  <div class="container-hero position-relative">
    <div class="row align-items-center pt-5">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">Hyper-Automation, Delivered.</h1>
          <p class="para-desc text-muted">
            Accelerate digital evolution by transforming complex processes for
            speed and scale using our low-code unified platform.
          </p>
          <p class="para-desc text-muted">
            <span class="text-primary">Zvolv</span> drives business process
            innovation and operational excellence with intelligent applications,
            personalized in days.
          </p>
          <div class="watch-video mt-4">
            <a
              (click)="openScheduleDemoModal()"
              class="btn btn-primary mb-2 mr-2"
              >Schedule a demo</a
            >
            <a
              [routerLink]="['/intro']"
              class="text-light mb-2 ml-2 mt-3 mr-2 ultr"
            >
              <b>See how it works <i class="mdi mdi-arrow-right"></i></b
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative bg-half-170">
          <div class="bg-saas-shape position-relative">
            <img
              src="assets/images/landing/-why-zvolv.png"
              class="img-fluid mover mx-auto d-block"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Home -->

<!-- Anouncements Start -->
<section class="section bg-primary pt-4 pb-4 page-bottom" id="survey">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-12">
        <!-- <h5 class="text-dark">No-code for speed, Low-code for flexibility, a managed Platform as a Service to do all the heavy lifting for scale, security and compliances.</h5> -->
        <h5 class="text-dark">Zai Orchestration: Where AI Meets Trusted Automation</h5>
        <h6 class="text-dark">
          Delivering AI driven trust and precision for your automations.
        </h6>
      </div>
      <div class="col-md-4 col-12 my-auto">
        <a href="https://zvolv.com/blog/zai/" class="btn btn-light" target="_blank">
          Explore Zai <i class="mdi mdi-chevron-right"></i>
        </a>
        <!-- <button
          (click)="
            openDownloadCaseStudyModal(
              'https://zvolv.com/assets/documents/Zvolv-7-day-action-plan.pdf'
            )
          "
          class="btn btn-light"
        >
          Explore Zai <i class="mdi mdi-chevron-right"></i>
        </button> -->
      </div>
    </div>
  </div>
</section>
<!-- Anouncements End -->

<!-- Features Start -->
<section class="section overflow-hidden bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2 pt-4">
          <h2 class="title mb-4">
            <span class="text-dark"
              ><span class="text-primary">Zvolv</span> is a low-code unified
              platform to drive hyper automation for enterprises</span
            >
          </h2>
          <p class="text-gray">
            Speed-up your digital transformation by streamlining processes
            across the organisation with the combined use of no-code/low-code
            application development, intelligent automation, end-end
            integrations, and impactful analytics.
          </p>
        </div>
      </div>
    </div>

    <div class="row pt-4">
      <div class="col-md-4 col-12 mt-5 mt-sm-0">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/decision-making.svg"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-5">
            <h4 class="title-2">Decisions, Automated</h4>
            <p class="text-gray mt-4 mb-0">
              Integrate human like decision making automation, orchestrate
              processes across systems.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/cloud.svg"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-5">
            <h4 class="title-2">Intelligence, Amplified</h4>
            <p class="text-gray mt-4 mb-0">
              Tackle last mile intelligent automation challenges that existing
              ERP, BPM or RPA tools cannot.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12 mt-5 mt-sm-0">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/acceleration.svg"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-5">
            <h4 class="title-2">Evolution, Accelerated</h4>
            <p class="text-gray mt-4 mb-0">
              Innovate 10x faster than with legacy IBPMS suites with no
              developer or IT dependency.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Features End -->

<!-- In the media Start -->
<!-- <section class="section bg-dark pb-0" id="survey">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="text-center mb-4 pb-2 pt-4">
                    <h2 class="title mb-4">Featured In</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <owl-carousel-o [options]="customOwlOptions">
                    <ng-template carouselSlide *ngFor="let newsItem of newsData;">
                        <div class="card border rounded mx-2">
                            <div class="row align-items-center">
                                <div class="col-12 text-center">
                                    <div class="image position-relative d-inline-block">
                                        <img [src]="newsItem.imageURL"
                                            style="max-width: 100px !important; max-height: 175px !important;"
                                            class="avatar avatar-small" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="card-body content pt-1">
                                <h5 class="max-lines-2"><a [href]="newsItem.link" class="card-title title text-dark"
                                        target="_blank">{{newsItem.title}}</a></h5>
                                <div class="text-muted post-meta d-flex justify-content-between mt-2">
                                    <small class="date"><i class="mdi mdi-calendar-check"></i>
                                        {{newsItem.date_published}}</small>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section> -->
<!-- In the media End -->

<!-- Platform Features Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h2 class="title mb-4">
            Here is what makes <span class="text-primary">Zvolv</span> great.
          </h2>
          <p class="text-gray para-desc mb-0 mx-auto">
            Analyze, plan, streamline, optimize and innovate with Zvolv unified
            platform.<br />
            Application development is simplified with the Zvolv platform for
            enterprises.
          </p>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-md-7 col-12 mt-4 pt-2">
        <div
          class="tab-content mr-lg-4 saas-feature-shape-left position-relative"
          id="pills-tabContent"
        >
          <!-- <div class="tab-pane fade show active" id="process-mapper" role="tabpanel" aria-labelledby="processmapper">
                        <img src="assets/images/product-screens/process.png" class="img-fluid mx-auto d-block" alt="">
                    </div> -->
          <div
            class="tab-pane fade show active"
            id="ui-builder"
            role="tabpanel"
            aria-labelledby="uibuilder"
          >
            <img
              src="assets/images/product-screens/process.png"
              class="img-fluid mx-auto d-block"
              alt=""
            />
          </div>
          <div
            class="tab-pane fade"
            id="automation-engine"
            role="tabpanel"
            aria-labelledby="automationengine"
          >
            <img
              src="assets/images/product-screens/automation.png"
              class="img-fluid mx-auto d-block"
              alt=""
            />
          </div>
          <div
            class="tab-pane fade"
            id="integration-builder"
            role="tabpanel"
            aria-labelledby="integrationbuilder"
          >
            <img
              src="assets/images/product-screens/collaboration.png"
              class="img-fluid mx-auto d-block"
              alt=""
            />
          </div>
          <!-- <div class="tab-pane fade" id="collab-board" role="tabpanel"
                        aria-labelledby="collabboard">
                        <img src="assets/images/product-screens/collaboration.png" class="img-fluid mx-auto d-block"
                            alt="">
                    </div> -->
          <div
            class="tab-pane fade"
            id="reporting-f"
            role="tabpanel"
            aria-labelledby="reporting"
          >
            <img
              src="assets/images/product-screens/reporting.png"
              class="img-fluid mx-auto d-block"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="col-md-5 col-12 mt-4 pt-2">
        <ul
          class="nav nav-pills bg-white nav-justified flex-column mb-0"
          id="pills-tab"
          role="tablist"
        >
          <!-- <li class="nav-item bg-light rounded-md mt-4">
                        <a class="nav-link rounded-md active" id="processmapper" data-toggle="pill" href="#process-mapper"
                            role="tab" aria-controls="process-mapper" aria-selected="false">
                            <div class="p-3 text-left">
                                <h5 class="title">Process Builder</h5>
                                <p class="text-gray tab-para mb-0">Visual, intuitive, no-code process builder to deliver the speed of application development.</p>
                            </div>
                        </a>
                    </li> -->
          <li class="nav-item bg-light rounded-md mt-4">
            <a
              class="nav-link rounded-md active"
              id="ui-builder"
              data-toggle="pill"
              href="#ui-builder"
              role="tab"
              aria-controls="ui-builder"
              aria-selected="false"
            >
              <div class="p-3 text-left">
                <h5 class="title">UI Builder</h5>
                <p class="text-gray tab-para mb-0">
                  Build and deliver the user journeys with the ease of drag drop
                  and done and connect to the process.
                </p>
              </div>
            </a>
          </li>
          <li class="nav-item bg-light rounded-md mt-4">
            <a
              class="nav-link rounded-md"
              id="automationengine"
              data-toggle="pill"
              href="#automation-engine"
              role="tab"
              aria-controls="automation-engine"
              aria-selected="false"
            >
              <div class="p-3 text-left">
                <h5 class="title">Automation</h5>
                <p class="text-gray tab-para mb-0">
                  Automation bot, low-code editor for developers to enhance the
                  application with the complex use case definitions.
                </p>
              </div>
            </a>
          </li>
          <li class="nav-item bg-light rounded-md mt-4">
            <a
              class="nav-link rounded-md"
              id="integration-builder"
              data-toggle="pill"
              href="#integration-builder"
              role="tab"
              aria-controls="integration-builder"
              aria-selected="false"
            >
              <div class="p-3 text-left">
                <h5 class="title">Integration Builder</h5>
                <p class="text-gray tab-para mb-0">
                  Connect and expand the enterprise application with the ease of
                  integrating with wide variety of enterprise systems.
                </p>
              </div>
            </a>
          </li>
          <!-- <li class="nav-item bg-light rounded-md mt-4">
                        <a class="nav-link rounded-md" id="collabboard" data-toggle="pill" href="#collab-board"
                            role="tab" aria-controls="collab-board" aria-selected="false">
                            <div class="p-3 text-left">
                                <h5 class="title">Collaboration Hub</h5>
                                <p class="text-gray tab-para mb-0">Simple, mobile-friendly task and user journey screens for business users to operate on the applications.</p>
                            </div>
                        </a>
                    </li> -->
          <li class="nav-item bg-light rounded-md mt-4">
            <a
              class="nav-link rounded-md"
              id="reporting"
              data-toggle="pill"
              href="#reporting-f"
              role="tab"
              aria-controls="reporting-f"
              aria-selected="false"
            >
              <div class="p-3 text-left">
                <h5 class="title">Dashboards for decisions</h5>
                <p class="text-gray tab-para mb-0">
                  Dynamic dashboards, reports, and drill-down analytics for
                  decision-makers.
                </p>
              </div>
            </a>
          </li>
        </ul>
        <div class="mt-3 text-center">
          <a [routerLink]="['/platform']" class="btn btn-primary"
            >Explore <i class="mdi mdi-chevron-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Value proposition start -->
<section class="section bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h2 class="title mb-4">
            The <span class="text-primary">Zvolv</span> value proposition
          </h2>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row" id="pills-tab" role="tablist">
      <div class="col-md-4 mt-4 pt-2">
        <div class="card features-1 work-process border-0">
          <div class="icons rounded h1 text-center text-zv-1 px-3">
            <i class="mdi mdi-lightbulb-on-outline"></i>
          </div>
          <div class="card-body">
            <h4 class="title text-dark text-center">Delivering Enterprises</h4>
            <ul class="list-unstyled text-gray pl-3">
              <li class="mb-0">
                <span class="text-zv-1 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Transform complex processes at scale
              </li>
              <li class="mb-0">
                <span class="text-zv-1 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Accelerating growth
              </li>
              <li class="mb-0">
                <span class="text-zv-1 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Better operational efficiency
              </li>
              <li class="mb-0">
                <span class="text-zv-1 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Optimize resources
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features-2 work-process border-0">
          <div class="icons rounded h1 text-center text-zv-2 px-3">
            <i class="mdi mdi-robot"></i>
          </div>

          <div class="card-body">
            <h4 class="title text-dark text-center">
              Faster, Tailored Solutions
            </h4>
            <ul class="list-unstyled text-gray pl-3">
              <li class="mb-0">
                <span class="text-zv-2 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Customised to Needs
              </li>
              <li class="mb-0">
                <span class="text-zv-2 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >5x compared to the full-stack
              </li>
              <li class="mb-0">
                <span class="text-zv-2 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >2x compared to RAD
              </li>
              <li class="mb-0">
                <span class="text-zv-2 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Applications with intelligence
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div class="card features-3 work-process border-0">
          <div class="icons rounded h1 text-center text-zv-3 px-3">
            <i class="mdi mdi-rocket"></i>
          </div>

          <div class="card-body">
            <h4 class="title text-dark text-center">
              Unique Platform as a Service
            </h4>
            <ul class="list-unstyled text-gray pl-3">
              <li class="mb-0">
                <span class="text-zv-3 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Beyond BPM, RPA
              </li>
              <li class="mb-0">
                <span class="text-zv-3 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >End-End Integrated
              </li>
              <li class="mb-0">
                <span class="text-zv-3 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >Apps Deliver in days
              </li>
              <li class="mb-0">
                <span class="text-zv-3 h5 mr-2">
                  <i class="uim uim-check-circle"></i></span
                >AI/ML delivered
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!-- Value proposition end -->

<!-- Why us start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 order-1 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="mr-lg-4">
          <h2 class="title mb-3">Why Choose us ?</h2>
          <p class="para-desc text-gray">
            <span class="text-primary">Zvolv</span> couples the scale of
            well-defined processes with the agility of quick changes. Build your
            Zvolv applications in a matter of weeks, and iterate until the
            solution is optimized to your needs. Pair your organization's
            knowledge and experience to build processes that deliver peak
            performance and efficiency gain for the organization.
          </p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <div>
                  <h5>Built for Makers</h5>
                  <p class="text-gray mb-0">
                    <span class="text-primary">Zvolv</span> translates your
                    needs to application with a few clicks covering the process
                    project and tasks with built-in automation bots and dynamic
                    forms, accessible via mobile and web. No need to worry about
                    servers, databases, security, or scalability - Zvolv does
                    all the heavy lifting.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <div>
                  <h5>Loved by Enterprises</h5>
                  <p class="text-gray mb-0">
                    <span class="text-primary">Zvolv</span> apps accelerate the
                    delivery of key insights, assist in rapid decision-making
                    through digital applications, reduce execution timelines,
                    and automate repetitive, error-prone manual tasks. Your
                    automation can now evolve as fast as your business.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 pt-4">
              <a [routerLink]="['/why-zvolv']" class="btn btn-outline-primary"
                >Read More <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 order-2 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img
            src="assets/images/feature/why-zvolv.svg"
            class="img-fluid mx-auto d-block rounded"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why us start -->

<!-- Testi Start -->
<app-testimonials></app-testimonials>
<div class="row">
  <div class="col-lg-12 text-center col-md-4 mb-4 pb-2">
    <a [routerLink]="['/solutions']" class="btn btn-primary"
      >See Casestudies <i class="mdi mdi-chevron-right"></i
    ></a>
  </div>
</div>
<!-- Testi End -->

<!-- Feature Start -->
<section class="section bg-dark">
  <div class="container-fluid mb-0">
    <div
      class="bg-dark-decor-rtl d-none d-md-block position-absolute opacity-0-5"
    ></div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-12">
        <div>
          <div class="section-title mb-4 pb-2 pt-5">
            <h1 class="title font-weight-bold mb-0">Zvolv is Built For</h1>
            <h1 class="title font-weight-bold mb-0">
              <span class="text-primary typed-element-feat"></span>
            </h1>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-12">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-format-list-checks"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Get more done with less</h5>
                    <p class="para text-muted mb-0">
                      A leading supermarket chain saved > 20% on their
                      operational costs with an ML based resource optimizer and
                      task rostering engine.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-chart-areaspline"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Drive consistent growth</h5>
                    <p class="para text-muted mb-0">
                      A fast growing QSR was able to expand > 40% faster with
                      smart site selection and cross-functional process
                      orchestration.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-av-timer"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Improve operational efficiency</h5>
                    <p class="para text-muted mb-0">
                      A leader in cloud kitchens was able to reduce food wastage
                      by > 30% with better planning and menu engineering
                      processes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-calendar"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Plan and orchestrate better</h5>
                    <p class="para text-muted mb-0">
                      A large fashion retailer was able to automate planning and
                      execution processes to allow inventory replenishments 50%
                      faster.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-lightbulb-on-outline"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Accelerate product innovation</h5>
                    <p class="para text-muted mb-0">
                      A leading FMCG manufacturer was able to launch 30% more
                      new products every quarter with stage-gate product
                      development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Feature End -->

<!-- FAQ n Contact Start -->
<section class="section bg-animation-polygon polygon-gradient-2">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              How is <span class="text-primary">Zvolv</span> different from
              existing BPM and Workflow Management tools?
            </h5>
            <p class="answer text-gray mb-0">
              Zvolv is an unified platform that combines no-code BPM and
              workflow capabilities with Intelligent bot-based automation,
              enabling you to automate end-to-end with applications that combine
              human and bot tasks. This helps address the last mile automation
              challenges that are often the most complex, need high adaptability
              and add the most value.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              What is the advantage of <span class="text-primary">Zvolv</span>'s
              Intelligent Automation compared to traditional RPA tools?
            </h5>
            <p class="answer text-gray mb-0">
              Traditional RPA tools will help automate the repeatable and
              mundane data entry and file processing tasks, or legacy system
              integrations. Zvolv's Intelligent Automation bots help stretch
              your automation goals further beyond into the realms of
              data-driven planning, optimization, intelligent decision making
              and machine-learning driven prescriptive and predictive analytics.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              Why is the <span class="text-primary">Zvolv</span> no-code
              platform better compared to traditional application development?
            </h5>
            <p class="answer text-gray mb-0">
              Unlike traditional development approaches which take armies of
              developers and months to launch, Zvolv applications are ready to
              add value now, with little to no dependence IT teams. Zvolv
              provides unrivaled agility in application customization, offering
              a total cost of ownership that is unachievable with traditional
              solutions, and at 10x the speed.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              How do I find out if <span class="text-primary">Zvolv</span> is
              the right fit for building my process automation solution?
            </h5>
            <p class="answer text-gray mb-0">
              Process innovation and automation are key to drive efficiency,
              agility and growth. Off-the-shelf tools will rarely meet your
              differentiated and evolving needs. And custom developed tools take
              ages to build and are obsolete faster than they can add value.
              Zvolv connects your people, processes and systems with intelligent
              automation, tailored to your needs. With Zvolv, you focus on the
              what, not the how. Let's start today.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Savings calculator Start -->
  <app-savings-calculator></app-savings-calculator>
  <!-- Savings calculator End -->
</section>
<!--end section-->
<!-- FAQ n Contact End -->

<!-- Contact Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading ml-lg-4">
          <h4 class="mb-4">Have Question ? Get in touch!</h4>

          <div class="media contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="mdi mdi-email-outline mdi-24px mr-3"></i>
            </div>
            <div class="media-body content">
              <h4 class="title font-weight-bold mb-0">Email</h4>
              <a href="mailto:hello@zvolv.com" class="text-primary"
                >hello@zvolv.com</a
              >
            </div>
          </div>

          <div class="media contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="mdi mdi-phone mdi-24px mr-3"></i>
            </div>
            <div class="media-body content">
              <h4 class="title font-weight-bold mb-0">Phone</h4>
              <a href="tel:+91-7002587748" class="text-primary">
                +91-7002587748 (India)</a
              ><br />
              <a href="tel:+1-650-215-0220" class="text-primary"
                >+1-650-215-0220 (USA)</a
              >
            </div>
          </div>

          <div class="media contact-detail align-items-center mt-3">
            <div class="icon">
              <i class="mdi mdi-map-marker mdi-24px mr-3"></i>
            </div>
            <div class="media-body content">
              <h4 class="title font-weight-bold mb-0">Location</h4>
              <p class="text-gray mb-0">
                FC Road, Pune, India<br />
                Mountain View, CA, USA
              </p>
              <a
                href="https://goo.gl/maps/4kt1uNLpErUsuGn16"
                target="_blank"
                class="video-play-icon text-primary"
                >View on Google map</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card custom-form rounded shadow border-0">
          <div class="card-body">
            <div id="message"></div>
            <form [formGroup]="contactForm">
              <div class="form-row">
                <div class="form-group col-6">
                  <label>Name <span class="text-danger">*</span></label>
                  <i
                    class="mdi mdi-account icons text-muted mx-auto my-auto"
                  ></i>
                  <input
                    type="text"
                    formControlName="name"
                    class="form-control pl-5"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                  />
                  <div
                    *ngIf="submitted && f.name.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.name.errors.required">Name is required</div>
                  </div>
                </div>
                <div class="form-group col-6">
                  <label>Email <span class="text-danger">*</span></label>
                  <i
                    class="mdi mdi-email-outline icons text-muted mx-auto my-auto"
                  ></i>
                  <input
                    type="email"
                    formControlName="email"
                    class="form-control pl-5"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  />
                  <div
                    *ngIf="submitted && f.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Comments <span class="text-danger">*</span></label>
                  <i
                    class="mdi mdi-comment-outline icons text-muted mx-auto my-auto"
                  ></i>
                  <textarea
                    name="comments"
                    id="comments"
                    rows="4"
                    class="form-control pl-5"
                    formControlName="comments"
                    [ngClass]="{ 'is-invalid': submitted && f.comments.errors }"
                    placeholder="Any specific requests"
                  ></textarea>
                  <div
                    *ngIf="submitted && f.comments.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.comments.errors.required">
                      Message is required
                    </div>
                  </div>
                </div>
              </div>

              <re-captcha
                #captchaRefCU="reCaptcha"
                (resolved)="resolved($event)"
                siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ"
                size="invisible"
                badge="inline"
                name="cf_hiddenRecaptcha"
                id="cf_hiddenRecaptcha"
              >
              </re-captcha>

              <div *ngIf="!showSuccess" class="text-center">
                <button
                  class="btn btn-primary btn-block"
                  (click)="onSubmit()"
                  [disabled]="showLoader"
                >
                  Send
                  <i *ngIf="showLoader" class="mdi mdi-loading mdi-spin"></i>
                </button>
              </div>
              <div
                *ngIf="showSuccess"
                class="alert alert-success mt-2"
                role="alert"
              >
                Thank you for contacting us. Our representative will reach out
                to you soon.
                <button
                  type="button"
                  class="float-right close"
                  aria-label="Close"
                  (click)="showSuccess = false; onReset()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                *ngIf="showError"
                class="alert alert-danger mt-2"
                role="alert"
              >
                <button
                  type="button"
                  class="float-right close"
                  aria-label="Close"
                  (click)="showError = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                Error while submitting form. Please try again.
                <div *ngIf="errorMsg">
                  {{ errorMsg }}
                </div>
              </div>
            </form>
            <!--end form-->
          </div>
        </div>
        <!--end custom-form-->
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->

<!-- Shape Start -->
<div class="position-relative d-sm-none d-md-none" style="z-index: -2">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 100L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
