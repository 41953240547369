import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Typed from 'typed.js';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss']
})
export class PlatformComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    nav: false
  }

  constructor(private meta: Meta, private modalService: NgbModal, public googleAnalyticsService: GoogleAnalyticsService) {
    this.meta.addTag({ name: 'description', content: 'Zvolv – Platform to find the most trusted way to choose No Code Application development for optimally upgraded automation processes for your business.' });
    this.meta.addTag({ name: 'keywords', content: 'No Code Automation' });
  }

  ngOnInit(): void {
    const options = {
      strings: ['Analyze', 'Plan', 'Streamline', 'Optimize'],
      typeSpeed: 100,
      backSpeed: 100,
      showCursor: true,
      cursorChar: '|',
      loop: true
    };

    const typed = new Typed('.typed-element-pfeat', options);
  }

  gotoZvolvAppSignup(): void {
    
    // Send data to Google analytics
    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/jemoCMb0lfABEOb10t4B");

    window.open("https://app.zvolv.com/signup/create", "_blank");
  }

  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
  }

}
