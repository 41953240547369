import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http/http.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-schedule-enterprise-demo-modal',
  templateUrl: './schedule-enterprise-demo-modal.component.html',
  styleUrls: ['./schedule-enterprise-demo-modal.component.scss']
})

export class ScheduleEnterpriseDemoModalComponent implements OnInit {

  @ViewChild('captchaRefRED') public captchaRefRED: RecaptchaComponent;

  requestDemoForm: FormGroup;
  submitted = false;
  showLoader = false;
  showSuccess = false
  showError = false
  errorMsg = null

  @Input()
  email: any = null

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private httpService: HttpService, public googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.requestDemoForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: [this.email, [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      company: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.requestDemoForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.requestDemoForm.invalid) {
      return;
    }

    // Invoke captcha. This will invoke submit form API on resolving captcha key.
    this.captchaRefRED.execute()
  }

  onReset() {
    this.requestDemoForm.reset();
    this.submitted = false;
  }

  public captchaResponse: string = '';
  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse
    this.submitForm(this.captchaResponse)
  }

  resetVar() {
    this.showSuccess = false
    this.showError = false
    this.errorMsg = null
  }

  submitForm(captcha) {

    this.resetVar()
    this.showLoader = true

    // 1. Get reCaptcha key (via recaptcha lib)
    // 2. Get QR Code
    var getQRCodeUrl = 'https://app.zvolv.com/rest/v17/decode/qr/4C2PU2JCCU72Q'
    var reqheaders = {
      businessDomain: 'sales'
    }
    var qrapidata = { "g-recaptcha-response": captcha }
    this.httpService.postConfig(getQRCodeUrl, JSON.stringify(qrapidata), reqheaders)
      .subscribe(
        (data) => {

          this.showLoader = false

          if (data && !data['error']) {
            var loginToken = data['data']['UserDetails']['loginToken']

            // 3. Submit form
            var submitFormUrl = 'https://app.zvolv.com/rest/v17/5WGCU2Z62RZV3/forms/22374/submissions/'
            var reqheaders = {
              'Content-Type': 'application/json',
              jwt: loginToken,
              LoginToken: loginToken,
              businessDomain: 'sales'
            }
            var submitformapidata = {
              "FormData": {
                "type": "KEY_LABELS",
                "data": {
                  "Name": this.requestDemoForm.value.name,
                  "Email": this.requestDemoForm.value.email,
                  "Phone Number": this.requestDemoForm.value.phone,
                  "Company": this.requestDemoForm.value.company,
                  "Company Size": this.requestDemoForm.value.companySize,
                  "Industry": this.requestDemoForm.value.industry,
                  "Application Details": this.requestDemoForm.value.applicationDetails,
                  "Integrations": this.requestDemoForm.value.integrations,
                  "Server Hosting": this.requestDemoForm.value.hosting
                }
              }
            }
            this.httpService.postConfig(submitFormUrl, JSON.stringify(submitformapidata), reqheaders)
              .subscribe(
                (data) => {

                  this.showLoader = false

                  if (data && data['error']) {
                    this.showError = true
                    this.errorMsg = data['message']
                  }
                  else {
                    this.showSuccess = true

                    // Send data to Google analytics
                    // this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/ye4CL_tPABEOb10t4B");
                    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/ubcVCIaB1_EBEOb10t4B");
                  }
                },
                error => {
                  this.showLoader = false
                  this.showError = true
                  this.errorMsg = error
                  console.log("Error submitting form", error)
                }
              );
          }
        },
        error => {
          this.showLoader = false
          this.showError = true
          this.errorMsg = error
          console.log("Error getting QR Code of form", error)
        }
      );
  }

  dismiss() {
    this.activeModal.dismiss()
  }

}