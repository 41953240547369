<div class="container mt-100 mt-60">
    <div class="rounded bg-primary p-lg-5 p-4">
        <div class="row align-items-end">
            <div class="col-md-8">
                <div class="section-title text-md-left text-center">
                    <b><h4 class="text-dark sc-title">Calculate Your Savings With Zvolv</h4></b>
                    <br>
                    <p class="mb-0 text-dark">
                        Get a detailed total cost of ownership and time to launch comparison, tailored to your
                        process.<br>
                        What will you do with the time and money you save?
                    </p>
                </div>
            </div>

            <div class="col-md-4 my-auto">
                <div class="text-md-right text-center">
                    <button (click)="openSavingsCalculatorModal()" class="btn btn-light">Calculate My Savings</button>
                </div>
            </div>
        </div>
    </div>
</div>