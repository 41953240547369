import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zvolv-vs-alternatives',
  templateUrl: './zvolv-vs-alternatives.component.html',
  styleUrls: ['./zvolv-vs-alternatives.component.scss']
})
export class ZvolvVsAlternativesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
