<!-- Hero Start -->
<section class="d-table w-100 overflow-hidden bg-dark" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="title-heading text-center mt-4 mt-md-5 pt-md-5">
          <h1 class="heading mb-3">Zvolv Careers</h1>
          <p class="para-desc mx-auto text-muted mb-4">
            Challenge the limits of your career
          </p>
          <a [routerLink]="['/careers/job-list']" class="btn btn-primary"
            ><b>See all jobs <i class="mdi mdi-arrow-right"></i></b
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="card features-2 work-process border-0">
          <div class="card-body">
            <h4 class="title text-dark">Who we are ?</h4>
            <p class="text-gray">
              At <span class="text-primary">Zvolv</span>, we believe anyone
              within an enterprise should be able to design, build and deploy
              trusted enterprise-grade business applications at rapid speed.
              This is key to overcoming the The Great Digitisation Challenge we
              are facing in the post-pandemic era.
            </p>
            <p class="text-gray">
              We are passionate about enabling these enterprise applications
              that enhance and transform how companies interact with their own
              employees, customers, partners, and other stakeholders.
            </p>
            <div class="text-center">
              <img
                src="assets/images/careers/who-we-are.svg"
                width="100%"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card features-3 work-process border-0">
          <div class="card-body">
            <h4 class="title text-dark">Our Culture</h4>
            <p class="text-gray">
              We strive to not only bring equality in the technology usage, but,
              also believe in a workplace that reflects the society through
              inclusive programs and benefits.
            </p>
            <p class="text-gray">
              <span class="text-primary">Zvolv</span> is an Equal Employment
              Opportunity Employer. Qualified applicants will receive
              consideration for employment without regard to race, colour,
              religion, sex, sexual orientation, gender perception or identity,
              age, marital status, or disability status.
            </p>
            <div class="text-center">
              <img
                src="assets/images/careers/culture.svg"
                width="100%"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section bg-animation-polygon polygon-gradient-2">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">
            Why you should work at <span class="text-primary">Zvolv</span>?
          </h4>
          <h6 class="text-gray para-desc mx-auto mb-0">
            Here are some reasons — just to get started with.
          </h6>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 mt-4 pt-2">
        <div
          class="card features feature-clean bg-transparent process-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center text-primary px-3">
            <i class="mdi mdi-creation"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">Be part of <br />a unique culture</h5>
            <p class="text-gray mb-0">
              With our environment encouraging creativity and innovation
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-4 pt-2">
        <div
          class="card features feature-clean bg-transparent process-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center text-primary px-3">
            <i class="mdi mdi-chart-line-variant"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">Challenge <br />yourself</h5>
            <p class="text-gray mb-0">
              With ever changing nature of the problems and their solutions
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-4 pt-2">
        <div
          class="card features feature-clean bg-transparent d-none-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center text-primary px-3">
            <i class="mdi mdi-lightbulb-on-outline"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">Explore on <br />your own</h5>
            <p class="text-gray mb-0">
              With our belief in freedom and autonomy
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-4 pt-2">
        <div
          class="card features feature-clean bg-transparent d-none-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center text-primary px-3">
            <i class="mdi mdi-rocket"></i>
          </div>
          <div class="card-body">
            <h5 class="text-dark">Shape the future <br />of technology</h5>
            <p class="text-gray mb-0">
              With your values and expertise aligned with our vision
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Join Our Teams</h4>
          <h6 class="text-gray para-desc mx-auto mb-0">
            We are on a journey to change the world of technology upside-down.
            Explore our teams and join us in our journey.
          </h6>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mt-4 pt-2" *ngFor="let jType of jobsTypes">
        <div class="card shadow rounded border-0 overflow-hidden">
          <div class="card-body">
            <h5 class="card-title">{{ jType.title }}</h5>
            <div [innerHTML]="jType.description"></div>
            <p class="card-text" *ngIf="jobsHashMap[jType.title]">
              <a
                class="text-primary"
                [routerLink]="['/careers/job-list']"
                [queryParams]="{ category: jType.title }"
              >
                {{ jobsHashMap[jType.title] }} job opportunities in
                {{ jType.title }}
                <i class="mdi mdi-arrow-right"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="title-heading text-center mt-4 mt-md-5">
          <a [routerLink]="['/careers/job-list']" class="btn btn-primary"
            ><b>See all jobs <i class="mdi mdi-arrow-right"></i></b
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>

<re-captcha
  #captchaRefCT="reCaptcha"
  (resolved)="resolved($event)"
  siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ"
  size="invisible"
  badge="inline"
  name="cf_hiddenRecaptcha"
  id="cf_hiddenRecaptcha"
>
</re-captcha>

<!-- Shape Start -->
<div class="position-relative mt-5" style="z-index: -2">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 75L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
