<div class="modal-header">
    <h3><span class="text-primary">Zvolv</span> Savings Calculator</h3>
    <button type="button" class="close p-3" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body p-0 m-0">
    <div class="row mr-0 ml-0 justify-content-center">
        <div class="col-lg-10 p-4">
            <div id="casestudy">
                <p>
                    Compared to traditional custom application development,
                    Zvolv apps are more than 50% lower cost to develop and more than 3x faster to launch.
                </p>

                <div class="row justify-content-center align-content-center">
                    <div class="col-8">
                        <div class="text-center">
                            <img src="assets/images/pricing-cmp.png" height="250px" alt="">
                            <br>
                            <a target="_blank" href="/pricing-case-study" class="btn btn-sm btn-secondary m-2">
                                See case study <i class="mdi mdi-open-in-new"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5" id="calcform">
                <p class="mb-0 pb-0">
                    Tell us a bit about the process automation application you have in mind and we can provide a
                    detailed comparison report.
                </p>

                <div class="p-3">
                    <div class="form-row">
                        <div class="col-8">
                            <h6 class="mb-1">Number of User Activities</h6>
                            <p class="text-muted mb-1">tasks, forms, approvals</p>
                        </div>
                        <div class="col-3">
                            <div class="form-group position-relative">
                                <input name="numTasks" type="number" [(ngModel)]="formData.numTasks"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control border-radius-0 border-0 border-bottom" placeholder="#tasks">
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-2">
                        <div class="col-8">
                            <h6 class="mb-1">Number of automation bots</h6>
                            <p class="text-muted mb-1">data movement, analytics, predictions, decision-support
                            </p>
                        </div>
                        <div class="col-3">
                            <div class="form-group position-relative">
                                <input name="numBots" type="number" [(ngModel)]="formData.numBots"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control border-radius-0 border-0 border-bottom"
                                    placeholder="#bots">
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-2">
                        <div class="col-8">
                            <h6 class="mb-1">Number of dashboards</h6>
                            <p class="text-muted mb-1">charts, pivot tables, milestone trackers</p>
                        </div>
                        <div class="col-3">
                            <div class="form-group position-relative">
                                <input name="numWidgets" type="number" [(ngModel)]="formData.numWidgets"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control border-radius-0 border-0 border-bottom"
                                    placeholder="#widgets">
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-2">
                        <div class="col-8">
                            <h6 class="mb-1">Number of external API integrations</h6>
                            <p class="text-muted mb-1">Databases, web services etc.</p>
                        </div>
                        <div class="col-3">
                            <div class="form-group position-relative">
                                <input name="numIntegrations" type="number" [(ngModel)]="formData.numIntegrations"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control border-radius-0 border-0 border-bottom"
                                    placeholder="#integrations">
                            </div>
                        </div>
                    </div>

                    <h6 class="mt-4">Deployment type?</h6>
                    <div class="form-row mt-2">
                        <div class="custom-control custom-radio custom-control-inline">
                            <div class="form-group mb-0">
                                <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"
                                    [value]="'Zvolv Managed Cloud'" [(ngModel)]="formData.deploymentType">
                                <label class="custom-control-label" for="customRadio1">Zvolv Managed Cloud</label>
                            </div>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <div class="form-group mb-0">
                                <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"
                                    [(ngModel)]="formData.deploymentType" [value]="'Private Cloud'">
                                <label class="custom-control-label" for="customRadio2">Private Cloud</label>
                            </div>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <div class="form-group mb-0">
                                <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input"
                                    [(ngModel)]="formData.deploymentType" [value]="'On Premise'">
                                <label class="custom-control-label" for="customRadio3">On Premise</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mt-4">
                        <div class="col-4">
                            <div class="form-check">
                                <div class="form-group mb-0">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1"
                                            [(ngModel)]="formData.bots">
                                        <label class="custom-control-label" for="customCheck1">AI/ML bots?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-check">
                                <div class="form-group mb-0">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck2"
                                            [(ngModel)]="formData.analytics">
                                        <label class="custom-control-label" for="customCheck2">Drill-down
                                            analytics?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-check">
                                <div class="form-group mb-0">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck3"
                                            [(ngModel)]="formData.customDocuments">
                                        <label class="custom-control-label" for="customCheck3">Custom document
                                            generator?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-check">
                                <div class="form-group mb-0">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck4"
                                            [(ngModel)]="formData.sso">
                                        <label class="custom-control-label" for="customCheck4">SSO/AD
                                            integration?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-check">
                                <div class="form-group mb-0">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck5"
                                            [(ngModel)]="formData.apps">
                                        <label class="custom-control-label" for="customCheck5">Android/iOS apps?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-check">
                                <div class="form-group mb-0">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck6"
                                            [(ngModel)]="formData.whitelabelledApp">
                                        <label class="custom-control-label" for="customCheck6">Whitelabel
                                            applications?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <re-captcha #captchaRefSC="reCaptcha" (resolved)="resolved($event)"
                        siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ" size="invisible" badge="inline">
                    </re-captcha>

                    <div class="form-row mt-2">
                        <div class="col-12 text-center">
                            <button class="btn btn-primary" (click)="openSubmitFormModal()">Calculate
                                Savings</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #submitFormModal let-modal>
    <form class="p-4" [formGroup]="savingsCalcForm">
        <div class="form-row">
            <div class="col-12">
                <div class="foot-subscribe form-group position-relative">
                    <label>Email <span class="text-danger">*</span></label>
                    <i class="mdi mdi-email-outline icons text-muted mx-auto my-auto"></i>
                    <input type="email" formControlName="email" placeholder="Your email:" class="form-control pl-5"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is
                            required</div>
                        <div *ngIf="f.email.errors.email">Email must be
                            a valid email
                            address</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!showSuccess" class="text-center">
            <button class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="showLoader">Send detailed report
                <i *ngIf="showLoader" class="mdi mdi-loading mdi-spin"></i></button>
        </div>
        <div *ngIf="showSuccess" class="alert alert-success mt-2" role="alert">
            Thank you for contacting us. You'll receive an email shortly.
            <button type="button" class="float-right close" aria-label="Close"
                (click)="showSuccess = false; onReset(); modal.dismiss(); dismiss();">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="showError" class="alert alert-danger mt-2" role="alert">
            <button type="button" class="float-right close" aria-label="Close"
                (click)="showError = false; modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
            Error while submitting form. Please try again.
            <div *ngIf="errorMsg">
                {{errorMsg}}
            </div>
        </div>
    </form>
</ng-template>