<!-- Hero Start -->
<section class="d-table w-100 h-100 overflow-hidden bg-dark pb-0" id="home">
    <div class="container-hero position-relative">
    <div class="row align-items-center pt-5">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">
            Loved and trusted by all our customers and partners
          </h1>
          <p class="para-desc text-white-50">
            And we are ready to win you over too!
          </p>
          <div class="mt-4 pt-2">
            <a (click)="openScheduleDemoModal()" class="btn btn-primary"
              >Schedule a demo</a
            >
            <a
              (click)="gotoZvolvAppSignup()"
              class="text-light mb-2 ml-4 mt-3 mr-2 ultr"
              ><b>Try For Free <i class="mdi mdi-arrow-right"></i></b></a
            ><br /><br />
            <a
              href="#zvolv-intro-video"
              title="Zvolv - no-code process automation platform"
              data-vbtype="inline"
              data-autoplay="true"
              class="video-play-icon watch mt-4 ml-2 mb-2 venobox text-secondary"
              ><i
                class="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle title-dark text-white position-relative play play-iconbar"
              ></i>
              WATCH VIDEO</a
            >
            <div id="zvolv-intro-video" style="display: none">
              <video controls preload="metadata">
                <source src="assets/videos/Zvolv-Intro.mp4" type="video/mp4" />
                Your browser does not support playing video.
              </video>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative bg-half-170">
          <div class="bg-saas-shape position-relative">
            <img
              src="assets/images/landing/customers.png"
              class="img-fluid mover mx-auto d-block"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- By Application start -->
<!-- <section id="by-applications" class="section bg-animation-polygon polygon-gradient-1"> -->
<section id="by-applications" class="section">
  <div class="container-fluid plc-100 prc-100">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h2 class="title">Creating Value For Our Customers</h2>
          <p>In days and weeks instead of months or years.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 mt-4 pt-2 text-center">
        <ul
          class="nav nav-pills nav-justified flex-column flex-sm-row rounded"
          data-aos="fade-up"
          data-aos-duration="1200"
          id="pills-tab"
          role="tablist"
          style="background-color: transparent"
        >
          <li class="nav-item spacing">
            <a
              class="nav-link border rounded active"
              id="expansion-tab"
              data-toggle="pill"
              href="#expansion"
              role="tab"
              aria-controls="expansion"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Expansion</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="auditing-tab"
              data-toggle="pill"
              href="#auditing"
              role="tab"
              aria-controls="auditing"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Auditing</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="operations-tab"
              data-toggle="pill"
              href="#operations"
              role="tab"
              aria-controls="operations"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Operations</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="planning-tab"
              data-toggle="pill"
              href="#planning"
              role="tab"
              aria-controls="planning"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Planning</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="innovation-tab"
              data-toggle="pill"
              href="#innovation"
              role="tab"
              aria-controls="innovation"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Innovation</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="execution-tab"
              data-toggle="pill"
              href="#execution"
              role="tab"
              aria-controls="execution"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Execution</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="projects-tab"
              data-toggle="pill"
              href="#projects"
              role="tab"
              aria-controls="projects"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Projects</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="optimization-tab"
              data-toggle="pill"
              href="#optimization"
              role="tab"
              aria-controls="optimization"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Optimization</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->

          <li class="nav-item spacing">
            <a
              class="nav-link border rounded"
              id="logistics-tab"
              data-toggle="pill"
              href="#logistics"
              role="tab"
              aria-controls="logistics"
              aria-selected="false"
            >
              <div class="text-center pt-1 pb-1">
                <h4 class="title font-weight-normal mb-0">Logistics</h4>
              </div>
            </a>
            <!--end nav link-->
          </li>
          <!--end nav item-->
        </ul>
        <!--end nav pills-->
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <div
          class="tab-content saas-feature-shape-left position-relative"
          id="pills-tabContent"
        >
          <div
            class="tab-pane fade show active"
            id="expansion"
            role="tabpanel"
            aria-labelledby="expansion-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/expansion.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->
              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    A unicorn eyecare retailer on a break-neck expansion spree.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Smart site selection and new store opening processes,
                    coordinated across 8+ teams, 200+ activities. Process
                    orchestration and automation from identifying the right
                    sites, to managing all approvals and documents, to project
                    execution, automated merchandise planning, hiring and
                    training, legal and quality compliance processes. Slew of
                    integrations including CRM, ERP, call-center, partner
                    websites and more. Replaced 6 disparate tools and dozens of
                    tracking spreadsheets.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    500+ internal users including designers, project managers,
                    merchandisers, vendor managers and CxOs. Contextual access
                    to 200+ partners.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    1 week per team process including process mapping,
                    role-plays and training. 6 weeks to launch end-end solution
                    with 5 external integrations.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    Enabled launching a new outlet every other day! Over 40%
                    reduction in execution timelines, significant Capex savings
                    with coordinated procurement and just in time ordering.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/blog/wp-content/uploads/2020/06/NSO-Whitepaper-Zvolv.pdf',
                          'https://zvolv.com/assets/images/customer/expansion.jpg',
                          'Expansion'
                        );
                        $event.stopPropagation()
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->

          <div
            class="tab-pane fade"
            id="auditing"
            role="tabpanel"
            aria-labelledby="auditing-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/auditing.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    A leader in internal audits with a large national field
                    force and clients across industries and multiple countries.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    An audit automation platform that can be used to create
                    white-labeled audit apps for their end customers. The
                    platform has enabled launching 12+ audit applications for
                    revenue assurance, mystery shopping, stock taking,
                    facilities management, brand compliance and risk monitoring
                    for end clients, in a matter of days each. Applications
                    include audit specific workflows and forms, with
                    geo-tagging, product scanning, offline-mode, automated
                    schedular, digital signatures and other security features,
                    and is augmented by individualized intelligent automation
                    bots to highlight audit discrepancies and deviations.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    2000+ field auditors, reviewers and approvers, along with
                    contextual logins for end client stakeholders.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    4 weeks from concept to launch with audit configuration and
                    bot building training. Individual end customer audit
                    applications have been launched in roughly a week each.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    30% increase in audit efficiency, complete elimination of
                    paper based processes, multifold increase in audit accuracy
                    with automated deviation highlighting.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/blog/wp-content/uploads/2020/06/Zvolv-Audits-WhitePaper.pdf',
                          'https://zvolv.com/assets/images/customer/auditing.jpg',
                          'Auditing'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->

          <div
            class="tab-pane fade"
            id="operations"
            role="tabpanel"
            aria-labelledby="operations-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/operations.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    One of the world's most widely used food delivery app with a
                    large network of cloud kitchens.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Operations management platform with a suite of apps to
                    manage several aspects of cloud kitchen operations, from
                    smart menu innovation to partner onboarding and feedback,
                    maintenance and rostering processes. A slew of new
                    applications were introduced to tackle new challenges posed
                    by the Covid-19 pandemic. Extensive integration with other
                    data sources, consumer app and analytics platform.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    5000+ employees across 900+ kitchens, both company as well
                    as partner owned and operated. Users include kitchen staff,
                    quality and hygiene managers, food innovation teams and
                    CxOs.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    On average 1 week per app, over 15 apps built and deployed
                    over a 6 month period. Covid apps were evangelized and
                    launched in a matter of days to ensure continued operations.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    Enabled efficient operations as orders scaled up to over a
                    million per day. Enabled un-interrupted operations through
                    the Covid lockdown. Over 30% savings in food wastage with
                    better planning and tracking.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/blog/wp-content/uploads/2020/06/Zvolv-Foodtech-Case-Study.pdf',
                          'https://zvolv.com/assets/images/customer/operations.jpg',
                          'Operations'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->

          <div
            class="tab-pane fade"
            id="planning"
            role="tabpanel"
            aria-labelledby="planning-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/planning.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    India's top fashion retailer with 100s of large format
                    stores.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Planning and tracking inventory replenishment cycles, all
                    the way from mind to market for 40+ brands, 20+ drops per
                    year. Over 25k activities coordinated across 6 functions
                    every year. Automated data collection, communications with
                    internal and vendor teams, proactive escalation management
                    and drop planning. Web and mobile apps integrated with other
                    enterprise systems, installed on-premise.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    4000+ users across 700+ roles spanning designers, brand
                    managers, sourcing, operations and planning teams.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    3 weeks from concept to launch, with 4 process iterations
                    and doubling of the number of activities to be tracked along
                    the way.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    Drove twice as many inventory replenishments per season, and
                    more than 50% reduction in mind to market timelines,
                    resulting in better capital utilization and increased
                    customer delight with constantly refreshing inventory.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/blog/wp-content/uploads/2020/06/Retail-Reimagined-Zvolv-Whitepaper.pdf',
                          'https://zvolv.com/assets/images/customer/planning.jpg',
                          'Planning'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->

          <div
            class="tab-pane fade"
            id="innovation"
            role="tabpanel"
            aria-labelledby="innovation-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/innovation.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    A multi-brand FMCG company launching hundreds of food and
                    non-food packaged products every year.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Stage-gate based process management application to enable
                    launching products faster, first time right, and meeting
                    profitability metrics and safety/quality compliance.
                    Multiple process templates for different end products. 100+
                    activities tracked across 6 stage reviews. Automated review
                    documents, adaptive timelines based on product details,
                    prioritization based on revenue potential.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    300+ users across 7 roles spanning marketing, sales,
                    quality, brand managers, artwork, legal and planning teams.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    2 weeks from concept to launch, with multiple automation
                    bots added along the way at 2-3 days per bot.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    30% increase in new products launched per year, no product
                    recalls and 100% products meeting projections.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/blog/wp-content/uploads/2020/07/NPD-Case-Study-Zvolv.pdf',
                          'https://zvolv.com/assets/images/customer/innovation.jpg',
                          'Innovation'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->

          <div
            class="tab-pane fade"
            id="execution"
            role="tabpanel"
            aria-labelledby="execution-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/execution.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    One of the world's largest multi-format and multi-vertical
                    retailer.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Multiple interlinked applications to manage on-site project
                    execution and vendor activities, providing real-time
                    visibility into status, tracking efficiency and managing
                    escalations proactively. Enabling on-time and first time
                    right project execution. Multi-lingual support. Geo tagged
                    applications with offline mode support. Dozens of project
                    templates, automated fit to timelines, site formats and
                    execution teams.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    1000+ vendors, 300+ internal users including project
                    managers, auditors, vendor managers and CxOs.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    PM portal and vendor apps launched in 3 weeks from kick-off,
                    integrated audit and PM app launched in a week as need
                    arised 6 months later.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    Over 80% reduction in travel costs for project executives
                    due to real time site updates available at a click, 20%
                    reduction in project execution timelines due to timely
                    communications, handovers, escalations and elimination of
                    rework.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/blog/wp-content/uploads/2020/07/Projects-Case-Study-Zvolv.pdf',
                          'https://zvolv.com/assets/images/customer/execution.jpg',
                          'Execution'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->

          <div
            class="tab-pane fade"
            id="projects"
            role="tabpanel"
            aria-labelledby="projects-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/projects.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    One of the big 4 consulting firms managing a portfolio of
                    public sector government- funded healthcare projects.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Connecting people, processes, and data across multiple
                    portfolios of construction and engineering projects, with
                    intelligent, personalised mobile and web applications.<br />
                    Aimed at driving end-end portfolio visibility with
                    drill-down analytics, collaborative project delivery with
                    tracking for construction and procurement milestones, design
                    and construction coordination, document management, project
                    and portfolio spend management and cost controls.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    100s of project executives across construction sites
                    updating weekly progress as it happens.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    Less than one month from concept to go live, including
                    retrofitting legacy data from past months, extensive
                    dashboard customization and approval cycles from government
                    officials, and white-labeled mobile apps.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    End to end drill-down visibility into all aspects of the
                    entire portfolio, from real-time status updates, site
                    pictures, escalations and tracking of financial milestones.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/assets/documents/Zvolv-Construction-Case-Study.pdf',
                          'https://zvolv.com/assets/images/customer/projects.jpg',
                          'Projects'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->

          <div
            class="tab-pane fade"
            id="optimization"
            role="tabpanel"
            aria-labelledby="optimization-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/optimization.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    A leading chain of supermarkets with over 750 outlets,
                    majority- owned by the world's largest retailer.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Three interlinked applications, focused on resource
                    optimisation, employee rostering and daily task/activity
                    tracking respectively.
                  </p>
                  <ol class="text-gray">
                    <li>
                      The first application leverages intelligent automation
                      with machine learning and specialized business algorithms
                      to build and execute complex models used to forecast
                      resourcing requirements and task mapping.
                    </li>
                    <li>
                      The second application leverage business rules for task
                      allocations to present employees and store work breakdown
                      calendars at a weekly, daily, and slot-wise granularity.
                    </li>
                    <li>
                      The third module leveraged task and time management
                      functionality to track daily employee availability,
                      performance and feedback.
                    </li>
                  </ol>

                  <h6>Users</h6>
                  <p class="text-gray">
                    10000+ employees, 1000+ managers across 750 stores and
                    back-office planning, HR and CxO roles.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    Phase 1 involved data collection and sanitisation from the
                    past two years of sales performance, and was undertaken by a
                    big-4 partner. This was done over a periodof 2 months.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    On average, 15% reduction in variable resources per store
                    basis the Zvolv application predictions, and on-ground
                    performance over a quarter. This translates to over $10M in
                    annual savings in operational costs.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/assets/documents/Zvolv-IPA-casestudy.pdf',
                          'https://zvolv.com/assets/images/customer/optimization.jpg',
                          'Optimization'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <div
            class="tab-pane fade"
            id="logistics"
            role="tabpanel"
            aria-labelledby="logistics-tab"
          >
            <div class="row align-items-center">
              <div class="col-md-5">
                <img
                  src="assets/images/customer/logistics.jpg"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  class="img-fluid mx-auto d-block shadow rounded"
                  alt=""
                />
              </div>
              <!--end col-->

              <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-md-4">
                  <h6>Customer</h6>
                  <p class="text-gray">
                    A global leader in manufacturing fueling and convenience
                    store equipment and technology.
                  </p>

                  <h6>Application</h6>
                  <p class="text-gray">
                    Three interlinked applications, focused on tracking the
                    delivery of fuel dispensers across 4 continents, tracking
                    successful installation and any issues encountered,
                    technical support ticketing for any issues encountered and
                    managing warranty claims.
                  </p>

                  <h6>Users</h6>
                  <p class="text-gray">
                    100s of distributers and end customers across 4 continents,
                    back-office technical support, quality heads and logistics
                    managers.
                  </p>

                  <h6>Time to Launch</h6>
                  <p class="text-gray">
                    Under 3 weeks from concept to launch. Mobile applications
                    for field personnel and end customers for self-help.
                    Drill-down analytics and reporting via web dashboards for
                    back-office operations. Task tracking system for quality
                    heads and technical support personnel.
                  </p>

                  <h6>Value Delivered</h6>
                  <p class="text-gray">
                    Improved visibility of high-value equipment delivery and
                    installation status - from an original TAT for
                    issue/warranty claim reporting of over 6 months down to less
                    than 5 days on average. Self-help service for end customers
                    improves installation experience multi-fold.
                  </p>

                  <div class="mt-4" data-aos="fade-up" data-aos-duration="1800">
                    <a
                      (click)="
                        openDownloadCaseStudyModal(
                          'https://zvolv.com/assets/documents/Zvolv-Intelligent-Automation-In-Logistics-casestudy.pdf',
                          'https://zvolv.com/assets/images/customer/logistics.jpg',
                          'Logistics'
                        )
                      "
                      class="btn btn-light mr-3"
                      style="cursor: pointer"
                      >Read Whitepaper <i class="mdi mdi-chevron-right"></i
                    ></a>
                    <a
                      href="https://zvolv.com/blog"
                      class="text-warning p-1 px-2 shadow rounded"
                      >Blogs</a
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end teb pane-->
        </div>
        <!--end tab content-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</section>
<!-- By Application start -->

<!-- By Industry Start -->
<section
  id="by-industries"
  class="section bg-animation-polygon polygon-gradient-2-reverse"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h2 class="title">
            How Our Customers Use <span class="text-primary">Zvolv</span>
          </h2>
          <p>
            We help our customers make automation all pervasive across their
            enterprises,<br />and tear down any barriers to adoption. And do
            this with unrilaved speed and agility.<br /><br />
            Because seriously, waiting weeks or months for a change to happen is
            ridiculous.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <ul
        class="col container-filter list-unstyled categories-filter text-center"
        id="filter"
      >
        <li class="list-inline-item">
          <a
            class="categories cat-all border d-block text-dark rounded active"
            data-filter="*"
            >All</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-finance border d-block text-dark rounded"
            data-filter=".finance"
            >Finance</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-sales border d-block text-dark rounded"
            data-filter=".sales"
            >Sales and Marketing</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-scm border d-block text-dark rounded"
            data-filter=".scm"
            >Supply Chain and Procurement</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-hr border d-block text-dark rounded"
            data-filter=".hr"
            >HR</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-support border d-block text-dark rounded"
            data-filter=".support"
            >Operations and Support</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-retail border d-block text-dark rounded"
            data-filter=".retail"
            >Retail</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-fab border d-block text-dark rounded"
            data-filter=".fab"
            >F&B</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-manufacturing border d-block text-dark rounded"
            data-filter=".manufacturing"
            >Manufacturing</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-healthcare border d-block text-dark rounded"
            data-filter=".healthcare"
            >Healthcare</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-fmcg border d-block text-dark rounded"
            data-filter=".fmcg"
            >FMCG/CPG</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-projects border d-block text-dark rounded"
            data-filter=".projects"
            >Construction and Projects</a
          >
        </li>
        <li class="list-inline-item">
          <a
            class="categories cat-services border d-block text-dark rounded"
            data-filter=".services"
            >Services</a
          >
        </li>
      </ul>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container">
    <div id="by-industries" class="row container-grid projects-wrapper">
      <div
        id="retail"
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Ops Control Tower</h4>
            <p class="text-gray para">
              End to end visibility of data from various sources, task and
              approval statuses, tracking execution across tools, unified
              communications and escalations, centralized reporting.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Store Opening, Closing and Relocation</h4>
            <p class="text-gray para">
              Replace manual collaboration and project management with
              simplicity and automation guaranteed to reduce effort and
              timelines.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Operational Excellence</h4>
            <p class="text-gray para">
              Optimize manpower, simplify communication with store network,
              automate enhanced visibility into improvement measures.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Supplier Integration</h4>
            <p class="text-gray para">
              Speed up mind to market timelines. Give suppliers unparalleled
              visibility into demand, get more accurate lead times than ever
              before.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Allocation and Replenishment</h4>
            <p class="text-gray para">
              Identify stock-outs and overstocks early and recommend solutions
              based on inventory and order status, lead time and more.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Assortment Planning</h4>
            <p class="text-gray para">
              Simplify by incorporating predictions, enhancing visibility and
              enforcing strategies and policies through business rules.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail finance manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Demand Forecasting</h4>
            <p class="text-gray para">
              Incorporate advanced, granular insights into day to day decision
              making.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>
      <!-- Retail cards end -->

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail manufacturing fab finance"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Procurement Automation</h4>
            <p class="text-gray para">
              Automate Record Keeping across systems, Trigger Auto-replenishment
              based on complex criterion, Automate required Approvals by
              configuring decision engine, Automate processing Purchase
              Requisitions based on business rules.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing manufacturing">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Production planning and control</h4>
            <p class="text-gray para">
              Routing, scheduling and automated workflows for dispatching and
              follow up.
            </p>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing manufacturing">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Export-Import Processes</h4>
            <p class="text-gray para">
              Tracking of shipments from factory to foreign Customers or Foreign
              Vendors to factory.
            </p>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail manufacturing fab healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Employee Performance</h4>
            <p class="text-gray para">
              Setup and manage goals/KPIs, self and manager reviews, task-based
              feedback.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing manufacturing healthcare"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Quality Compliance</h4>
            <p class="text-gray para">
              Automate and standardize the unstructured components of compliance
              processes, improve adherence to corporate quality and compliance
              initiatives and avoid risks or fines.
            </p>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing manufacturing">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Materials Management</h4>
            <p class="text-gray para">
              Accurate tracking of all the materials in the inventory integrated
              with automated ordering, real time delivery tracking
            </p>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing manufacturing finance"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Financial Tracking</h4>
            <p class="text-gray para">
              Generate automated reconciliation reports, integrate with ERP to
              track payments received against invoices, avoid excels and human
              errors.
            </p>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing fab">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Central Recipe Bank & Menu Engineering</h4>
            <p class="text-gray para">
              Evaluate sales performance, design menus, automate production cost
              calculations, automate menu and nutrition publishing
            </p>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Event Management</h4>
            <p class="text-gray para">
              Plan and execute events with auto-task assignments & pre-defined
              checklists and training. Track documents, inventory, task
              progress, collect and analyze customer feedback.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Hygiene Audits & Quality Compliance</h4>
            <p class="text-gray para">
              Design and conduct safety audits to ensure adherence to the
              protocols. Conduct Brand Standards audits to ensure consistent
              customer experience. Conduct internal audits to stay complaint to
              the regulations. Conduct audits with Geo-fencing & Geo-tagging
              enabled to increase authenticity
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Digital SOP Logbooks</h4>
            <p class="text-gray para">
              View SOP Task completion statuses across stores. Automated
              Rostering of the employees based on the defined attributes like
              availability, preferred timings, type of the employee (part time
              or full time) etc. Design & assign singular or recurring
              checklists and tasks based on business rules. Auto assign incident
              & maintenance requests raised based on type and priority
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Inventory Automation</h4>
            <p class="text-gray para">
              Optimize inventory based on accurate demand forecasting. Highlight
              causes of waste (expiry, theft etc.) to auto-trigger actions.
              Comparison between actual vs planned to highlight discrepancies.
              Automate different types of requisitions reducing processing time.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab fmcg">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Brand Audits</h4>
            <p class="text-gray para">
              Drive consistency in branding, experience, identify risks,
              opportunities and inefficiencies.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">New Product Development</h4>
            <p class="text-gray para">
              Launch new products faster, first time right, meeting all safety,
              quality compliances. Incorporate stage gate based development and
              innovation funnel methodologies.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Integrated Sourcing</h4>
            <p class="text-gray para">
              Automate PR, RFx, PO, e-auctions, quote comparison and other
              vendor processes. Fill in gaps that exist in existing tools,
              enable real-time visibility across vendors.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Product lifecycle management</h4>
            <p class="text-gray para">
              Plan and track new product introductions from concept to shelves,
              across departments. Track performance relative to plans,
              auto-trigger reactive workflows based on deviations.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Construction and Fitout Projects</h4>
            <p class="text-gray para">
              Schedule, track and get real-time site updates. Automate resource
              and material estimation. Manage vendors and react pro-actively to
              escalations to deliver timely outcomes.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Employee Availability & Leave Management</h4>
            <p class="text-gray para">
              Setup and manage employee shifts, absence, overtime. Approval
              workflows and integration with HR systems.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Store Rostering & task management</h4>
            <p class="text-gray para">
              Automated Rostering of the employees based on the defined
              attributes like availability, preferred timings, type of the
              employee (part time or full time) etc. Design & assign singular or
              recurring checklists and tasks based on business rules.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Employee Attendance and time tracking</h4>
            <p class="text-gray para">
              Track and manage employee attendance, time spent on tasks, raise
              escalations for delays. Provide task level feedback and employee
              rating management.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">
              Facility opening, closing and other SOP checklists
            </h4>
            <p class="text-gray para">
              Build and measure against SOPs for various daily activities.
              Configure controls, automated task routing, escalations and
              instant communication for deviations.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Employee On-boarding and Off-boarding</h4>
            <p class="text-gray para">
              SOPs, Documentation, communication, timelines, training,
              engagement.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Benefits Administration And Compliances</h4>
            <p class="text-gray para">
              Workflows to collect and approve various requests, collect
              supporting data, automated communications and routing of
              activities.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Branch And Facilities Audit</h4>
            <p class="text-gray para">
              Setup and manage audit parameters and scoring logic, corrective
              actions, automated scheduling and deviation tracking.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail manufacturing finance"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Stock And Field Asset Verification</h4>
            <p class="text-gray para">
              Verify data against master data bases, highlight deviations,
              QR/barcode scanning and geo-tagging.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail manufacturing finance"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Revenue Assurance And Performance Audits</h4>
            <p class="text-gray para">
              Track quality of facilities and services, measure against agreed
              parameters, auto-scoring and classification of deviations.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Support Ticketing System</h4>
            <p class="text-gray para">
              Automated ticketing for support issues, intelligent routing and
              follow-up, collecting supporting documentation and proof of
              service.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance healthcare hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Helpdesk / Knowledge-Base</h4>
            <p class="text-gray para">
              Contextual access to FAQs, training material, reference manuals
              for employees and partners. Integrated with support ticketing
              processes.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing finance hr"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Travel Expensing</h4>
            <p class="text-gray para">
              Track and manage employee travel, automate expensing for field
              travel based on business rules, field staff tracking and checkin
              via GEO tagging.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Purchase Requisitions</h4>
            <p class="text-gray para">
              Direct entry or sync'ed with existing ERP system.
              Auto-classification - new, annual contract, part of approved
              budget or exceptions etc. CAPEX approvals workflows.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Vendor Quote Comparisons & Shortlisting</h4>
            <p class="text-gray para">
              Single screen compilation of RFx responses, Auto-allocation based
              on business rules, Manual over- ride with additional approvals.
              Integrated vendor management.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Finance Approvals</h4>
            <p class="text-gray para">
              Auto-identification of category/ routing for approvals. Business
              logic for approvals. Time based tracking and auto- follow-ups.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Purchase Order Enforcement</h4>
            <p class="text-gray para">
              Vendor PO issuance directly, or via existing ERP system. PO
              confirmation, Terms and conditions and delivery schedules
              agreement tracking and enforcement.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Vendor Tracking And Expediting</h4>
            <p class="text-gray para">
              Track delivery schedules and delays, ASNs, Gate-passes, Audits,
              approvals, expediting workflows for high value capital
              expenditures.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Payments And Reconciliations</h4>
            <p class="text-gray para">
              Track vendor accounts, deliveries, returns, pending payments.
              Synchronize with ERP system, manage communications.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing fab healthcare finance scm projects services"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">
              Vendor/Partner on-boarding and document management
            </h4>
            <p class="text-gray para">
              On-boarding process with document collection, updation and
              approval workflows, document repository, document expiry and
              change notifications, sharing and on-the-go access.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG/CPG</span>
            <span class="badge badge-lemon float-right mr-1 mt-1"
              >Supply Chain and Procurement</span
            >
            <span class="badge badge-wheat float-right mr-1 mt-1"
              >Construction and Projects</span
            >
            <span class="badge badge-plum float-right mr-1 mt-1">Services</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab sales support services"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Digital media campaign management</h4>
            <p class="text-gray para">
              Media creation approval and event launch processes including
              document repository, event and task planning, timeline
              enforcement, content and user list management.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-olive float-right mr-1 mt-1"
              >Sales and Marketing</span
            >
            <span class="badge badge-orchid float-right mt-1"
              >Operations and Support</span
            >
            <span class="badge badge-plum float-right mt-1">Services</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing sales services">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Sales Funnel Tracking</h4>
            <p class="text-gray para">
              Account and lead management, classification, funnel progression,
              deal identification, task tracking and follow-up reminders,
              integrations with email, call-logging and social media automation
              tools.
            </p>
            <span class="badge badge-olive float-right mt-1"
              >Sales and Marketing</span
            >
            <span class="badge badge-plum float-right mr-1 mt-1">Services</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail manufacturing projects support"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Construction Portfolio Management</h4>
            <p class="text-gray para">
              Tracking construction and procurement milestones, resource costs
              and actual vs planned spend across portfolio, classify by region,
              project types, automated reports and escalations, dynamic
              dashboards.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-wheat float-right mr-1 mt-1"
              >Construction and Projects</span
            >
            <span class="badge badge-orchid float-right mt-1"
              >Operations and Support</span
            >
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing finance projects">
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Risk Compliance</h4>
            <p class="text-gray para">
              Track and evaluate risk for financial products basis pre-defined
              parameters and measurement metrics, periodic audits and scoring,
              document collection and management, reactive processes for
              deviations from norm.
            </p>
            <span class="badge badge-success float-right mt-1">Finance</span>
            <span class="badge badge-wheat float-right mr-1 mt-1"
              >Construction and Projects</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing fmcg healthcare sales services"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Sales Goal Setting and Target Tracking</h4>
            <p class="text-gray para">
              Auto-allocate sales goals basis accounts, products, plan visits,
              track execution and conversion, integrated travel expensing and
              time/distance tracking, geo-tracking for check-ins, automated
              performance reports.
            </p>
            <span class="badge badge-dark float-right mt-1">FMCG/CPG</span>
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-olive float-right mr-1 mt-1"
              >Sales and Marketing</span
            >
            <span class="badge badge-plum float-right mr-1 mt-1">Services</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fmcg manufacturing fab finance healthcare hr sales support"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">
              Initiative tracking, meeting minutes and task execution tracking
            </h4>
            <p class="text-gray para">
              Track organisational initiatives around cost savings, efficiency
              or new products or campaigns, Track meeting minutes, assign and
              follow up on tasks and linkages to initiatives, track initiative
              progress with metrics and milestones.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG/CPG</span>
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
            <span class="badge badge-olive float-right mr-1 mt-1"
              >Sales and Marketing</span
            >
            <span class="badge badge-orchid float-right mr-1 mt-1"
              >Operations and Support</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance healthcare hr support"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">
              Multi-site finance and performance data collation and reporting
            </h4>
            <p class="text-gray para">
              Collect finance and operational data across sites on a regular
              cadence, track updates, escalate on deviations, prepare automated
              reports and drill-down analytics, integrate with HRMS and Finance
              ERP systems as required.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
            <span class="badge badge-orchid float-right mr-1 mt-1"
              >Operations and Support</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance healthcare support"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Daily utility logs</h4>
            <p class="text-gray para">
              Collect manpower and resource utilisation data from distributed
              sites on a daily basis, business rules to convert to spend and
              forecasting, reactive workflows to handle deviations, integrated
              training modules.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-orchid float-right mr-1 mt-1"
              >Operations and Support</span
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance healthcare support hr projects services"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Resource Estimation</h4>
            <p class="text-gray para">
              Intelligent automation utilising ML and extensive business rules
              and part task performance data to predict resourcing needs, broken
              down to employee type, skill ratings, shift utilisation, leave
              management. Detailed break-up of work-plans and utilisation
              reports.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-secondary float-right mr-1 mt-1"
              >Healthcare</span
            >
            <span class="badge badge-orchid float-right mr-1 mt-1"
              >Operations and Support</span
            >
            <span class="badge badge-danger float-right mr-1 mt-1">HR</span>
            <span class="badge badge-wheat float-right mr-1 mt-1"
              >Construction and Projects</span
            >
            <span class="badge badge-plum float-right mr-1 mt-1">Services</span>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 col-12 mt-4 pt-2 spacing retail fab manufacturing finance fmcg support scm projects services"
      >
        <div class="card work-process border-0 p-4 rounded shadow">
          <div class="card-body p-0">
            <h4 class="title">Automated Financial reports</h4>
            <p class="text-gray para">
              Real-time and on-demand drill-down reports around costing,
              profitability, MIS and other financial metrics. Consolidated data
              from various integrated sources, business rules and custom
              computations, data collection forms and tasks, automated
              escalations.
            </p>
            <span class="badge badge-primary float-right mt-1">Retail</span>
            <span class="badge badge-info float-right mr-1 mt-1">F&B</span>
            <span class="badge badge-success float-right mr-1 mt-1"
              >Finance</span
            >
            <span class="badge badge-warning float-right mr-1 mt-1"
              >Manufacturing</span
            >
            <span class="badge badge-dark float-right mr-1 mt-1">FMCG/CPG</span>
            <span class="badge badge-orchid float-right mr-1 mt-1"
              >Operations and Support</span
            >
            <span class="badge badge-lemon float-right mr-1 mt-1"
              >Supply Chain and Procurement</span
            >
            <span class="badge badge-wheat float-right mr-1 mt-1"
              >Construction and Projects</span
            >
            <span class="badge badge-plum float-right mr-1 mt-1">Services</span>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- By Industry End -->

<!-- Testi Start -->
<app-testimonials></app-testimonials>
<!-- Testi End -->

<!-- Schedule demo start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">
            Unleash the power of <span class="text-primary">Zvolv</span> for
            your business
          </h4>
          <p class="text-gray para-desc mx-auto mb-0">
            We can help evolve your enterprise processes, fast. Let's start
            building for free.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-10">
        <div class="form-group">
          <div class="input-group mb-3">
            <input
              [(ngModel)]="workEmail"
              name="email"
              id="email"
              type="email"
              class="form-control"
              placeholder="Work email :"
              required=""
              aria-describedby="sdbtn"
            />
            <div class="input-group-append">
              <button
                class="btn btn-primary submitBnt"
                (click)="openScheduleDemoModal()"
                id="sdbtn"
              >
                Schedule a demo
              </button>
            </div>
          </div>
        </div>
        <!--end form-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Schedule demo End -->

<!-- Shape Start -->
<div class="position-relative" style="z-index: -2">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 100L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
