<!-- Hero Start -->
<section class="d-table w-100 overflow-hidden bg-dark" id="home">
    <div class="container position-relative">
        <div class="row align-items-center pt-5">
            <div class="col-lg-7 col-md-6">
                <div class="title-heading">
                    <h1 class="heading mb-3"><span class="text-primary">Zvolv</span> is the ONLY platform you need to
                        automate ANY enterprise process.
                    </h1>
                    <p class="para-desc text-gray">A unified platform that can do everything you envision and then
                        some.</p>
                    <div class="mt-4 pt-2">
                        <a (click)="openScheduleDemoModal()" class="btn btn-primary">Schedule a demo</a><br>
                        <a (click)="gotoZvolvAppSignup()" class="text-light mb-2 ml-2 mt-3 mr-2 ultr"><b>Try
                                For Free <i class="mdi mdi-arrow-right"></i></b></a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="classic-saas-image position-relative bg-half-170">
                    <div class="bg-saas-shape position-relative">
                        <img src="assets/images/landing/why-zvolv.png" class="mover mx-auto d-block" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<section class="section pb-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h2 class="title mb-4"><span class="text-primary">Zvolv</span> Features</h2>
                    <p class="text-gray">
                        Zvolv's end-to-end digital process automation can revolutionize your operations with everything
                        from bots that automate tasks and assist in decision making, to business process management with
                        project management and workflow automation features.With Zvolv’s visual builders and
                        rapid-deployment methodology, build solutions that are always up to date, easy to change, and
                        ready to deploy where you need them.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>

<!-- Feature Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-gray">Zvolv Components</h4>
                    <h2>Flexibility to architect your solutions the way you envision</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>A
                            Zvolv Workspace is a web-portal or mobile application that provides an end-end process
                            solution, via access to one or more Zapps.
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Zvolv workspaces can be white-labelled with
                            custom domain URLs and branded mobile applications
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>A
                            Zapp is an individual workflow application, made by logically and contextually
                            interconnecting Zblocks.
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Zapps can be contextually exposed to
                            stakeholders inside and outside organisations
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>A
                            Zblock is a building block, can be a human task, data form, automation bot, dashboard widget
                            or integration database.
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Zblocks can be configured at a granular
                            level for data fields, communications and escalations, access permissions, automation logic
                            and more
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-gray">Workflow Automation</h4>
                    <h2>Orchestrate activities, automations and decisions across teams and tools</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Human and Bot task coordination
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Approvals and role based routing
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Logical and data based task dependencies
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Graphical visualisations via process
                            diagrams and flow-charts
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/workflow-automation.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/intelligent-automation.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-gray">Intelligent Automation</h4>
                    <h2>Offload the repetitive, mundane and error-prone data-driven tasks to bots</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Growing library of self-help Bots configured
                            with no-code builder
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Interactive bot builder with developer pane
                            to build custom automation via Python code
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Extensive set of pre-tested functions in
                            Python library to speed up bot building
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Bot examples and templates ranging from
                            simple API interfaces to complex AI/ML bots
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-gray">Project Management</h4>
                    <h2>Drive time-synchronised delivery of well-aligned goals across teams</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Adaptive vs Predictive project tracking
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Critical path computations and Gantt/Pert
                            charts
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Stage-gate based planning and approval
                            options
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Task, resource and efficiency milestone
                            tracking
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/project-management.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/task-management.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-gray">Task Management</h4>
                    <h2>Prioritized task lists for every individual, contextual access to information</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Templatized or computed timelines for delays
                            and escalations
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Role based task assignments and re-routing
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Status change, activity and data logs,
                            management summary reports
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Email and calendar integrations, email
                            digests
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-gray">Document Management</h4>
                    <h2>Efficiently manage all project related documents with versioning, access controls and more</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Project based document dockets, sharable
                            link generation
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>HTML document builder to dynamically
                            generate PDFs and CSVs
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Document tagging, keyword extraction for
                            search
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Integrated approval and version control
                            mechanisms
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/dms.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/form-builder.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-gray">Form Builder</h4>
                    <h2>Collect data and status updates from distributed teams with structured forms</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>50+ form field types, mobile friendly layout
                            options
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Auto-search fields integrated with databases
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Inter-field dependencies and visibility
                            controls
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Per field access controls, custom bot based
                            validations
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-gray">Visualisations</h4>
                    <h2>Disseminate information with the right visual tools relevant to different stakeholders</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Calendar, Kanban, List views for task
                            executioners
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Timeline and interactive Gantt views for
                            project managers
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Tabular, dependency and flow-chart views for
                            process configuration
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Granular control on information displayed in
                            web and mobile views
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/visualisations.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/dashboards.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-gray">Dashboards and Charts</h4>
                    <h2>Intuitive, summarised and drill-down access to information as required for various roles</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Multi-dimentional tables, charts, KPIs, Map
                            views and control tower visualisation options for dashboards
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Sort, search and filter options at field and
                            global levels
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Pivot tables, complex math functions and
                            analytical operations
                            controls
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Drag and drop configuration and contextual
                            access to information and widgets
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-gray">Communications</h4>
                    <h2>Keep your teams in the know for every project and task progress, delays or critical escalations
                    </h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Email, SMS, In-app notifications with role
                            based customisation
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Templatized and system defined message
                            options
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>SMTP and SMS provider integration
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Daily/weekly email digests, EOD summary
                            reports, weekly management reports
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/communications.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-gray">Drill-down Analytics</h4>
                    <h2>Make smarter decisions with real-time actionable data</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Self configurable via intuitive GUI
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Variety of graphical visualisations
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Data analytics and statistics functions
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Large scale data export and import
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/builder.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-gray">Self Help Configurations</h4>
                    <h2>Control who in the team has access to what information and interactivity</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Granular role based access permissions
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Application UI/UX and dashboard
                            configurations
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Workflow, task and project management
                            controls
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Automation bot and communications management
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-gray">Integrations</h4>
                    <h2>Connect all data sources and systems to your Zapps via easy, scalable interface widgets</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>REST/SOAP API and web services based
                            integrations with other tools. Support for JSON, XML and other formats.
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Extensive library of pre-configured
                            integration widgets for commonly used SaaS tools and ERP/CRM tools
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Options to integrate at a database layer, or
                            via file based interfaces
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Extensive set of push, pull, scheduled,
                            data-driven and on-demand trigger options
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/integrations.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/developer-tools.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-gray">Developer Tools</h4>
                    <h2>Manage advanced functionality with flexible access to relevant dev-ops and design features</h2>
                    <ul class="list-unstyled text-gray">
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Granular API access to various
                            functionality, sandbox environments
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Bulk operations for big datasets via simple
                            scripting flexibility
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>BOT builder and test/debugger framework for
                            custom Python development
                        </li>
                        <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                    class="uim uim-check-circle"></i></span>Access to mobile SDKs for custom UI/UX
                            development
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- Features End -->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                <div class="features">
                    <div class="image position-relative d-inline-block">
                        <h1><i class="uil uil-data-sharing"></i></h1>
                    </div>

                    <div class="content">
                        <h4 class="title-2">Cloud Deployment</h4>
                        <ul class="list-unstyled text-gray">
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Single click application deployment
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Application version controls and
                                archival and lock capabilities
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Migration of applications across
                                workspaces and servers
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Containerised deployments allow private
                                cloud or on-premise installations with varied hardware/OS
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                <div class="features">
                    <div class="image position-relative d-inline-block">
                        <h1><i class="uil uil-cloud-database-tree"></i></h1>
                    </div>

                    <div class="content">
                        <h4 class="title-2">External Access</h4>
                        <ul class="list-unstyled text-gray">
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                Public forms for data collection, integration with existing website forms
                            </li>
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                Contextual access to vendors and partners with minimalistic web and mobile applications
                            </li>
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                Invitation and approval based login access via phone numbers and/or emails with OTPs
                            </li>
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                Project or time driven access controls, restricted dashboard and document access
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                <div class="features">
                    <div class="image position-relative d-inline-block">
                        <h1><i class="uil uil-channel"></i></h1>
                    </div>

                    <div class="content">
                        <h4 class="title-2">Scalability</h4>
                        <ul class="list-unstyled text-gray">
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Zvolv is enterprise ready - with
                                security, scalability, and reliability you need to deliver complete solutions to your
                                most critical business processes
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Robust testing methodologies and
                                engineered chaos scenarios to guarantee stability
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Independent code base options with
                                private cloud allows selective feature upgrades
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Build processes with full confidence to
                                scale for high activity volumes and unexpected load spikes while maintaining high
                                availability
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">

            <div class="col-md-6 col-12 mt-5 mt-sm-0">
                <div class="features">
                    <div class="image position-relative d-inline-block">
                        <h1><i class="mdi mdi-security"></i></h1>
                    </div>

                    <div class="content">
                        <h4 class="title-2">Security</h4>
                        <ul class="list-unstyled text-gray">
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Our security model and controls are
                                based on international protocols and standards and adheres
                                to industry best practices, including external VAPT audits
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>SSO (SAML/ADFS, LDAP, Oauth2), 2-factor
                                authentication, SHA 256 Encryption, IMEI and Geo
                                locks, mobile app virtualisation
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Multi-level access controls allows IT to
                                govern access while enabling business operations
                                teams the autonomy to build their own solutions
                            </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i
                                        class="uim uim-check-circle"></i></span>Granular data retention controls for
                                each process allows you to keep data for only as long as
                                you need to, while staying within your security guidelines
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-5 mt-sm-0">
                <div class="features">
                    <div class="image position-relative d-inline-block">
                        <h1><i class="uil uil-chat-bubble-user"></i></h1>
                    </div>

                    <div class="content">
                        <h4 class="title-2">Hypercare Support</h4>
                        <ul class="list-unstyled text-gray">
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                14/7 Phone, Email, Chat support, Ticketing portal with SLA controlled response times
                            </li>
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                Automatically capture all activity history to easily triage issues, maintain compliance,
                                and understand what is happening with each business process
                            </li>
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                At Zvolv, no matter how big we get, we will still stay small enough to care. We can cope
                                with the needs of massive transformation initiatives with global giants, but we'll never
                                be too big to offer our customers a truly personal service
                            </li>
                            <li class="mb-0">
                                <span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>
                                Our uttermost dedication to each and every customer is our biggest differentiator. Our
                                customers' know that they can simply pick up the phone and call us at any time, and
                                we’ll be there to support them
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section mt-0">
    <div class="container pb-lg-4 mb-md-5 mb-4 mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">See everything that <span class="text-primary font-weight-bold">Zvolv</span>
                        offers at one place.</h4>
                    <p class="text-gray para-desc mx-auto mb-0">Start working with <span
                            class="text-primary font-weight-bold">Zvolv</span> that can provide everything you need.</p>
                    <div class="mt-4">
                        <a (click)="openScheduleDemoModal()" class="btn btn-primary mt-2 mr-2">Schedule a demo</a>
                        <a (click)="gotoZvolvAppSignup()" class="btn btn-outline-primary mt-2">Free Trial</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="position-relative">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>