<!-- Start Contact -->
<section class="section bg-animation-polygon polygon-gradient-2-reverse">
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5">
                <div class="card border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/partners/partner.svg" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-1 col-md-1"></div>
            <div class="col-lg-6 col-md-6 pt-2 pt-sm-0">
                <div class="card shadow rounded border-0">
                    <div class="card-body py-5">
                        <h4 class="card-title">Join the Zvolv Partner Program</h4>
                        <p class="para-desc">
                            Connect your people, processes and systems with intelligent automation using <span
                                class="text-primary">Zvolv</span>.
                        </p>
                        <div class="custom-form mt-4">
                            <div id="message"></div>

                            <form [formGroup]="contactForm">
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>First Name <span class="text-danger">*</span></label>
                                        <i class="mdi mdi-account icons text-muted mx-auto my-auto"></i>
                                        <input type="text" formControlName="firstName" class="form-control pl-5"
                                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="f.firstName.errors.required">First Name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Last Name <span class="text-danger">*</span></label>
                                        <i class="mdi mdi-account icons text-muted mx-auto my-auto"></i>
                                        <input type="text" formControlName="lastName" class="form-control pl-5"
                                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                            <div *ngIf="f.lastName.errors.required">Last Name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Email <span class="text-danger">*</span></label>
                                        <i class="mdi mdi-email-outline icons text-muted mx-auto my-auto"></i>
                                        <input type="text" formControlName="email" class="form-control pl-5"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Phone <span class="text-danger">*</span></label><br>
                                        <input type="tel" formControlName="phone" class="form-control pl-5"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">Phone number is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>Company <span class="text-danger">*</span></label>
                                        <i class="mdi mdi-garage icons text-muted mx-auto my-auto"></i>
                                        <input type="text" formControlName="company" class="form-control pl-5"
                                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
                                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                                            <div *ngIf="f.company.errors.required">Company name is required</div>
                                            <div *ngIf="f.company.errors.minlength">Company name must be at least 3 characters</div>
                                        </div>
                                    </div>
                                    <div class="form-group  col-6">
                                        <label for="csize">Company Size <span class="text-danger">*</span></label>
                                        <select formControlName="companySize" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.companySize.errors }" required>
                                            <option value="Less than 100">Less than 100</option>
                                            <option value="100 to 500">100 - 500</option>
                                            <option value="500 to 2000">500 - 2000</option>
                                            <option value="Over 2000">Over 2000</option>
                                        </select>
                                        <div *ngIf="submitted && f.companySize.errors" class="invalid-feedback">
                                            <div *ngIf="f.companySize.errors.required">Company size is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <label>Comments <span class="text-danger">*</span></label>
                                        <i class="mdi mdi-comment-outline icons text-muted mx-auto my-auto"></i>
                                        <textarea name="comments" id="comments" rows="4" class="form-control pl-5"
                                            formControlName="comments"
                                            [ngClass]="{ 'is-invalid': submitted && f.comments.errors }"
                                            placeholder="Any specific requests"></textarea>
                                        <div *ngIf="submitted && f.comments.errors" class="invalid-feedback">
                                            <div *ngIf="f.comments.errors.required">Message is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group form-check">
                                    <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input"
                                        [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                                    <label for="acceptTerms" class="form-check-label" for="agreeTerms">I agree to Zvolv
                                        <a href="terms" target="_blank">terms and conditions<i class="mdi mdi-open-in-new"></i></a> and
                                        <a href="privacy" target="_blank">privacy
                                            policy<i class="mdi mdi-open-in-new"></i></a></label>
                                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Please accept terms and
                                        conditions
                                    </div>
                                </div>

                                <re-captcha #captchaRefCU="reCaptcha" (resolved)="resolved($event)"
                                    siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ" size="invisible" badge="inline">
                                </re-captcha>

                                <div *ngIf="!showSuccess" class="text-center">
                                    <button class="btn btn-primary btn-block" (click)="onSubmit()"
                                        [disabled]="showLoader">Send <i *ngIf="showLoader"
                                            class="mdi mdi-loading mdi-spin"></i></button>
                                </div>
                                <div *ngIf="showSuccess" class="alert alert-success mt-2" role="alert">
                                    Thank you for contacting us. Our representative will reach out to you soon.
                                    <button type="button" class="float-right close" aria-label="Close"
                                        (click)="showSuccess = false;onReset()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div *ngIf="showError" class="alert alert-danger mt-2" role="alert">
                                    <button type="button" class="float-right close" aria-label="Close"
                                        (click)="showError = false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    Error while submitting form. Please try again.
                                    <div *ngIf="errorMsg">
                                        {{errorMsg}}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!--end custom-form-->
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->