<!-- Hero Start -->
<section class="bg-half bg-dark d-table w-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Terms of Services </h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="feature-item">
                    <p class="text-gray">
                        PLEASE READ THESE TERMS OF USE CAREFULLY. BY USING THE ZVOLV PLATFORM, YOU INDICATE YOUR
                        UNDERSTANDING AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT
                        ACCESS OR USE THIS PLATFORM.
                        <br><br>
                        Welcome to the Zvolv Platform ("Platform"), owned and operated by Zestl Software Private
                        Limited ("Company"). If you continue to browse and use this Zvolv Platform, you are agreeing
                        to comply with and be bound by these terms and conditions ("Terms of Use"), which together
                        with our privacy policy ("Privacy Policy") govern the Company's relationship with you
                        ("you"). Where applicable, "you" would include Service Providers (defined below). If you
                        disagree with any part of these Terms of Use, you may not use this Zvolv Platform in its
                        entirety. The Zvolv Platform is an integrated online platform consisting of the Zvolv Mobile
                        Applications ("Mobile App"), the www.zvolv.com website and its sub-domains ("Website") and
                        various cloud-based services upon which this Zvolv Platform is built and published. The term
                        "Zestl" or "us" or "we" or "our" refers to Zestl Software Private Limited or its affiliates.
                    </p>
                    <h5>
                        SCOPE OF SERVICES
                    </h5>
                    <p class="text-gray">
                        The Platform caters to a wide user base, ranging from individual users, independent service
                        professionals, single business owners and small, medium and large enterprises and their
                        employees and customers ("Members"). The Platform allows businesses ("Service Providers") to
                        build and host their own applications on the Platform in order to provide services to their
                        Members. In order to avail the services provided by the Service Providers, you are required
                        to access their respective applications through the Platform. The Platform will act as a
                        medium to allow the Members and Service Providers, who comply with these Terms of Use, to
                        connect with each other. Although the Platform facilitates in making connections between
                        Members and Service Providers, the Company shall not be deemed to be involved in such
                        connections or any subsequent transactions between the Members and the Service Providers. As
                        a result, and as detailed in these Terms of Use, you hereby acknowledge and agree that the
                        Company is not a party to such connections, and has no control over any element of such
                        connections, and shall have no liability to you or any third party in relation to the same.
                        Your use of this Platform and the services provided by the Company is at your own risk. The
                        Zvolv Platform is available, to the Service Provider, as SaaS (Software as a Service), and
                        is highly secure and scalable. You may use the Zvolv Platform for your personal and business
                        use or for business purpose of the business entity or organization that you represent.
                    </p>
                    <br>
                    <h5>
                        ACCEPTANCE OF THE TERMS
                    </h5>
                    <p class="text-gray">
                        Your use of the Zvolv Platform is subject to these Terms of Use, which may be updated from
                        time to time without notice to you. These Terms of Use apply to your use of the Zvolv
                        Platform. The use of any other product or service offered by the Company or the Service
                        Providers, through the Platform may be governed by separate terms and conditions devised
                        either by the Company or the Service Provider, as the case may be. Your acceptance of the
                        Terms of Use is valid, binding and constitutes the entire agreement between us (irrespective
                        of whether you are a user of or visitor to the Zvolv Platform). These Terms of Use supersede
                        all prior or contemporaneous agreements, representations, warranties and understandings with
                        respect to the Zvolv Platform, and the services that are provided to you through the Zvolv
                        Platform.
                    </p>
                    <br>
                    <h5>
                        ELIGIBILITY
                    </h5>
                    <p class="text-gray">
                        By using the Zvolv Platform, you represent and warrant that:
                    </p>
                    <ul class="list-unstyled text-gray">
                        <li>(a) You are fully able, competent and authorized to enter into the terms, conditions,
                            obligations, representations and warranties set forth in these Terms of Use ;</li>
                        <li>(b) All registration information you submit is truthful and accurate;</li>
                        <li>(c) You will maintain the accuracy of such information;</li>
                        <li>(d) You have attained the age to legally accept these Terms of Use as per the laws
                            applicable to you and/or at your location, or the age of 18 years, whichever is higher;
                            and</li>
                        <li>(e) your use of the Zvolv Platform does not violate any applicable law or regulation.
                            Use of the Zvolv Platform is void where prohibited. In the event, the Company discovers
                            that you are competitor of the Company or the Platform, the Company reserves the right
                            to restrict or remove your access to the platform, without any notice to you.</li>
                    </ul>
                    <p></p>
                    <br>
                    <h5>
                        REGISTRATION AND YOUR ACCOUNT
                    </h5>
                    <p class="text-gray">
                        You can create an account with us by registering on the Zvolv Platform. You understand and
                        acknowledge that the facilities provided by the Service Providers through the Platform shall
                        be subject to the respective terms of use of the Service Providers. You may also be required
                        to register with the respective Service Provider on their micro applications. The
                        registration of your account on the Zvolv Platform is subject to you satisfying these Terms
                        of Use at all times. If we have reasonable grounds to suspect violation of these Terms of
                        Use or that registration information you have provided is untrue, inaccurate, outdated, or
                        incomplete, we may terminate your account and refuse current or future use of any or all
                        parts of the Zvolv Platform.
                    </p>
                    <br>
                    <h5>
                        SECURITY AND ACCESS TO YOUR ACCOUNT
                    </h5>
                    <p class="text-gray">
                        You are solely responsible for maintaining the confidentiality of your username and password
                        and for all activities carried out under your account. We are not responsible for any loss
                        or damage to you or any third party that may be incurred as a result of any unauthorized
                        access and/or use of your account, or otherwise. You agree not to use the account, username
                        or password of another user (unless validly authorized) at any time or to disclose your
                        password to any third party or do anything else that might jeopardize the security of your
                        account. You agree to notify us immediately of any unauthorized use of your account. You
                        agree not to access or use the Zvolv Platform in any manner that could damage, disable,
                        overburden, or impair any computer system, network, website, the Zvolv Platform or any other
                        product, service or website operated by us or any other person. You also agree not to
                        interfere with or attempt to gain unauthorized access to any parts of the Zvolv Platform or
                        any accounts, computer systems or networks, etc. You agree not to use any robot, spider,
                        scraper or other automated means to access the Zvolv Platform or any other product, service
                        or website operated by us or any other person. We may, at our sole discretion, permit the
                        use of distribution channels such as application programming interfaces for the Zvolv
                        Platform.
                    </p>
                    <br>
                    <h5>
                        CONTENT AND PRIVACY
                    </h5>
                    <p class="text-gray">
                        We are concerned about your privacy and have developed a policy to address privacy concerns.
                        Any personal information or your business information collected on the Zvolv Platform may be
                        accessed and stored globally and will be treated in accordance with our Privacy Policy. You
                        are solely responsible for the content that you create or store on the Zvolv Platform, along
                        with the consequences of its transmission, by you. You are further responsible for ensuring
                        that you do not accidentally make any private content publicly available. The Service
                        Providers understands that any content that they receive through the use of the Zvolv
                        Platform is provided to them on a “AS IS” basis and they undertake not to use or otherwise
                        exploit such content for any commercial purpose without the express written consent of the
                        person who owns the rights to such content. We make no warranties, express or implied, as to
                        the accuracy or reliability of the content or any material or information that you
                        (including Service Provider) receive through the Zvolv Platform. You represent and warrant
                        that the content you create and store on the Zvolv Platform does not violate any applicable
                        law, rule or regulation or third party rights, including copyright, trademark, privacy,
                        publicity, or other personal or proprietary rights, breaches or conflicts with any
                        obligation, such as a confidentiality obligation, or contains libelous, defamatory, or
                        otherwise unlawful material. You agree to indemnify us against any claims against us arising
                        out of your content. You agree not to use, display or share your content or any data in a
                        manner inconsistent with these Terms of Use, Privacy Policy, or any applicable law, rule or
                        regulation. We are not under any obligation to keep back-up copies of the content once your
                        account is deleted. To be safe, you should independently back-up your content, to the extent
                        permitted herein and by applicable laws, rules and regulations. The Zvolv Platform may also
                        provide you with the ability to modify any of your content. You acknowledge and agree that
                        you are solely responsible for any modifications you may make and the same is required to be
                        in accordance with applicable law.
                    </p>
                    <br>
                    <h5>
                        CONTENT AVAILABLE
                    </h5>
                    <p class="text-gray">
                        You acknowledge that the Company does not make any representations or warranties about the
                        Content which you may have access to as part of the services, or through your use of this
                        Platform. Under no circumstances, the Company is liable in any way for any Content,
                        including, but not limited to: any infringing Content, any errors or omissions in Content,
                        or for any loss or damage of any kind incurred as a result of the use of any Content posted,
                        transmitted, linked from, or otherwise accessible through or made available via the
                        Platform. You agree that you are solely responsible for your reuse of Content made available
                        through the Platform.
                    </p>
                    <br>
                    <h5>
                        FEES AND SUBSCRIPTION PLANS
                    </h5>
                    <p class="text-gray">
                        Currently, the Zvolv Platform is free of cost for the users (customers/clients of Service
                        Providers) of the Platform. However, the Company reserves the right to levy certain fees or
                        charges, for the use of the Zvolv Platform or certain features available through the
                        Platform. You agree to pay any such fees, as may be applicable. The Service Provider’s shall
                        be required to pay the subscription fee as detailed in the Master License Agreement, to be
                        executed between the Service Providers and the Company, for availing the subscription plans
                        available on the Zvolv Platform.
                    </p>
                    <br>
                    <h5>
                        SUBSCRIPTION TO BETA SERVICES
                    </h5>
                    <p class="text-gray">
                        We may offer certain services on the Zvolv Platform as closed or open beta services ("Beta
                        Services") during the testing and evaluation period. You agree that we have the sole
                        authority and discretion to determine the period of time for testing and evaluation of Beta
                        Services. We reserve the right to fully or partially discontinue, at any time and from time
                        to time, temporarily or permanently, any of the Beta Services with or without notice to you.
                        We also reserve the right to make the Beta Services available for a subscription fee (which
                        may or may not be lower than the subscription fee payable for Paid Services).
                    </p>
                    <br>
                    <h5>
                        PROHIBITED ACTIVITIES
                    </h5>
                    <p class="text-gray">
                        You agree not to use the Zvolv Platform for illegal, harmful, misleading, fraudulent or
                        other malicious purposes or to post, disseminate or communicate any unlawful, defamatory,
                        obscene, lewd, excessively violent, harassing, sexually explicit or otherwise objectionable
                        subject matter or for the transmission of material that contains viruses or other malicious
                        code, or that which infringes or may infringe intellectual property or other rights of
                        another. You agree not to use the Services for the transmission of "junk mail", "spam",
                        "chain letters", "phishing" or unsolicited mass distribution of email. You agree not to
                        modify or create a derivative work of any part of the Services, You shall indemnify us for
                        any claim, loss, damages or liability arising out of or as a result of your misuse of the
                        Zvolv Platform or the Services. Despite these prohibitions, content communicated by other
                        users of the Zvolv Platform may contain inaccurate, inappropriate, offensive or sexually
                        explicit material, products or services, and we assume no responsibility or liability for
                        this material. Without assuming any obligation to do so, we may delete any Content or
                        suspend any account associated with it, that we have reasonable grounds to believe violates
                        the Terms of Use, any applicable law, or that may be offensive or illegal, or violate the
                        rights, harm, or threaten the safety of any person.
                    </p>
                    <br>
                    <h5>
                        INACTIVE ACCOUNTS POLICY
                    </h5>
                    <p class="text-gray">
                        We reserve the right to disable or deactivate unpaid user accounts that are inactive for
                        more than 60 days. In the event of such termination, all data associated with such account
                        may be deleted. We will provide you prior notice of such termination by email.
                    </p>
                    <br>
                    <h5>
                        ANTI-SPAM POLICY
                    </h5>
                    <p class="text-gray">
                        You certify that you will use the services in accordance with these Terms of Use. You are
                        forbidden to transmit unsolicited commercial email or SMS (spam) by using the Services
                        offered through the Platform. Additionally, you are required to reconfirm (permission pass)
                        or stop mailing an existing list when it is determined to be in violation of our anti-spam
                        policy. Repeated violations or failures to comply with this anti-spam policy will result in
                        termination of your access to the Services. You agree and acknowledge that, we shall not be
                        held liable for any claim that may arise due to your violation of this anti-spam policy.
                    </p>
                    <br>
                    <h5>
                        MONITORING
                    </h5>
                    <p class="text-gray">
                        We reserve the right (but have no obligation) to monitor all activities on your account to
                        ensure compliance with the Terms of Use and applicable law.
                    </p>
                    <br>
                    <h5>
                        SERVICE COMMUNICATION
                    </h5>
                    <p class="text-gray">
                        We may contact you with service-related communication, from time to time, including any
                        notices required by law, in lieu of communication by postal mail or important notices
                        pertaining to our arrangement. We shall use the contact information provided at the time of
                        registration and you should ensure that they are up-to-date. We may also use contact
                        information to send you other messages, such as updates, user communications, newsletters,
                        changes to features of the Zvolv Platform, or our offers.
                    </p>
                    <br>
                    <h5>
                        THIRD PARTY LINKS AND INTERACTION
                    </h5>
                    <p class="text-gray">
                        Additionally, we may provide hyperlinks though the Zvolv Platform, or any other form of link
                        or redirection of your connection to other sites and mobile applications ("Third Parties").
                        Links to these Third Parties are provided solely for your convenience and in no way does the
                        inclusion of any link on the Platform or Services imply our affiliation or endorsement of
                        the linked site, their business practices (including their privacy policies) or any
                        information therein. We expressly disclaim responsibility for the accuracy, quality,
                        legality, nature, availability or reliability of Third Parties linked to, by, or through the
                        Zvolv Platform. You release us, our officers, employees, agents and successors from claims,
                        demands and damages of every kind or nature arising out of or related to any disputes with
                        other users and / or third parties. We may provide you with certain ancillary services such
                        as testimonies, blogs and reviews for the Services. Subject to any applicable laws, any
                        communication shared by you via the Platform or through the blogs, reviews or otherwise to
                        us (including without limitation contents, images, audio, financial information, feedback
                        etc. collectively "Feedback") is on a non-confidential basis, and we are under no obligation
                        to refrain from reproducing, publishing or otherwise using it in any way or for any purpose.
                        You shall be responsible for the content and information contained in any Feedback shared by
                        you through the Platform or otherwise to us, including without limitation for its
                        truthfulness and accuracy. Sharing your Feedback with us, constitutes an assignment to us of
                        all worldwide rights, titles and interests in all copyrights and other intellectual property
                        rights in the Feedback and you authorize us to use the Feedback for any purpose, which we
                        may deem fit.
                    </p>
                    <br>
                    <h5>
                        SERVICE PROVIDER APPLICATIONS
                    </h5>
                    <p class="text-gray">
                        The Platform hosts certain micro applications, owned and operated by the Service Providers.
                        The access to these micro applications are provided solely for your convenience and in no
                        way does the inclusion of any of these micro application on the Platform imply our
                        affiliation or endorsement of any specific micro application, the business practices
                        (including their privacy policies) or any information of the Service Provider. We expressly
                        disclaim responsibility for the accuracy, quality, legality, nature, availability or
                        reliability of these micro application. You release us, our officers, employees, agents and
                        successors from claims, demands and damages of every kind or nature arising out of or
                        related to any of your dispute with other users or the Service Providers.
                    </p>
                    <br>
                    <h5>
                        INTELLECTUAL PROPERTY
                    </h5>
                    <p class="text-gray">
                        You acknowledge and agree that we and our licensors retain ownership of all intellectual
                        property rights (including applicable copyrights, trademarks and other proprietary rights)
                        of any kind on the Zvolv Platform, and our websites, emails, platforms, applications, other
                        products and/or services. We are not granting any license to you under any of those
                        intellectual property rights by virtue of these Terms of Use. You further acknowledge and
                        agree that we retain ownership and control over the "look and feel" and substance of our
                        tools, widgets, buttons, applications and the like. We reserve all rights that are not
                        explicitly granted to you in under these Terms of Use. Our trademarks, logos, service marks,
                        images, trade names, designs, page headers, button icons, scripts and other distinctive
                        branding features constitute our intellectual property and may not be copied, imitated, or
                        used, in whole or in part. You agree not to infringe any intellectual property rights when
                        you use the Zvolv Platform and that you will indemnify us for any claims which may arise on
                        us in this regard. We have invested substantial time and effort into developing the Zvolv
                        Platform and it contains our own or licensed intellectual property. You further agree not to
                        breach our intellectual property rights or attempt to reverse-engineer or discover the
                        source code (by any means) of the Zvolv Platform. You agree that any infringement of these
                        restrictions will cause us irreparable loss.
                    </p>
                    <br>
                    <h5>
                        DISCLAIMER OF WARRANTIES
                    </h5>
                    <p class="text-gray">
                        We do not warrant that:
                    </p>
                    <ul class="list-unstyled text-gray">
                        <li>(a) the Zvolv Platform will meet your specific requirements;</li>
                        <li>(b) the services on the Zvolv Platform will be uninterrupted, timely, secure, or
                            error-free;</li>
                        <li>(c) the results that may be obtained from the use of the Zvolv Platform will be accurate
                            or reliable;</li>
                        <li>(d) the quality of any products, services, information, or other material purchased or
                            obtained by you through the Zvolv Platform will meet your expectations; and</li>
                        <li>(e) any errors on the Zvolv Platform will be corrected.</li>
                    </ul>
                    <br>
                    <p class="text-gray">
                        YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE PLATFORM AND THE SERVICE IS ENTIRELY AT
                        YOUR
                        OWN RISK AND THAT THE PLATFORM AND THE SERVICES THEREIN ARE PROVIDED ON AN "AS IS" OR "AS
                        AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. ALL EXPRESS AND IMPLIED WARRANTIES,
                        INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS ARE EXPRESSLY DISCLAIMED TO THE FULLEST
                        EXTENT PERMITTED BY LAW. TO THE FULLEST EXTENT PERMITTED BY LAW THE COMPANY, ITS OFFICERS,
                        DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                        WITH
                        THE PLATFORM, THE SERVICES AND YOUR USE THEREOF. THE COMPANY MAKES NO WARRANTIES OR
                        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES OR THE PLATFORM'S CONTENT
                        OR
                        THE DETAILS AND CONTENTS SHARED BY THE MEMBERS OR THE SERVICE PROVIDERS OR THE CONTENT OF
                        ANY
                        THIRD PARTY WEBSITES LINKED TO THE PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR
                        ANY
                        (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE,
                        OF
                        ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM AND SERVICE,
                        (III)
                        ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                        AND/OR
                        FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
                        OR
                        FROM THE PLATFORM, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                        TRANSMITTED
                        TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY
                        CONTENT
                        OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                        EMAILED, COMMUNICATED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM OR THE
                        SERVICE.
                        THE COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
                        OR
                        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM OR ANY HYPERLINKED
                        WEBSITE
                        OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND THE COMPANY WILL NOT BE A PARTY TO OR IN
                        ANY
                        WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND/OR OTHER MEMBERS AND/OR
                        SERVICE PROVIDERS AND/OR THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. YOU UNDERSTAND AND
                        ACKNOWLEDGE THAT THE PRODUCTS AND THE SERVICES AVAILABLE ON THE PLATFORM ARE PROVIDED SOLELY
                        BY
                        THE SERVICE PROVIDERS AND THE PLATFORM IS NOT RESPONSIBLE OR LIABLE FOR THE QUALITY,
                        QUANTITY OR
                        THE NATURE OF SUCH PRODUCTS AND SERVICES. ANY ADVICE, CONSULTATION, REPORTS, DOCUMENTS OR
                        DETAILS PROVIDED OR REQUESTED BY THE SERVICE PROVIDERS, THROUGH THE PLATFORM, IS SPECIFIC TO
                        SUCH SERVICE PROVIDER AND THE PLATFORM OR THE COMPANY IS NOT PRIVY TO THIS INTERACTION
                        BETWEEN
                        YOU AND THE SERVICE PROVIDERS. THE COMPANY OR THE PLATFORM SHALL NOT BE HELD LIABLE OR MADE
                        A
                        PARTY TO ANY OF THE TRANSACTION OR CLAIM WHICH YOU MAY HAVE OR RAISE AGAINST THE SERVICE
                        PROVIDERS. THE PLATFORM DOES NOT PROVIDE ANY GUARANTEE OR WARRANTY FOR THE PRODUCTS AND
                        SERVICES
                        OFFERED BY THE SERVICE PROVIDERS. THE PLATFORM ALSO DOES NOT GUARANTEE CONTINUED ACCESS TO
                        THE
                        MICRO APPLICATIONS OWNED AND OPERATED BY THE RESPECTIVE SERVICE PROVIDERS. THE MICRO
                        APPLICATIONS SHALL HAVE THEIR OWN POLICIES, YOU AGREE AND UNDERTAKE TO READ, UNDERSTAND AND
                        ADHERE TO SUCH POLICIES. WE DO NOT GUARANTEE SECURITY OF YOUR DATA OR INFORMATION, WHICH YOU
                        SHARE WITH THE SERVICE PROVIDERS, THROUGH THEIR MICRO APPLICATIONS.
                    </p>
                    <br>
                    <h5>
                        LIMITATION OF LIABILITY
                    </h5>
                    <p class="text-gray">
                        You expressly understand and agree that Zvolv, its affiliates and subsidiaries, and their
                        respective officers, directors, agents, co-branders or other partners, and employees shall
                        not be liable for any direct, indirect, incidental, special, consequential or exemplary
                        damages, including but not limited to, damages for loss of profits, goodwill, use, data or
                        other intangible losses (even if we have been advised of the possibility of such damages),
                        resulting from:
                    </p>
                    <ul class="list-unstyled text-gray">
                        <li>(a) the use or the inability to use the Zvolv Platform;</li>
                        <li>(b) the cost of procurement of substitute products and services resulting from any
                            goods, data, information or services purchased or obtained or messages received or
                            transactions entered into through or from the Zvolv Platform;</li>
                        <li>(c) unauthorized access to or alteration of your transmissions or data;</li>
                        <li>(d) statements or conduct of any third party on the Zvolv Platform;</li>
                        <li>(e) or any other matter relating to the Zvolv Platform. Your sole and exclusive remedy
                            for any dispute regarding the Zvolv Platform, with us or any of the persons detailed
                            above shall be termination of your relationship with us. In no event shall our entire
                            liability to you in respect of any Service, whether direct or indirect, exceed the fees
                            paid by you towards the use of the Zvolv Platform in the previous three (3) months. You
                            may use the Zvolv Platform to interact, communicate and/or deliver services to your
                            customers. Further, your customers may have the right to modify and add Content as well.
                            We have no responsibility to your customers or their actions, and shall not be liable
                            for any claims which may arise out of your relationship with your customers. You shall
                            indemnify us against any claims which may out of your interactions with your customers
                            or actions of your customers pursuant to your use of the Zvolv Platform.</li>
                        <p></p>
                        <br>
                        <h5>
                            INDEMNIFICATION
                        </h5>
                        <p class="text-gray">
                            You agree to indemnify, defend, and hold harmless Zvolv, its affiliates and
                            subsidiaries, and their respective officers, directors, agents, co-branders or other
                            partners, and employees and its third party service providers from and against any
                            claim, demand, loss, damage, cost, or liability (including reasonable attorneys' fees)
                            arising out of or relating to:
                        </p>
                        <ul class="list-unstyled text-gray">
                            <li>(a) any Content you submit, post, transmit, or make available through the Zvolv
                                Platform or any other service provided by us;</li>
                            <li>(b) your use or misuse of the Zvolv Platform;</li>
                            <li>(c) your connection to and use of the Zvolv Platform;</li>
                            <li>(d) your breach or alleged breach of these Terms of Use; or</li>
                            <li>(e) your violation of any rights (including intellectual property rights) of a third
                                party.</li>
                        </ul>
                        <p></p>
                        <br>
                        <h5>
                            CHANGES TO THE Zvolv PLATFORM
                        </h5>
                        <p class="text-gray">
                            We reserve the right at any time (and from time to time) to modify, suspend, or
                            discontinue providing the Zvolv Platform or any part thereof with or without notice. We
                            will not be liable to you or to any third party for any modification, suspension or
                            discontinuance of the Zvolv Platform.
                        </p>
                        <br>
                        <h5>
                            AMENDMENT OF THESE TERMS OF USE
                        </h5>
                        <p class="text-gray">
                            We may amend, modify, change, add or remove portions of these Terms of Use at any time
                            without notice to you. The latest Terms of Use will be posted on the Platform, and you
                            should review the Terms of Use each time before you use the Zvolv Platform. By
                            continuing to use the Zvolv Platform, you are presumed to have notice of the latest
                            version of the Terms of Use.
                        </p>
                        <br>
                        <h5>
                            TERM AND TERMINATION
                        </h5>
                        <p class="text-gray">
                            You may terminate your account and end your use of the Zvolv Platform at any time. We
                            have the right (at our sole discretion and without any liability to you) for any reason
                            to:
                        </p>
                        <ul class="list-unstyled text-gray">
                            <li>(a) delete, disable or deactivate your account, block your email or IP address, or
                                otherwise terminate your access to or use of the Zvolv Platform;</li>
                            <li>(b) remove and discard any Content within any account or anywhere on the Zvolv
                                Platform; or</li>
                            <li>(c) shut down an account, with or without notice.</li>
                        </ul>
                        If you terminate your account, we will have no obligation to refund you any fees you may
                        have paid. Upon deactivating your account, these Terms of Use terminate and your access
                        rights to the Zvolv Platform immediately cease to exist. Our rights survive the termination
                        of these Terms of Use.
                        <p></p>
                        <br>
                        <h5>
                            GOVERNING LAW AND DISPUTE RESOLUTION
                        </h5>
                        <p class="text-gray">
                            These Terms of Use shall be governed by the laws of the Republic of India without giving
                            effect to any principles that may provide the application of the law of another
                            jurisdiction. Any controversy or claim arising out of or relating to these Terms of Use
                            shall be settled by binding arbitration in accordance with the requirements of the
                            Arbitration and Conciliation Act, 1996 of India, as amended from time to time. The venue
                            of the arbitration shall be Pune, India and the sole arbitrator shall be appointed by
                            us. The decision of the sole arbitrator shall be final and un-appealable.
                            Notwithstanding the foregoing, we may seek injunctive or other equitable relief to
                            protect our rights in any court of competent jurisdiction. Subject to the above, the
                            courts of law situated at Pune, Maharashtra, India shall have exclusive jurisdiction to
                            try any dispute arising under these Terms of Use.
                        </p>
                        <br>
                        <h5>
                            FORCE MAJEURE
                        </h5>
                        <p class="text-gray">
                            Notwithstanding anything provided herein, under no circumstances the Company or this
                            Platform is liable for any damage or loss due to deficiency in performance of the
                            Services or this Platform resulting directly or indirectly from acts of nature, forces,
                            or causes beyond its reasonable control, including, without limitation, Internet
                            failures, computer equipment failures, telecommunication equipment failures, change in
                            law, government order or regulations, other equipment failures, electrical power
                            failures.
                        </p>
                        <br>
                        <h5>
                            Payment Gateway
                        </h5>
                        <p class="text-gray">
                            The Zvolv platform makes available a Payment Gateway through its approved payment
                            processing provider(s). As a Service Provider, your use of the Payment Gateway will be
                            subject to receiving an approved status by the Company and/or the payment processing
                            provider(s), fulfilling the documentation and other requirements of the approved payment
                            processing provider(s) and adhering to the terms and conditions of use prescribed by the
                            payment processing provider(s). Please contact us at support@zestl.com to understand if
                            you qualify to avail of this service. You acknowledge and understand that the payment
                            gateway services made available through the Zvolv platform are owned, controlled and
                            provided by the payment processing provider(s) and hence, the Company does not warrant
                            and in no way will be liable for the timeliness of such services and the handover of
                            payments through such services.
                        </p>
                        <br>
                        <h5>
                            Who You Are Buying From
                        </h5>
                        <p class="text-gray">
                            The Zvolv platform acts as a medium to allow Members and Service Providers, who comply
                            with these Terms of Use, to connect with each other and enables transactions between
                            them. When a Member purchases a product or a service from a hosted Service Provider (for
                            example, a membership, a ticket for an event, a product etc), Zvolv will be handling the
                            transaction and collecting payment on behalf of the Service Provider. The use of any
                            product or service offered by the Service Providers through the Zvolv Platform may be
                            governed by separate terms and conditions devised by the Service Providers.
                        </p>
                        <br>
                        <h5>
                            Eligibility Criteria
                        </h5>
                        <p class="text-gray">
                            The services and products offered on the Zvolv platform are not available to persons
                            under the age of 18 or to anyone previously suspended or removed from the platform by
                            the Company. By accepting these Terms &amp; Conditions or by otherwise using the
                            platform, You represent that You are at least 18 years of age and have not been
                            previously suspended or removed from the Platform. You represent and warrant that you
                            have the right, authority, and capacity to enter into this Agreement and to abide by all
                            of the terms and conditions of this Agreement. You shall not impersonate any person or
                            entity, or falsely state or otherwise misrepresent identity, age or affiliation with any
                            person or entity.
                        </p>
                        <br>
                        <h5>
                            Pricing and Availability
                        </h5>
                        <p class="text-gray">
                            The Zvolv platform offers a convenient and secure way to make payments towards products
                            and services bought from the service providers on the platform. But the Company is not a
                            warrantor, insurer , or guarantor of the products or services to be provided by the
                            Service Providers. The Company does not control the inventory or its availability and
                            pricing. Any disputes regarding the purchased product or service must be handled
                            directly between the Member and the Service Provider. If the amount you pay for a
                            product or service is incorrect regardless of whether because of an error in a price
                            posted on the platform or otherwise communicated to you, the Company will have the right
                            to cancel that transaction and refund to you the amount that you paid. This will apply
                            regardless of whether this is because of any human error or a transactional malfunction
                            on the platform.
                        </p>
                        <br>
                        <h5>
                            Billing Information Verification
                        </h5>
                        <p class="text-gray">
                            In using the Zvolv platform to pay for products/services, you agree to: Provide true,
                            accurate, current and complete information about yourself and your account information.
                            If you provide any information that is untrue, inaccurate, not current or incomplete, or
                            the Company has reasonable grounds to suspect that such information is untrue,
                            inaccurate, not current or incomplete, the Company has the right to suspend or terminate
                            your account and refuse any and all current or future use of the platform (or any
                            portion thereof). The Company assumes no responsibility and shall incur no liability if
                            it is unable to complete the payment on Payment Date owing to any one or more of the
                            following:
                        </p>
                        <ul class="list-unstyled text-gray">
                            <li>(a)If the Payment Instruction(s) issued by you is/are incomplete, inaccurate,
                                invalid and delayed.</li>
                            <li>(b)If the Payment Account has insufficient funds/limits to cover for the payment
                                amount.</li>
                            <li>(c)If your Bank refuses or delays honoring the Payment Instruction(s)</li>
                            <li>(d)If payment is not processed by the Service Provider upon receipt.</li>
                            <li>(e)Circumstances beyond the control of the Company (including, but not limited to,
                                fire, flood, natural disasters, bank strikes, power failure, systems failure like
                                computer or telephone lines breakdown due to an unforeseeable cause or interference
                                from an outside force) In case the transaction payment is not effected for any
                                reason, you will be intimated about the failed payment by an e-mail or via a message
                                on the Zvolv app/website or SMS Some transactions will be processed only after a
                                billing address, and other billing information, has been verified. Occasionally, we
                                receive incorrect billing or credit card account information for transactions that
                                can delay processing and delivery. In these cases, Zvolv customer service will
                                attempt to contact you, using the information provided at the time of purchase. If
                                the Company is unable to reach you after its initial attempt, the Company may cancel
                                your transactions.</li>
                        </ul>
                        <p></p>
                        <br>
                        <h5>
                            Internet handling fees and Order Processing Fees
                        </h5>
                        <p class="text-gray">
                            Products and services purchased on Zvolv may be subject to a per transaction internet
                            handling fee and a non-refundable per order processing fee. The Company and/or the
                            Service Providers on the platform reserve the right to charge a service fee or any other
                            additional charge from the member, in addition to the fees for availing the products or
                            services. Fees structure can be revised by the Company or Service Providers hosted on
                            the platform.
                        </p>
                        <br>
                        <h5>
                            Order Confirmation
                        </h5>
                        <p class="text-gray">
                            If you do not receive a confirmation number (in the form of a confirmation page or email
                            or SMS or in-app notification) after submitting payment information, or if you
                            experience an error message or service interruption after submitting payment
                            information, it is your responsibility to confirm the same from your transaction history
                            or with the Customer Service Department whether or not your order has been placed. Only
                            you may be aware of any problems that may occur during the purchase process. The Company
                            will not be responsible for losses (monetary or otherwise) if you assume that an order
                            was not placed because you failed to receive confirmation.
                        </p>
                        <br>
                        <h5>
                            Cancellation, Replacement, Refund, Dispute Policy
                        </h5>
                        <p class="text-gray">
                            Occasionally, orders are canceled or postponed by the service provider. Should this
                            occur, we will attempt to contact you and refund the amount as per the policy of the
                            service provider. Before making any purchases on the Zvolv platform, carefully review
                            your transaction details. The Company will be unable to process exchanges or refunds
                            after a product or service has been purchased or for lost, stolen, damaged or destroyed
                            products, unless the Service Provider refund policy allows for it. Refund eligibility
                            depends on the Service Provider offering the goods/ service on Zvolv platform.
                            Individual Service Providers on the Zvolv platform can have different refund policies.
                            In case it is applicable, refund policy of the individual Service Providers will
                            supercede the generic Refund policy of Zvolv. The Member can write to us at
                            support@zestl.com or chat with Zvolv support on our app for any of the issues pertaining
                            to refund, chargeback, dispute, unfulfilled order,etc. Our support team would actively
                            engage the concerned Service Provider and connect the Member to resolve the same. The
                            policy applies to the products or services where payment has been made via Zvolv. All
                            Refunds, if allowed, will be credited back to the payment instrument used by the Member
                            to complete the payment. It will take 3-21 days for the money to show in your bank
                            account depending on payment participants involved in the payment, including, but not
                            limited to your bank, payment gateway, bank used by payment gateway. In case of delay in
                            refund getting credited to your bank account or any other payment instrument used by you
                            to complete the payment, the Zvolv support team has the right to ask for bank account
                            details to resolve the issue with the payment gateway. The Zvolv support team can ask
                            you to raise a chargeback with your bank in case there is no resolution or if the money
                            is not credited by your bank to your account. The Member agrees to cooperate with the
                            team and follow the process to raise a chargeback to resolve the issue. The Company is
                            not responsible for any delay in the process of raising the chargeback and the Member
                            agrees not to hold the Company liable for the delay in the same.
                        </p>
                        <br>
                        <h5>
                            Customer Care
                        </h5>
                        <p class="text-gray">
                            If you need help regarding your order or any other issue, the best in the company is
                            available. We guarantee a response time of one working day and you can reach our
                            customer service department at support@zestl.com
                        </p>
                        <br>
                        <h5>
                            GENERAL
                        </h5>
                        <p class="text-gray">
                            A failure on our part to exercise or enforce any right or provision of these Terms of
                            Use does not constitute a waiver of such right or provision. If any provision of these
                            Terms of Use is found by a court of competent jurisdiction to be invalid, it shall not
                            invalidate the rest of the Terms of Use which shall remain in full force and effect, and
                            we shall try to give effect to the intention as reflected in the provision. We may
                            assign the agreement between us pursuant to the Terms of Use in whole or in part in its
                            sole discretion without your consent and without notice. Nothing in this Agreement shall
                            prevent us from complying with the law and applicable regulations. You agree that no
                            joint venture, employment, or agency relationship exists between you and the Company as
                            a result of these Terms of Use or from your use of the Platform or the services.
                        </p>
                        <br>
                        <h5>
                            QUESTIONS AND SUGGESTIONS
                        </h5>
                        <p class="text-gray">
                            If you have questions, suggestions, or require to contact us as per these Terms of Use,
                            or wish to make a complaint, please contact us at support@zestl.com
                        </p>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Terms & Conditions -->