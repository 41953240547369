<section class="d-table w-100 h-100 overflow-hidden bg-dark pb-0" id="home">
  <div class="container-hero position-relative">
    <div class="row align-items-center pt-5">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-4">We have been in your shoes</h1>
          <p class="para-desc text-white-50 mb-4">
            We have suffered the pain of spreadsheets, emails, adhoc processes
            and antiquated tools. We have seen large companies fail to derive
            value from legacy BPM and RPA systems - wasting months and millions
            of dollars on developers and integrators.
          </p>
          <p class="para-desc text-white-50">
            With <span class="text-primary">Zvolv</span>, we are here to change
            the status quo. To democratize access to fast, simple yet powerful
            intelligent automation beyond just the Fortune-500.
          </p>
          <div class="mt-4 pt-2">
            <a [routerLink]="['/contact-us']" class="btn btn-primary"
              >Join us</a
            >
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative bg-half-170">
          <div class="bg-saas-shape position-relative">
            <img
              src="assets/images/about-us/about-hero-img.png"
              class="img-fluid mover mx-auto d-block"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->


<!-- Team Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title">Experience meets Passion</h4>
          <!-- <p class="text-muted para-desc mx-auto mb-0">Where Experience meets Passion</p> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row text-center justify-content-center align-items-center">
      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img
                src="assets/images/about-us/HardikGandhi.jpeg"
                class="img-fluid avatar avatar-ex-large rounded-circle"
                alt=""
              />
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/hardikpgandhi"
                    class="rounded"
                    ><i class="mdi mdi-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0">
                <a
                  href="https://www.linkedin.com/in/hardikpgandhi"
                  class="name text-dark"
                  >Hardik Gandhi</a
                >
              </h5>
              <small class="designation text-gray">CEO</small><br />
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img
                src="assets/images/about-us/KapilBhadke.jpeg"
                class="img-fluid avatar avatar-ex-large rounded-circle"
                alt=""
              />
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="name text-dark mb-0">Kapil Bhadke</h5>
              <small class="designation text-gray">CPO</small><br />
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img
                src="assets/images/about-us/SujoyChakravarty.jpeg"
                class="img-fluid avatar avatar-ex-large rounded-circle"
                alt=""
              />
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/sujoy-chakravarty-72aa1212"
                    class="rounded"
                    ><i class="mdi mdi-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0">
                <a
                  href="https://www.linkedin.com/in/sujoy-chakravarty-72aa1212"
                  class="name text-dark"
                  >Sujoy Chakravarty</a
                >
              </h5>
              <small class="designation text-gray">COO</small><br />
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>

    <div class="row text-center justify-content-center align-items-center">
      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img
                src="assets/images/about-us/NitinBhat.png"
                class="img-fluid avatar avatar-ex-large rounded-circle"
                alt=""
              />
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item">
                  <a href="https://www.linkedin.com/in/nibhat" class="rounded"
                    ><i class="mdi mdi-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0">
                <a
                  href="https://www.linkedin.com/in/nibhat"
                  class="name text-dark"
                  >Nitin Bhat</a
                >
              </h5>
              <small class="designation text-gray">Board Advisor</small><br />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img
                src="assets/images/about-us/SunilChandiramani.jpg"
                class="img-fluid avatar avatar-ex-large rounded-circle"
                alt=""
              />
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/sunilchandiramani"
                    class="rounded"
                    ><i class="mdi mdi-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0">
                <a
                  href="https://www.linkedin.com/in/sunilchandiramani"
                  class="name text-dark"
                  >Sunil Chandiramani</a
                >
              </h5>
              <small class="designation text-gray">Board Advisor</small><br />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img
                src="assets/images/about-us/SrivatsanSrinivasan.jpeg"
                class="img-fluid avatar avatar-ex-large rounded-circle"
                alt=""
              />
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/ssrinivasan1/?originalSubdomain=au"
                    class="rounded"
                    ><i class="mdi mdi-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0">
                <a
                  href="https://www.linkedin.com/in/ssrinivasan1/?originalSubdomain=au"
                  class="name text-dark"
                  >Srivatsan Srinivasan</a
                >
              </h5>
              <small class="designation text-gray">CRO, Board Advisor</small
              ><br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->
</section>
<!-- Team End -->

<!-- Vision/Mission Start -->
<section class="section bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-12">
        <div>
          <div class="section-title mb-4 pb-2 pt-5">
            <h1 class="title">
              We are on a mission to democratize access to cutting edge
              automation technology.
            </h1>
          </div>
          <div class="section-title mb-4 pb-2 pt-5">
            <h1 class="title font-weight-bold mb-0">We believe in</h1>
            <h1 class="title font-weight-bold mb-0">
              <span
                class="typed-element-about text-primary"
                data-elements="The Speed of Change, The Power of DIY, The Need for Simplicity, Eliminating the Mundane, Strength of Data, Deliverying Value"
              ></span>
            </h1>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-12">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-speedometer"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>The Speed of Change</h5>
                    <p class="para text-muted mb-0">
                      A leading supermarket chain saved > 20% on their
                      operational costs with an ML based resource optimizer and
                      task rostering engine.
                    </p>
                    <!-- <h6 class="text-muted tag mb-0">Branding</h6> -->
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-wrench"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>The Power of DIY</h5>
                    <p class="para text-muted mb-0">
                      A fast growing QSR was able to expand > 40% faster with
                      smart site selection and cross-functional process
                      orchestration.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-analysis"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>The Need for Simplicity</h5>
                    <p class="para text-muted mb-0">
                      A leader in cloud kitchens was able to reduce food wastage
                      by > 30% with better planning and menu engineering
                      processes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-redo"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Eliminating the Mundane</h5>
                    <p class="para text-muted mb-0">
                      A large fashion retailer was able to automate planning and
                      execution processes to allow inventory replenishments 50%
                      faster.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="uil uil-chart-pie"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Strength of Data</h5>
                    <p class="para text-muted mb-0">
                      A leading FMCG manufacturer was able to launch 30% more
                      new products every quarter with stage-gate product
                      development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Vision/Mission End -->

<!-- Photos Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h5 class="text-gray para-desc mx-auto mb-0">
            <span class="text-primary font-weight-bold">Zvolv</span> is the
            future of intelligent enterprise process automation<br />
            And we are busy helping our clients...
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-6 col-12 mt-4 pt-2">
        <div
          class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0"
        >
          <div class="card-body p-0">
            <img
              src="assets/images/about-us/team1.jpg"
              class="img-fluid rounded"
              alt="work-image"
            />
            <div class="overlay-work bg-dark"></div>
            <div class="content w-100">
              <p class="title text-white d-block font-weight-bold">
                Scale new heights
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="row">
          <div class="col-12">
            <div
              class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0"
            >
              <div class="card-body p-0">
                <img
                  src="assets/images/about-us/team2.jpg"
                  class="img-fluid rounded"
                  alt="work-image"
                />
                <div class="overlay-work bg-dark"></div>
                <div class="content w-100">
                  <p class="title text-white d-block font-weight-bold">
                    Navigate choppy waters
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mt-4">
            <div
              class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0"
            >
              <div class="card-body p-0">
                <img
                  src="assets/images/about-us/team3.jpg"
                  width="100%"
                  class="img-fluid rounded"
                  alt="work-image"
                />
                <div class="overlay-work bg-dark"></div>
                <div class="content w-100">
                  <p class="title text-white d-block font-weight-bold">
                    Build awesome applications
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mt-4">
            <div
              class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0"
            >
              <div class="card-body p-0">
                <img
                  src="assets/images/about-us/team4.jpg"
                  width="100%"
                  class="img-fluid rounded"
                  alt="work-image"
                />
                <div class="overlay-work bg-dark"></div>
                <div class="content w-100">
                  <p class="title text-white d-block font-weight-bold">
                    Get to the finish line fast
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <!--end col-->
      <div class="col-12 mt-4 text-center">
        <a
          [routerLink]="['/contact-us']"
          class="btn btn-outline-primary mt-2 mr-2"
          >Join Us</a
        >
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Photos End -->
