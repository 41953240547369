<!-- Hero Start -->
<section class="d-table w-100 h-100 overflow-hidden bg-dark pb-0" id="home">
  <div class="container-hero position-relative">
    <div class="row align-items-center pt-5">
      <div class="col-lg-6 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">
            Introducing the new era of Hyper Automation for enterprises
          </h1>
          <p class="para-desc text-muted">
            <span class="text-primary">Zvolv</span> helps planning, execution and operations teams leverage the
            power of Hyper Automation by accelerating the delivery of key
            insights, assisting in rapid decision-making, reducing execution
            timelines, and automating data-driven transformation.
          </p>
          <div class="mt-4 pt-2">
            <a (click)="openScheduleDemoModal()" class="btn btn-primary"
              >Schedule a demo</a
            ><br />
            <a
              (click)="gotoZvolvAppSignup()"
              class="text-light mb-2 ml-2 mt-3 mr-2 ultr"
              ><b>Try For Free <i class="mdi mdi-arrow-right"></i></b
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative bg-half-170">
          <div class="bg-saas-shape position-relative">
            <img
              src="assets/images/landing/platform-hero.png"
              class="img-fluid mover mx-auto d-block"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--end section-->
<!-- Hero End -->

<!-- Section Start -->
<section class="section overflow-hidden bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2 pt-4">
          <h1 class="title">
            <span class="text-primary">Zvolv</span> integrates business
            processes with intelligent bot automation
          </h1>
          <h1 class="title mb-4">
            With data-driven intelligent bot automation
          </h1>
        </div>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col-md-4 col-12">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/workflow.svg"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-5">
            <h4 class="title-2">Beyond Business Process <br />Management</h4>
            <p class="text-gray mt-4 mb-0">
              Tackle last-mile digital transformation challenges with the power
              of AI, ML, and end-end integration.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5 mt-sm-0">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/robot.svg"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-5">
            <h4 class="title-2">Ahead of Robotic Process <br />Automation</h4>
            <p class="text-gray mt-4 mb-0">
              Augment strategic decision-making with an intelligent bot-driven
              approach.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5 mt-sm-0">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img
              src="assets/images/icon/efficient.svg"
              class="avatar avatar-medium"
              alt=""
            />
          </div>

          <div class="content mt-5">
            <h4 class="title-2">Better TCO than Custom <br />Development</h4>
            <p class="text-gray mt-4 mb-0">
              Unrivaled agility in customization, a total cost of ownership
              unachievable with traditional solutions.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- How it works start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h2 class="title mb-4">
            The Future of Automation Extended to Enterprises
          </h2>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row" id="pills-tab" role="tablist">
      <div class="col-md-4 mt-4 pt-2">
        <div
          class="card features-1 work-process process-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center text-zv-1 px-3">
            <i class="mdi mdi-lightbulb-on-outline"></i>
          </div>

          <div class="card-body">
            <h4 class="title text-dark">Ideate</h4>
            <p class="text-dark mb-0">
              Pair knowledge and experience across organizations into processes
              that deliver peak performance. Define user journeys process,
              dependencies, approvals, timelines, measurable metrics, and more.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div
          class="card features-2 work-process process-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center text-zv-2 px-3">
            <i class="mdi mdi-robot"></i>
          </div>

          <div class="card-body">
            <h4 class="title text-dark">Automate</h4>
            <p class="text-dark mb-0">
              Add intelligence and drive more impact with automation bots to
              tackle complex, data-driven, or error-prone tasks. Connect
              critical 3rd party systems like your ERP, CRM, and more with
              simple yet powerful APIs.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div
          class="card features-3 work-process d-none-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center text-zv-3 px-3">
            <i class="mdi mdi-rocket"></i>
          </div>

          <div class="card-body">
            <h4 class="title text-dark">Evolve</h4>
            <p class="text-dark mb-0">
              Try your Zvolv app in a matter of days, and iterate until
              optimized. Your apps can now evolve as fast as your business. No
              need to worry about servers, databases, security, or scalability -
              Zvolv does all the heavy lifting.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!-- How it works end -->

<!-- Feature Start -->
<section class="section bg-dark">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-12">
        <div>
          <div class="section-title mb-4 pb-2 pt-5">
            <h1 class="title font-weight-bold">
              The <span class="text-primary">Zvolv</span> Advantage
            </h1>
            <h5 class="mb-0">
              Amplifying Individual Impact,<br />
              Empowering Decision Making
            </h5>
            <h1 class="title font-weight-bold mb-0">
              <span
                class="typed-element-wfeat text-primary"
                data-elements="Analyze, Plan, Streamline, Optimize"
              ></span>
            </h1>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-12">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-sitemap"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Improve Decision Making</h5>
                    <p class="para text-gray mb-0">
                      With real time insights into operations, simplified
                      forecasting, predictive analytics
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-crosshairs"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Optimize Operations</h5>
                    <p class="para text-gray mb-0">
                      With data-driven reactive workflows, dynamic process
                      mapping
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-chart-line"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Accelerate Growth</h5>
                    <p class="para text-gray mb-0">
                      With streamlined and time-bound activities synchronized
                      across teams
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4 pt-2">
                <div
                  class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0"
                >
                  <span class="h1 icon2 text-primary">
                    <i class="mdi mdi-checkbox-multiple-marked-outline"></i>
                  </span>
                  <div class="card-body p-0 content">
                    <h5>Get to Peak Efficiency</h5>
                    <p class="para text-gray mb-0">
                      With Intelligent Automation bots and agile Process
                      Orchestration
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Savings calculator Start -->
  <app-savings-calculator></app-savings-calculator>
  <!-- Savings calculator End -->
</section>
<!-- Feature End -->

<!-- Blogs start -->
<app-blogs></app-blogs>
<!-- Blogs end -->

<!-- FAQ n Contact Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              How do business process owners benefit from
              <span class="text-primary">Zvolv</span>?
            </h5>
            <p class="answer text-dark mb-0">
              Zvolv helps CXOs modernize their front lines and back offices by
              introducing AI and Automation into every business process that
              they manage and control. Workflow Automation to reduce errors and
              improve accuracy. Streamlined processes and integration across
              tools to drive synchronized and time bound efforts between teams.
              Intelligent automation bots to eliminate manual tasks and improve
              decision making.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              What is the advantage of <span class="text-primary">Zvolv</span>'s
              Intelligent Automation compared to traditional RPA tools?
            </h5>
            <p class="answer text-dark mb-0">
              Traditional RPA tools will help automate the repeatable and
              mundane data entry and file processing tasks, or legacy system
              integrations. Zvolv's Intelligent Automation bots help stretch
              your automation goals further beyond into the realms of
              data-driven planning, optimization, intelligent decision making
              and machine-learning driven prescriptive and predictive analytics.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              Why is the <span class="text-primary">Zvolv</span> no-code
              platform better compared to traditional application development?
            </h5>
            <p class="answer text-dark mb-0">
              Unlike traditional development approaches which take armies of
              developers and months to launch, Zvolv applications are ready to
              add value now, with little to no dependence on developers or
              back-logged IT teams. Zvolv provides unrivaled agility in
              application customization, offering a total cost of ownership that
              is unachievable with traditional solutions, and at 10x the speed.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              Can <span class="text-primary">Zvolv</span> bots integrate third
              party AI/ML models and APIs?
            </h5>
            <p class="answer text-dark mb-0">
              Make your Zvolv apps intelligent and more impactful by configuring
              automation bots with advanced predictive and prescriptive
              decision-support models leveraging all the data collected in the
              applications, as well as external sources. Zvolv does all the
              heavy lifting for setting up, training and scaling your AI/ML and
              analytics applications. The custom bot builder allows integrating
              any AI/ML model or third-party APIs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--end section-->
<!-- FAQ n Contact End -->

<!-- Shape Start -->
<div class="position-relative" style="z-index: -2">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 75L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
