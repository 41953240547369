<!-- Hero Start -->
<section class="bg-half bg-dark d-table w-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Newsroom </h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start News articles -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-10 col-md-10 col-sm-12 mb-5 pb-2" *ngFor="let newsItem of newsData;">
                <div class="card blog bg-light rounded border-0 shadow overflow-hidden">
                    <div class="row align-items-center no-gutters">
                        <div class="col-md-3">
                            <img class="card-img" [src]="newsItem.imageURL" [alt]="newsItem.title" style="max-height: 175px !important;">
                        </div>
                        <div class="col-md-9 pl-4">
                            <div class="card-body content">
                                <h5>
                                    <a [href]="newsItem.link" target="_blank"
                                        class="card-title title text-dark max-lines max-lines-1">
                                        {{newsItem.title}}
                                    </a>
                                </h5>
                                <p class="text-gray max-lines max-lines-3 mb-0">{{newsItem.description}}
                                </p>
                                <div class="post-meta d-flex justify-content-between mt-3">
                                    <ul class="list-unstyled mb-0">
                                        <li class="list-inline-item mr-2 mb-0"><small class="date"><i
                                                    class="mdi mdi-calendar-check"></i> {{newsItem.date_published}}</small>
                                        </li>
                                    </ul>
                                    <a [href]="newsItem.link" class="text-primary readmore">Read More <i
                                            class="mdi mdi-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End News articles -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Get in touch</h4>
                    <p class="text-muted para-desc mx-auto">
                        <a href="mailto:press@zvolv.com" class="text-primary"><i
                                class="mdi mdi-email mr-1"></i>press@zvolv.com</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>