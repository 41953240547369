import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  constructor(private modalService: NgbModal, public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
  }

  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
  }

  gotoZvolvAppSignup(): void {

    // Send data to Google analytics
    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/jemoCMb0lfABEOb10t4B");

    window.open("https://app.zvolv.com/signup/create", "_blank");
  }

}
