import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FullPagesRoutingModule } from './full-pages-routing.module';
import { HomeComponent } from './home/home.component';
import { PlatformComponent } from './platform/platform.component';
import { WhyZvolvComponent } from './why-zvolv/why-zvolv.component';
import { CustomersComponent } from './customers/customers.component';
import { PricingComponent } from './pricing/pricing.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SharedModule } from '../shared/shared.module';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SecurityComponent } from './security/security.component';
import { FeaturesComponent } from './features/features.component';
import { FaqComponent } from './faq/faq.component';
import { PricingCaseStudyComponent } from './pricing-case-study/pricing-case-study.component';
import { ZvolvVsAlternativesComponent } from './zvolv-vs-alternatives/zvolv-vs-alternatives.component';
import { ScheduleDemoComponent } from './schedule-demo/schedule-demo.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { PartnersComponent } from './partners/partners.component';
import { NewsroomComponent } from './newsroom/newsroom.component';
import { BecomeAPartnerComponent } from './partners/become-a-partner/become-a-partner.component';
import { IntroComponent } from './intro/intro.component';
import { CareersComponent } from './careers/careers.component';
import { JobListComponent } from './careers/job-list/job-list.component';
import { JobDetailComponent } from './careers/job-detail/job-detail.component';
import { JobApplyComponent } from './careers/job-apply/job-apply.component';

@NgModule({
  declarations: [
    HomeComponent,
    PlatformComponent,
    WhyZvolvComponent,
    CustomersComponent,
    PricingComponent,
    AboutUsComponent,
    ContactUsComponent,
    TermsComponent,
    PrivacyComponent,
    SecurityComponent,
    FeaturesComponent,
    FaqComponent,
    PricingCaseStudyComponent,
    ScheduleDemoComponent,
    ZvolvVsAlternativesComponent,
    IntegrationsComponent,
    PartnersComponent,
    NewsroomComponent,
    BecomeAPartnerComponent,
    IntroComponent,
    CareersComponent,
    JobListComponent,
    JobDetailComponent,
    JobApplyComponent
  ],
  entryComponents: [
    JobApplyComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    CommonModule,
    SharedModule,
    CarouselModule,
    FullPagesRoutingModule
  ]
})
export class FullPagesModule { }
