<!-- Blogs start -->
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center mb-4">
            <div class="col-lg-6">
                <div class="section-title text-center">
                    <h6 class="text-primary">Blog</h6>
                    <h4 class="title mb-4 mb-lg-0">Read Our Latest News & Blogs</h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let blog of blogsList">
                <div class="card blog rounded border-0 shadow">
                    <div class="position-relative">
                        <img *ngIf="blog._embedded['wp:featuredmedia']" [src]="blog._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url"
                            class="card-img-top rounded-top" [alt]="blog.title.rendered">
                        <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div class="card-body content">
                        <h5><a [href]="blog.link" class="card-title title text-dark"
                                target="_blank">{{blog.title.rendered}}</a></h5>
                        <div class="post-meta d-flex justify-content-between mt-3">
                            <small class="user d-block"><i class="mdi mdi-account"></i>
                                {{blog._embedded.author[0].name}}</small>
                            <small class="date"><i class="mdi mdi-calendar-check"></i>
                                {{getFormattedDate(blog.date)}}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
                <a href="https://zvolv.com/blog" class="btn btn-primary">See more <i
                        class="mdi mdi-arrow-right"></i></a>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!-- Blogs end -->