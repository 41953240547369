<!-- Price start -->
<section class="section bg-dark">
  <div class="container-hero position-relative">
    <div class="row">
      <div class="col-12">
        <div class="m-4 pb-2">
          <h1 class="title mb-4">
            Delivering the highest ROI at the lowest TCO
          </h1>
          <p class="mb-0 mx-auto">
            Compared to traditional custom application development,<br />
            <span class="text-primary font-weight-bold">Zvolv</span> apps are
            more than 50% lower cost to develop and more than 3x faster to
            launch
          </p>
        </div>
      </div>
    </div>

    <div class="row text-black">
      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div
          class="card features-1 pricing-rates business-rate shadow rounded text-center border-0"
        >
          <div class="card-body p-0">
            <h2>Free</h2>
            <p class="text-dark mb-4"><b>Tinker all you want</b></p>
            <p>Free Plan is best for self exploration and feature evaluation</p>
            <div class="d-flex justify-content-center">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">0</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>
            <a (click)="gotoZvolvAppSignup()" class="btn btn-1 w-50 mb-2 mt-4"
              >Get Started</a
            >
          </div>
        </div>
        <div class="p-5" style="text-align: left !important">
          <ul class="list-unstyled mb-0">
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >25 Zblocks
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >10 Users, 1 Zapp
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >All BPM Features + Bot Library
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >No-code workflow builder access
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div
          class="card features-2 pricing-rates business-rate shadow rounded text-center border-0"
        >
          <div class="card-body p-0">
            <h2>Business</h2>
            <p class="text-dark mb-4"><b>Build it yourself</b></p>
            <p>
              Business Plan is best for simple business process management and
              workflow applications with basic automations
            </p>
            <div class="d-flex justify-content-center">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h2 mb-0">2000</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>
            <a (click)="openScheduleDemoModal()" class="btn btn-2 w-50 mt-4 mb-2"
              >Request a demo</a
            >
          </div>
        </div>
        <div class="p-5" style="text-align: left !important">
          <ul class="list-unstyled mb-0">
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >Limited Zblocks
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >Limited Users
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >All BPM Features + Bot Library
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >No-code workflow and bot builder access
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >12/5 Email and Phone support
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-4 col-12 mt-4 pt-2">
        <div
          class="card features-3 pricing-rates business-rate shadow rounded text-center border-0"
        >
          <div class="card-body p-0">
            <h2>Enterprise</h2>
            <p class="text-dark mb-4"><b>All the support you need</b></p>
            <p>
              Enterprise Plan is best for complex applications combining
              intelligent automation with business process management
            </p>
            <div class="d-flex justify-content-center">
              <span class="price h2 mb-0">Let's Talk</span>
            </div>
            <a (click)="openScheduleEntDemoModal()" class="btn btn-3 w-50 mt-4 mb-2"
              >Contact Sales</a
            >
          </div>
        </div>
        <div class="p-5" style="text-align: left !important">
          <ul class="list-unstyled mb-0">
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >Telescopic Zblock pricing
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >Unlimited Users, Unlimited Zapps
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >All BPM Features + Bot Library
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              >No-code workflow and bot builder access
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              ><strong>Developer tools for custom BOT automation</strong>
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              ><strong>Drill-down analytics and reporting engine</strong>
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              ><strong>Private Cloud or On-prem hosting options</strong>
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              ><strong>White-labelled applications</strong>
            </li>
            <li class="h6 text-gray mb-0">
              <span class="text-primary h5 mr-2"
                ><i class="mdi mdi-check-circle"></i></span
              ><strong>VIP On-boarding + Hypercare Support</strong>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 mt-4 ml-4">
        <p class="text-muted">
          A <span class="h6 text-white">Zblock </span> is a building block, can be
          a human task, data form, automation bot, dashboard widget or
          integration database.<br />
          A <span class="h6 text-white">Zapp</span> is an individual workflow
          application, made by logically and contextually interconnecting
          Zblocks.<br />
          A <span class="h6 text-white">Workspace</span> is a web-portal or mobile
          application that serves an end-end process, providing access to one or
          more Zapps.<br />
          <span class="h6 text-white">VIP on-boarding:</span> 1 month hands-on POC
          with 1 BA + 1 Developer<br />
          <span class="h6 text-white">Hypercare support:</span> Dedicated account
          manager with 14/7 access over email/phone/chat, enterprise SLAs
        </p>
      </div>
    </div>
  </div>
  <!-- Savings calculator Start -->
  <app-savings-calculator></app-savings-calculator>
  <!-- Savings calculator End -->
</section>
<!-- Price End -->

<!-- Testi Start -->
<app-testimonials></app-testimonials>
<!-- Testi End -->

<!-- FAQ n Contact Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              How does the <span class="text-primary">Zvolv</span> pricing model
              work?
            </h5>
            <p class="answer text-gray mb-0">
              We understand the pain of managing user licenses and exponentially
              growing costs when applications are widely launched both inside
              and outside your organizations. With Zvolv’s building block
              licensing model, you only pay for what you build, not how widely
              or often you use the tools. You can start with small processes and
              keep building on top as you build more confidence and drive user
              adoption. Check out our savings comparison tool.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              What customization flexibility exists with
              <span class="text-primary">Zvolv</span>?
            </h5>
            <p class="answer text-gray mb-0">
              We understand that as organizations scale, the need for
              differentiation grows. Off-the-shelf solutions may seem attractive
              with deep domain specific features, but offer shallow
              customizations. Zvolv on the other hand offers you the ability to
              adapt your tools to your processes with a low-code Python
              environment to write your intelligent automation bots and custom
              integrations, at 10x the speed of traditional code development.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              Can the <span class="text-primary">Zvolv</span> applications be
              white-labeled?
            </h5>
            <p class="answer text-gray mb-0">
              Zvolv workspaces and Zapps are accessed via the web portal at
              your-workspace-name.zvolv.com, or via the Zvolv Android and iOS
              apps. Premium customers have the option to configure custom URLs
              for web portal access, as well as white-labeled applications for
              Android and iOS. Zvolv applications have many UI/UX configuration
              options to customize it to your branding scheme and desired usage
              internal and external to the organization
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="media">
          <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
          <div class="media-body">
            <h5 class="mt-0">
              How fast can applications be launched with
              <span class="text-primary">Zvolv</span>?
            </h5>
            <p class="answer text-gray mb-0">
              Zvolv gives you the power to define your processes via tasks,
              dependencies, approvals, timelines, metrics used to measure each
              step, check lists and more. Add and enforce consistency in data,
              documents and decision-making. Typically, applications get
              configured and functionally deployed in under a week. Make your
              Zvolv apps intelligent and more impactful by configuring
              automation bots to tackle complex, data-driven, repetitive tasks,
              or with advanced AI/ML models. Effort may range from days to weeks
              depending on complexity.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div
      class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center"
    >
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Have Question ? Get in touch!</h4>
          <div class="mt-4 pt-2">
            <a [routerLink]="['/contact-us']" class="btn btn-primary"
              >Contact us <i class="mdi mdi-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- FAQ n Contact End -->

<!-- Shape Start -->
<div class="position-relative" style="z-index: -2">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 100L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
