<div class="modal-body p-0 bg-demo-modal">
    <div class="bg-overlay bg-overlay-white"></div>
    <button type="button" class="float-right close p-3" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="row ml-0 mr-0 justify-content-center ">
        <div class="col-lg-8 col-md-12 col-sm-12 mb-2">
            <div class="section-title mt-4">
                <h4 id="modal-basic-title">
                    Unleash the power of Hyper Automation with <span class="text-primary">Zvolv</span>
                </h4>
                <p class="text-gray">
                    Connect your people, processes, and systems to become an agile orgnaization.
                </p>
            </div>

            <form class="bg-white shadow p-4" [formGroup]="requestDemoForm">

                <div class="form-row">
                    <div class="form-group col-12">
                        <label>Name <span class="text-danger">*</span></label>
                        <i class="mdi mdi-account icons text-muted mx-auto my-auto"></i>
                        <input type="text" formControlName="name" class="form-control pl-5"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required</div>
                            <div *ngIf="f.name.errors.minlength">Name must be at least 3 characters</div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                        <label>Email <span class="text-danger">*</span></label>
                        <i class="mdi mdi-email-outline icons text-muted mx-auto my-auto"></i>
                        <input type="text" formControlName="email" class="form-control pl-5"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                        <label>Phone <span class="text-danger">*</span></label>
                        <i class="mdi mdi-phone-outline icons text-muted mx-auto my-auto"></i>
                        <input type="tel" formControlName="phone" class="form-control pl-5"
                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">Phone number is required</div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                        <label>Company <span class="text-danger">*</span></label>
                        <i class="mdi mdi-garage icons text-muted mx-auto my-auto"></i>
                        <input type="text" formControlName="company" class="form-control pl-5"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">Company name is required</div>
                            <div *ngIf="f.company.errors.minlength">Company name must be at least 3 characters</div>
                        </div>
                    </div>
                </div>

                

                <div class="form-group">
                    <label class="form-check-label">By proceeding, you agree to our 
                        <a href="terms" target="_blank">Terms of Service</a> and
                        <a href="privacy" target="_blank">Privacy Policy</a></label>
                </div>

                <re-captcha #captchaRefRD="reCaptcha" (resolved)="resolved($event)"
                    siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ" size="invisible" badge="inline"></re-captcha>

                <div *ngIf="!showSuccess" class="text-center">
                    <button class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="showLoader">Schedule a
                        demo <i *ngIf="showLoader" class="mdi mdi-loading mdi-spin"></i></button>
                </div>
                <div *ngIf="showSuccess" class="alert alert-success mt-2" style="cursor: pointer;" role="alert"
                    (click)="showSuccess = false;onReset();dismiss();">
                    Thank you for contacting us. Our representative will reach out soon. <b>Keep exploring <i
                            class="mdi mdi-arrow-right-bold"></i></b>
                </div>
                <div *ngIf="showError" class="alert alert-danger mt-2" role="alert">
                    <button type="button" class="float-right close" aria-label="Close" (click)="showError = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    Error while submitting form. Please try again.
                    <div *ngIf="errorMsg">
                        {{errorMsg}}
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>