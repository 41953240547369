import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { DownloadManagerModalComponent } from '../download-manager-modal/download-manager-modal.component';

declare var $: any

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private modalService: NgbModal, public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.initNavbar()
  }

  initNavbar() {
    // Menu
    $('.navbar-toggle').on('click', function (event) {
      $(this).toggleClass('open');
      $('#navigation').slideToggle(400);
    });

    $('.navigation-menu>li').slice(-1).addClass('last-elements');

    $('.menu-arrow,.submenu-arrow').on('click', function (e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
      }
    });

    $(".navigation-menu a").each(function () {
      console.log(this.href, ":", window.location.href)
      if (this.href == window.location.href) {
        $(this).parent().addClass("active");
        $(this).parent().parent().parent().addClass("active");
        $(this).parent().parent().parent().parent().parent().addClass("active");
      }
    });

    // Clickable Menu
    $(".has-submenu a").click(function () {
      if (window.innerWidth < 992) {
        if ($(this).parent().hasClass('open')) {
          $(this).siblings('.submenu').removeClass('open');
          $(this).parent().removeClass('open');
        } else {
          $(this).siblings('.submenu').addClass('open');
          $(this).parent().addClass('open');
        }
      }
    });

    $('.mouse-down').on('click', function (event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top - 72
      }, 1500, 'easeInOutExpo');
      event.preventDefault();
    });

    $('.navbar-collapse ul li a:not(.dropdown-toggle-z)').click(function () {
      $('.navbar-toggle:visible').click();
    });

  }

  showBanner = false
  openDownloadCaseStudyModal(cslink) {
    const modalRef = this.modalService.open(DownloadManagerModalComponent, { backdrop: 'static', size: 'lg', windowClass: 'dark-modal' })
    modalRef.componentInstance.cslink = cslink
    modalRef.componentInstance.saveToDisk = true
  }

  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
  }

  gotoZvolvAppSignup(): void {

    // Send data to Google analytics
    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/jemoCMb0lfABEOb10t4B");

    window.open("https://app.zvolv.com/signup/create", "_blank");
  }
}
