import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobApplyComponent } from '../job-apply/job-apply.component';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit, AfterViewInit {

  jobId: any = null
  jobDetails: any = null

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe((params) => {
        if (params.jobId) {
          this.jobId = params.jobId
        }
      })
  }

  ngAfterViewInit() {
    this.onFetchJobDetails()
  }

  @ViewChild('captchaRefJD') public captchaRefJD: RecaptchaComponent;

  onFetchJobDetails() {
    // Invoke captcha. This will invoke submit form API on resolving captcha key.
    this.captchaRefJD.execute()
  }

  public captchaResponse: string = '';
  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse
    this.getCareerTypesFromServer(this.captchaResponse)
  }

  getCareerTypesFromServer(captcha) {

    // 1. Get reCaptcha key (via recaptcha lib)
    // 2. Get QR Code
    var getQRCodeUrl = 'https://app.zvolv.com/rest/v17/decode/qr/34F5GP6YL35CN'
    var reqheaders = {
      businessDomain: 'sales'
    }
    var qrapidata = { "g-recaptcha-response": captcha }
    this.httpService.postConfig(getQRCodeUrl, JSON.stringify(qrapidata), reqheaders)
      .subscribe(
        (data) => {

          if (data && !data['error']) {
            var loginToken = data['data']['UserDetails']['loginToken']

            // 3. Get form submissions
            var getFormSubmissionsUrl = 'https://app.zvolv.com/rest/v17/lite/submissions/' + this.jobId
            var reqheaders = {
              'Content-Type': 'application/json',
              jwt: loginToken,
              LoginToken: loginToken,
              businessDomain: 'sales'
            }

            this.httpService.get(getFormSubmissionsUrl, reqheaders)
              .subscribe(
                (response) => {
                  if (!response['error']) {
                    var tempResponse = response['data']['elements']
                    for (var t = tempResponse.length - 1; t >= 0; t--) {
                      var tempJobObj = {
                        'id': tempResponse[t].formSubmissionId,
                        'title': (tempResponse[t].inputs.filter(s => s.label.includes('Title'))[0].value),
                        'description': (tempResponse[t].inputs.filter(s => s.label.includes('Description'))[0].value),
                        'responsibilities': (tempResponse[t].inputs.filter(s => s.label.includes('Responsibilities'))[0].value),
                        'qualifications': (tempResponse[t].inputs.filter(s => s.label.includes('Qualifications'))[0].value),
                        'skills': (tempResponse[t].inputs.filter(s => s.label.includes('Preferred Skills and Knowledge'))[0].value),
                        'location': (tempResponse[t].inputs.filter(s => s.label.includes('Location'))[0].value),
                        'type': (tempResponse[t].inputs.filter(s => s.label.includes('Type'))[0].value),
                        'category': (tempResponse[t].inputs.filter(s => s.label.includes('Category'))[0].value),
                        'experience': (tempResponse[t].inputs.filter(s => s.label.includes('Experience'))[0].value),
                        'salary': (tempResponse[t].inputs.filter(s => s.label.includes('Salary'))[0].value),
                        'date_posted': this.getFormattedDate(tempResponse[t].dateCreated)
                      }
                      this.jobDetails = tempJobObj
                    }
                  }
                },
                error => {
                  console.log("Error in fetching job details", error)
                }
              );
          }
        },
        error => {
          console.log("Error in fetching job details", error)
        }
      );
  }

  dateFormatOptions: any = { year: 'numeric', month: 'long', day: 'numeric' };
  getFormattedDate(dateStr) {
    var d = new Date(dateStr);
    return d.toLocaleDateString("en-US", this.dateFormatOptions)
  }

  openApplicationFormModal() {
    const modalRef = this.modalService.open(JobApplyComponent, { backdrop: 'static', size: 'lg', windowClass: 'dark-modal' })
    modalRef.componentInstance.jobTitle = this.jobDetails.title
  }

}
