import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SeoPagesRoutingModule } from './seo-pages-routing.module';
import { AnAppAWeekComponent } from './an-app-a-week/an-app-a-week.component';
import { HowToMapProcessComponent } from './how-to-map-process/how-to-map-process.component';


@NgModule({
  declarations: [
    AnAppAWeekComponent,
    HowToMapProcessComponent
  ],
  imports: [
    CommonModule,
    SeoPagesRoutingModule
  ]
})
export class SeoPagesModule { }
