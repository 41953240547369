<!-- Hero Start -->
<section class="d-table w-100 h-100 overflow-hidden bg-dark" id="home">
    <div class="container position-relative">
        <div class="row align-items-center pt-5">
            <div class="col-lg-7 col-md-6">
                <div class="title-heading">
                    <h1 class="heading mb-3">Our Partners Focus on Your Success</h1>
                    <p class="para-desc text-muted">Experts in building, delivering and positioning low-code projects,
                        our partners have one goal: <b>customer success</b>.</p>
                    <p class="para-desc text-muted">With industry expertise and exclusive access to the tools and
                        resources required to help you achieve business results, Zvolv Partners help fuel your
                        innovation.</p>
                    <div class="mt-4 pt-2">
                        <a [routerLink]="['/partners/become-a-partner']" class="btn btn-primary"><b>Become a
                                Partner</b></a><br>
                        <a (click)="scroll('zvolv-partners')" class="text-primary mb-2 ml-2 mt-3 mr-2 ultr"><b>Find a
                                Partner
                                <i class="mdi mdi-arrow-right"></i></b></a>

                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="classic-saas-image position-relative bg-half-170">
                    <div class="bg-saas-shape position-relative">
                        <img src="assets/images/landing/why-zvolv.png" class="mover mx-auto d-block" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Partners -->
<section class="section" id="zvolv-partners">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h2 class="title"><span class="text-primary">Zvolv</span> Partners</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
                <div class="card border-0 sidebar">
                    <div class="card-body p-0">
                        <!-- CATAGORIES -->
                        <div class="widget mt-2 pt-2">
                            <h4 class="widget-title">Catagories</h4>
                            <ul class="list-unstyled mt-4 mb-0 blog-catagories">
                                <li><a href="jvascript:void(0)">Agriculture, Forestry, Fishing and Hunting</a></li>
                                <li><a href="jvascript:void(0)">Mining</a></li>
                                <li><a href="jvascript:void(0)">Utilities</a></li>
                                <li><a href="jvascript:void(0)">Construction</a></li>
                                <li><a href="jvascript:void(0)">Manufacturing</a></li>
                                <li><a href="jvascript:void(0)">Wholesale</a></li>
                                <li><a href="jvascript:void(0)">Retail</a></li>
                                <li><a href="jvascript:void(0)">Transportation and Warehousing</a></li>
                                <li><a href="jvascript:void(0)">Information</a></li>
                                <li><a href="jvascript:void(0)">Finance and Insurance</a></li>
                                <li><a href="jvascript:void(0)">Real Estate Rental and Leasing</a></li>
                                <li><a href="jvascript:void(0)">Professional, Scientific, and Technical Services</a>
                                </li>
                                <li><a href="jvascript:void(0)">Consulting Services</a></li>
                                <li><a href="jvascript:void(0)">Waste Management and Remediation Services</a></li>
                                <li><a href="jvascript:void(0)">Educational Services</a></li>
                                <li><a href="jvascript:void(0)">Health Care and Social Assistance</a></li>
                                <li><a href="jvascript:void(0)">Arts, Entertainment, and Recreation</a></li>
                                <li><a href="jvascript:void(0)">Accommodation and Food Services</a></li>
                                <li><a href="jvascript:void(0)">Public Administration</a></li>
                            </ul>
                        </div>
                        <!-- CATAGORIES -->
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-8 col-12">

                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 pull-right">
                        <!-- SEARCH -->
                        <div class="card border-0 sidebar mr-2">
                            <div class="card-body p-0">
                                <div class="widget">
                                    <div id="search2" class="widget-search mb-0">
                                        <form role="search" method="get" id="searchform" class="searchform">
                                            <div>
                                                <input type="text" class="border rounded" name="s" id="s"
                                                    placeholder="Search for partners, projects..."
                                                    (keyup)="search($event.target.value)">
                                                <input type="submit" id="searchsubmit" value="Search">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- SEARCH -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="card rounded bg-light overflow-hidden border-0 m-2 mt-4"
                            *ngFor="let partner of filteredData;">
                            <div class="row align-items-center no-gutters">
                                <div class="col-md-4">
                                    <img [src]="partner.imageURL" class="img-fluid" [alt]="partner.name"
                                        style="max-height: 175px !important;">
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h4 class="text-dark product-name">{{partner.name}}</h4>
                                        <ul class="list-unstyled mb-0">
                                            <li class="list-inline-item"
                                                *ngFor="let i of [].constructor(partner.rating);"><i
                                                    class="mdi mdi-star text-warning"></i></li>
                                        </ul>
                                        <p class="text-muted h6 mb-0 font-italic">{{partner.description}}</p>
                                        <div class="row mt-3">
                                            <div class="col-9">
                                                <span class="badge badge-primary mr-1"
                                                    *ngFor="let tag of partner.tags;">
                                                    {{tag}} </span>
                                            </div>
                                            <div class="col-3" *ngIf="partner.num_projects">
                                                <h6 class="mb-0"><i
                                                        class="mdi mdi-checkbox-multiple-marked-circle mr-1"></i>{{partner.num_projects}}+
                                                </h6>
                                                <h6 class="counter-head text-muted mb-0">Projects</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Partners -->

<section class="section" id="zvolv-tech-partners">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h2 class="title"><span class="text-primary">Zvolv</span> Technology Partners</h2>
                    <p class="text-muted">We have partnered with some of the world’s leading
                        technology leaders to offer cutting-edge IT services to our clients. Through our technology
                        partnerships, we provide top-rated IT services that enable our clients to address their IT
                        challenges.</p>
                </div>
            </div>
        </div>
        <div class="row container-grid projects-wrapper">
            <div class="col-lg-3 col-md-6 col-12 spacing mt-3 branding">
                <div
                    class="card shadow border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                    <div class="card-body">
                        <a class="mfp-image d-inline-block">
                            <img src="assets/images/partners/tech-partners/amazon.svg" width="100%" class="img-fluid"
                                alt="work-image">
                        </a>
                        <div class="content bg-white p-3">
                            <h5 class="mb-0"><a href="#" class="text-dark title">Amazon AWS</a>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12 spacing mt-3 branding">
                <div
                    class="card shadow border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                    <div class="card-body">
                        <a class="mfp-image d-inline-block">
                            <img src="assets/images/partners/tech-partners/google.svg" width="100%" class="img-fluid"
                                alt="work-image">
                        </a>
                        <div class="content bg-white p-3">
                            <h5 class="mb-0"><a href="#" class="text-dark title">Google Cloud Platform</a>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Start working with <span class="text-primary font-weight-bold">Zvolv</span>
                    </h4>
                    <div class="mt-4 pt-2">
                        <a [routerLink]="['/partners/become-a-partner']" class="btn btn-primary"><b>Become a
                                Partner</b></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Shape Start -->
<div class="position-relative mt-5" style="z-index: -2;">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 75L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->