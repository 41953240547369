<!-- Start Contact -->
<section class="section w-100 h-100 bg-light pb-0">
  <div
    class="container position-relative container-fluid container-znav mt-100 mt-60"
  >
    <div class="row align-items-center">
      <div class="col-lg-1 col-md-1 my-auto"></div>
      <div class="col-lg-5 col-md-5 my-auto">
        <div class="title-heading ml-lg-4">
          <h4 class="mb-4">Have Questions? Get in touch!</h4>

          <div class="media contact-detail align-items-center mt-3">
            <div class="media-body content">
              <h4 class="title font-weight-bold mb-0 text-zv-2">INDIA</h4>
              <a href="mailto:hello@zvolv.com" class="text-primary"
                >hello@zvolv.com</a
              >
              <p class="text-gray mb-0">+91-7002587748</p>
              <p class="text-gray mb-0">FC Road, Pune, India</p>
            </div>
          </div>

          <div class="media contact-detail align-items-center mt-4">
            <div class="media-body content">
              <h4 class="title font-weight-bold mb-0 text-zv-1">USA</h4>
              <a href="mailto:hello@zvolv.com" class="text-primary"
                >hello@zvolv.com</a
              >
              <p class="text-gray mb-0">+1-650-215-0220</p>
              <p class="text-gray mb-0">Mountain View, CA, USA</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 my-auto">
        <p class="para-desc mt-100 mt-60">
          Unleash the power of <span class="text-primary">Zvolv</span> for your
          business.<br />
          Connect your people, processes and systems with intelligent
          automation.
        </p>
        <div class="custom-form mt-4">
          <div id="message"></div>

          <form [formGroup]="contactForm">
            <div class="form-row">
              <div class="form-group col-12">
                <label>Name <span class="text-danger">*</span></label>
                <i class="mdi mdi-account icons text-muted mx-auto my-auto"></i>
                <input
                  type="text"
                  formControlName="name"
                  class="form-control pl-5"
                  [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                />
                <div
                  *ngIf="submitted && f.name.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.name.errors.required">Name is required</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12">
                <label>Email <span class="text-danger">*</span></label>
                <i
                  class="mdi mdi-email-outline icons text-muted mx-auto my-auto"
                ></i>
                <input
                  type="email"
                  formControlName="email"
                  class="form-control pl-5"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                />
                <div
                  *ngIf="submitted && f.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">
                    Email must be a valid email address
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12">
                <label>Comments <span class="text-danger">*</span></label>
                <i
                  class="mdi mdi-comment-outline icons text-muted mx-auto my-auto"
                ></i>
                <textarea
                  name="comments"
                  id="comments"
                  rows="4"
                  class="form-control pl-5"
                  formControlName="comments"
                  [ngClass]="{ 'is-invalid': submitted && f.comments.errors }"
                  placeholder="Any specific requests"
                ></textarea>
                <div
                  *ngIf="submitted && f.comments.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.comments.errors.required">
                    Message is required
                  </div>
                </div>
              </div>
            </div>

            <re-captcha
              #captchaRefCU="reCaptcha"
              (resolved)="resolved($event)"
              siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ"
              size="invisible"
              badge="inline"
            ></re-captcha>

            <div *ngIf="!showSuccess" class="text-center">
              <button
                class="btn btn-primary btn-block"
                (click)="onSubmit()"
                [disabled]="showLoader"
              >
                Send <i *ngIf="showLoader" class="mdi mdi-loading mdi-spin"></i>
              </button>
            </div>
            <div
              *ngIf="showSuccess"
              class="alert alert-success mt-2"
              role="alert"
            >
              Thank you for contacting us. Our representative will reach out to
              you soon.
              <button
                type="button"
                class="float-right close"
                aria-label="Close"
                (click)="showSuccess = false; onReset()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div *ngIf="showError" class="alert alert-danger mt-2" role="alert">
              <button
                type="button"
                class="float-right close"
                aria-label="Close"
                (click)="showError = false"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              Error while submitting form. Please try again.
              <div *ngIf="errorMsg">
                {{ errorMsg }}
              </div>
            </div>
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->
