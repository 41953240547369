import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { RecaptchaComponent } from 'ng-recaptcha';

import Typed from 'typed.js';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { DownloadManagerModalComponent } from 'src/app/shared/download-manager-modal/download-manager-modal.component';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  @ViewChild('captchaRefCU') public captchaRefCU: RecaptchaComponent;

  contactForm: FormGroup;
  submitted = false;
  showLoader = false;
  showSuccess = false
  showError = false
  errorMsg = null

  customOwlOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    }
  }

  public newsData: any = []

  constructor(private httpClient: HttpClient, private meta: Meta, private modalService: NgbModal, private formBuilder: FormBuilder, private httpService: HttpService, public googleAnalyticsService: GoogleAnalyticsService) {
    this.meta.addTag({ name: 'description', content: 'Zvolv’s No-code Process Automation is the most upgraded, innovative and personalized technology solutions to drive excellence in business operations.' });
    this.meta.addTag({ name: 'keywords', content: 'Intelligent Process Automation, Process Automation' });
  }

  ngOnInit(): void {
    const options = {
      strings: ['Planning', 'Execution', 'Automation'],
      typeSpeed: 100,
      backSpeed: 100,
      showCursor: true,
      cursorChar: '|',
      loop: true
    };

    const typed = new Typed('.typed-element-feat', options);


    $('.venobox').venobox({
      framewidth: '1280px',
      frameheight: '720px',
      autoplay: true,
      spinner: 'double-bounce',
      spinColor: '#2bb673'
    });

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      comments: ['', Validators.required]
    });

    this.httpClient.get("assets/resources/newsroom.json").subscribe(data => {
      this.newsData = data
    })
  }

  gotoZvolvAppSignup(): void {

    // Send data to Google analytics
    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/jemoCMb0lfABEOb10t4B");

    window.open("https://app.zvolv.com/signup/create", "_blank");
  }

  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
  }

  openDownloadCaseStudyModal(cslink) {
    const modalRef = this.modalService.open(DownloadManagerModalComponent, { backdrop: 'static', size: 'lg', windowClass: 'dark-modal' })
    modalRef.componentInstance.cslink = cslink
    modalRef.componentInstance.saveToDisk = true
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    // Invoke captcha. This will invoke submit form API on resolving captcha key.
    this.captchaRefCU.execute()
  }

  onReset() {
    this.contactForm.reset();
    this.submitted = false;
  }

  public captchaResponse: string = '';
  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse
    this.submitForm(this.captchaResponse)
  }

  resetVar() {
    this.showSuccess = false
    this.showError = false
    this.errorMsg = null
  }

  submitForm(captcha) {

    this.resetVar()
    this.showLoader = true

    // 1. Get reCaptcha key (via recaptcha lib)
    // 2. Get QR Code
    var getQRCodeUrl = 'https://app.zvolv.com/rest/v17/decode/qr/34F5GP6YL35CN'
    var reqheaders = {
      businessDomain: 'sales'
    }
    var qrapidata = { "g-recaptcha-response": captcha }
    this.httpService.postConfig(getQRCodeUrl, JSON.stringify(qrapidata), reqheaders)
      .subscribe(
        (data) => {

          this.showLoader = false

          if (data && !data['error']) {
            var loginToken = data['data']['UserDetails']['loginToken']

            // 3. Submit form
            var submitFormUrl = 'https://app.zvolv.com/rest/v17/5WGCU2Z62RZV3/forms/22373/submissions/'
            var reqheaders = {
              'Content-Type': 'application/json',
              jwt: loginToken,
              LoginToken: loginToken,
              businessDomain: 'sales'
            }
            var submitformapidata = {
              "FormData": {
                "type": "KEY_LABELS",
                "data": {
                  "Name": this.contactForm.value.name,
                  "Email": this.contactForm.value.email,
                  "Message": this.contactForm.value.comments
                }
              }
            }
            this.httpService.postConfig(submitFormUrl, JSON.stringify(submitformapidata), reqheaders)
              .subscribe(
                (data) => {

                  this.showLoader = false

                  if (data && data['error']) {
                    this.showError = true
                    this.errorMsg = data['message']
                  }
                  else {
                    this.showSuccess = true
                  }
                },
                error => {
                  this.showLoader = false
                  this.showError = true
                  this.errorMsg = error
                  console.log("Error submitting form", error)
                }
              );
          }
        },
        error => {
          this.showLoader = false
          this.showError = true
          this.errorMsg = error
          console.log("Error getting QR Code of form", error)
        }
      );
  }

}
