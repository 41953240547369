<!-- Hero Start -->
<section class="d-table w-100 overflow-hidden bg-dark" id="home">
    <div class="container position-relative">
        <div class="row align-items-center pt-5">
            <div class="col-lg-7 col-md-6">
                <div class="title-heading">
                    <h1 class="heading mb-3">Introducing the new era of Intelligent Automation for everyone</h1>
                    <p class="para-desc text-muted">The Zvolv workflow and process automation platform enables you to to
                        build applications to drive your organizations unique repeatable processes, in a few easy steps.
                    </p>
                    <div class="mt-4 pt-2">
                        <a (click)="openScheduleDemoModal()" class="btn btn-primary">Schedule a demo</a><br>
                        <a (click)="gotoZvolvAppSignup()" class="text-light mb-2 ml-2 mt-3 mr-2 ultr"><b>Try
                                For Free <i class="mdi mdi-arrow-right"></i></b></a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="classic-saas-image position-relative bg-half-170">
                    <div class="bg-saas-shape position-relative">
                        <img src="assets/images/landing/why-zvolv.png" class="mover mx-auto d-block" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h2 class="title mb-4">How to map a process on <span class="text-primary">Zvolv</span>?</h2>
                    <p class="text-muted">
                        Chaotic collaboration abounds in many workplaces. It’s exciting when
                        organizations are young, but as they grow, processes need to evolve from chaotic to organized –
                        fast.
                    </p>
                    <p class="text-muted">
                        Structured and automated processes are the foundation on which organisations can grow and become
                        more efficient. They also go a long way towards improving individual and team productivity and
                        morale and most importantly, have drive predictable outcomes.
                    </p>
                    <p class="text-muted">
                        Selecting the right process to automate requires a nuanced approach, with foresight to drive
                        change management and user adoption, integrations with other tools to bridge all the gaps, and
                        well articulated goals in terms of efficiency improvement or cost savings. If you don’t, your
                        people will end up using the tools they always have – which often time means a fall back to
                        emails, meetings, phone calls and he said / she said, and excel files galore. Not an efficient
                        way to scale.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-muted">
                        <span class="text-primary">1.</span>
                        Identify the right process
                    </h4>
                    <h3>Is it a repeatable process? Can it be broken down into individual or team activities and roles?
                    </h3>
                    <p class="para-desc text-muted">
                        Zvolv gives you the power to define your process via tasks, dependencies, approvals, timelines,
                        metrics used to measure each step, check lists and more at a granular level. Keep it high level,
                        or go as deep as you need, Zvolv gives you the flexibility and guides you along the way.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-muted">
                        <span class="text-primary">2.</span>
                        Add structure to the process
                    </h4>
                    <h3>Can the data collection, sharing and analysis be standardised? Can decision making be based on
                        business rules?</h3>
                    <p class="para-desc text-muted">
                        Zvolv enables you to add and enforce consistency in data and documents collected and shared
                        during execution and decision-making. Zvolv mobile forms enables on-the go and contextual data
                        collection. Data validation, sanity checking, approvals and reviews can be built in to ensure
                        quality and timeliness.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-muted">
                        <span class="text-primary">3.</span>
                        Make the application more intelligent
                    </h4>
                    <h3>Are there opportunities to automate data-crunching or advanced analytics? Is there a lot of past
                        data to leverage?
                    </h3>
                    <p class="para-desc text-muted">
                        Make your Zvolv apps intelligent and more impactful by configuring automation bots to tackle
                        complex, repetitive or error-prone tasks. Whether its training and executing ML models, AI to
                        analyze your documents, images, voice or text, running business specific rules or number
                        crunching and analytics to make predictions or assist in decision making, there’s always a Zvolv
                        bot for it.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h4 class="title text-muted">
                        <span class="text-primary">4.</span>
                        Integrate with other tools
                    </h4>
                    <h3>Does the process need to be orchestrated with other processes? Does data need to be exchanged
                        across tools?</h3>
                    <p class="para-desc text-muted">
                        Connect critical 3rd party systems like your ERP, CRM, POS and more to Zvolv with simple yet
                        powerful APIs. Push and pull documents and data as required. Data is now unified and structured,
                        making it more useful. Configure your dashboards and analytics via impactful charts, tables,
                        KPIs and powerful reports. Export data for deeper analytics. Zvolv can auto-generate reports,
                        dossiers and more.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h4 class="title text-muted">
                        <span class="text-primary">5.</span>
                        Iterate and evolve until your process automation goals are met
                    </h4>
                    <h3>What operational or process efficiency goal do you need to drive? How is user adoption and
                        change management progressing?
                    </h3>
                    <p class="para-desc text-muted">
                        With a single click, Zvolv translates your processes and automation into projects and tasks with
                        built in bots, forms, notifications, escalations and more. No worrying about databases, servers,
                        dev-ops, security, scalability - its all handled seem-lessly under the hood. Zvolv couples the
                        scale of well defined processes with the agility of quick changes. Try your Zvolv app in a
                        matter of days, iterate until the solution is optimized to your needs. Your apps can now evolve
                        as fast as your business.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer CTAs -->
<section>
    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="media align-items-center shadow rounded p-4 features">
                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                        <i class="uil uil-rocket"></i>
                    </div>
                    <div class="content ml-4">
                        <h5 class="mb-1"><a (click)="gotoZvolvAppSignup()" class="text-dark">Get Started !</a></h5>
                        <p class="text-muted mb-0">Let’s get started with building your first application with Zvolv.
                        </p>
                        <div class="mt-2">
                            <a (click)="gotoZvolvAppSignup()" class="btn btn-sm btn-soft-primary">Start My Free
                                Trial</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="media align-items-center shadow rounded p-4 features">
                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                        <i class="uil uil-envelope-check"></i>
                    </div>
                    <div class="content ml-4">
                        <h5 class="mb-1"><a (click)="openScheduleDemoModal()" class="text-dark">Get in Touch !</a></h5>
                        <p class="text-muted mb-0">Get in touch and we will assist/guide you in every step of the way.
                        </p>
                        <div class="mt-2">
                            <a (click)="openScheduleDemoModal()" class="btn btn-sm btn-soft-primary">Schedule a demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Footer CTAs end -->