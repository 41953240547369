import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnAppAWeekComponent } from './an-app-a-week/an-app-a-week.component';
import { HowToMapProcessComponent } from './how-to-map-process/how-to-map-process.component';


const routes: Routes = [
  {
    path: 'launch-an-app-a-week',
    component: AnAppAWeekComponent
  },
  {
    path: 'how-to-map-a-process-on-zvolv',
    component: HowToMapProcessComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SeoPagesRoutingModule { }
