<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h1 class="title"> 7-day action plan to launch a new enterprise application </h1>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section pt-0">
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered bootstrap4 html page.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-9 mt-4 pt-2">
                <div class="faq-content">
                    <div class="accordion" id="accordionExample">
                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseOne" class="faq position-relative"
                                aria-expanded="true" aria-controls="collapseOne">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingOne">
                                    <h5 class="title mb-0"> Day 1: Map out your process </h5>
                                </div>
                            </a>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Start building your application in a sandbox workspace</li>
                                        <li>Break down your process into individual activities across teams and roles
                                        </li>
                                        <li>Map out inter-dependencies between activities and completion criteria</li>
                                        <li>Configure standard durations for activities, and monitor end-end timelines
                                        </li>
                                        <li>Map out all approvals and decision logic</li>
                                        <li>Creat user roles and program escalations</li>
                                        <li>Get alignment across stakeholders on process flow</li>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseTwo" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseTwo">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingTwo">
                                    <h5 class="title mb-0"> Day 2: Configure your data flows </h5>
                                </div>
                            </a>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Configure task and initiation form fields and visualisation </li>
                                        <li>Create master data forms for interfaces across applications or tools</li>
                                        <li>Add data validations for form fields</li>
                                        <li>Configure data flow across activities and masters</li>
                                        <li>Setup form field level controls and permissions</li>
                                        <li>You are now ready to save and deploy your application</li>
                                        <li>Do a sample role-play across stake-holders</li>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseThree" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseThree">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                    <h5 class="title mb-0"> Day 3: Setup dashboards, reports, communications </h5>
                                </div>
                            </a>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingfive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Create charts, tables, pivots</li>
                                        <li>Configure drill-down and conditional formatting</li>
                                        <li>Configure emails and notifications, with customised templates</li>
                                        <li>Create sample projects with representative data</li>
                                        <li>Validate dashboards and communications with stakeholders</li>
                                        <li>Execute a few role-plays with the team and visualise real-time dashboard
                                            updates</li>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseFour" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseFour">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                    <h5 class="title mb-0"> Day 4: Configure data-movement and logic based automation
                                        bots </h5>
                                </div>
                            </a>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingfive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Identify requirements for customised business logic in process flow</li>
                                        <li>Identify areas where mathematical or analytical operations to be inserted in
                                            data flow</li>
                                        <li>Identify customised communication needs and templatized report generation
                                        </li>
                                        <li>Configure automation bots with appropriate triggers for above items</li>
                                        <li>Modify forms and dashboards to accommodate bot results or intermediate data
                                        </li>
                                        <li>Save and deploy the application again</li>
                                        <li>Create sample projects and test out all the automation bots</li>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseFive" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseFive">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                    <h5 class="title mb-0"> Day 5: Configure role based access to web and mobile
                                        application screens </h5>
                                </div>
                            </a>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingfive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Configure view, edit and create permissions for different components</li>
                                        <li>Define mobile screens for different roles</li>
                                        <li>Login to web and mobile applications via different roles and validate access
                                            permissions</li>
                                        <li>Navigate through task and project screens and configure data visibility and
                                            labels as required</li>
                                        <li>Setup project and task labels and filter options</li>
                                        <li>Add cross navigation links to dashboards and action buttons as required</li>
                                        <li>You are ready for extensive UAT now with users from across teams</li>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseSix" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseSix">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                    <h5 class="title mb-0"> Day 6: Continue UATs and modify application based on
                                        feedback collected </h5>
                                </div>
                            </a>
                            <div id="collapseSix" class="collapse" aria-labelledby="headingfive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Edit dashboards, task forms and dependencies/timelines as necessary</li>
                                        <li>Fix and access permissions issues and role-mapping</li>
                                        <li>Modify automation bots as required for any changes to business logic or
                                            algorithms</li>
                                        <li>Re-deploy application, iterate with stake-holders until accepted
                                            functionality</li>
                                        <li>Define admin team and provide appropriate privileges</li>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseSeven" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseSeven">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                    <h5 class="title mb-0"> Day 7: Move application to production workspace and go live
                                    </h5>
                                </div>
                            </a>
                            <div id="collapseSeven" class="collapse" aria-labelledby="headingfive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Export application to production workspace</li>
                                        <li>Deploy, create a sample project, execute end to end to ensure accuracy</li>
                                        <li>Delete all test data</li>
                                        <li>Upload and required reference or legacy data to appropriate masters</li>
                                        <li>Invite all users with appropriate roles</li>
                                        <li>Your application is now ready to use</li>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseBeyond" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseBeyond">
                                <div class="card-header border-0 shadow bg-light p-3" id="headingfive">
                                    <h5 class="title mb-0"> Beyond: Continue making your applications more intelligent
                                        and evolve with process changes
                                    </h5>
                                </div>
                            </a>
                            <div id="collapseBeyond" class="collapse" aria-labelledby="headingfive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text-muted mb-0 faq-ans">
                                        <li>Identify additional areas of automation - replace or augment human tasks
                                            with BOTs</li>
                                        <li>Leverage data collected in the system to build advanced automation around
                                            predictions and analytics</li>
                                        <li>Add machine learning capability to applications</li>
                                        <li>Integrate with AI APIs to assist in execution and decision making where
                                            relevant</li>
                                        <li>Integrate with other tools like ERP/CRM/HRMS systems to minimise or
                                            eliminate manual data transfers and/or process orchestration</li>
                                        <li>Build additional inter-linked applications to fill in any gaps to processes
                                            across tools</li>
                                        <li>Modify process or data flows and associated automation as processes change
                                            to keep applications up to date</li>
                                        <li>Analyse usage and data trends and drive further evolution</li>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Footer CTAs -->
<section>
    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="media align-items-center shadow rounded p-4 features">
                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                        <i class="uil uil-rocket"></i>
                    </div>
                    <div class="content ml-4">
                        <h5 class="mb-1"><a (click)="gotoZvolvAppSignup()" class="text-dark">Get Started !</a></h5>
                        <p class="text-muted mb-0">Let’s get started with building your first application with Zvolv.
                        </p>
                        <div class="mt-2">
                            <a (click)="gotoZvolvAppSignup()" class="btn btn-sm btn-soft-primary">Start My Free
                                Trial</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="media align-items-center shadow rounded p-4 features">
                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                        <i class="uil uil-envelope-check"></i>
                    </div>
                    <div class="content ml-4">
                        <h5 class="mb-1"><a (click)="openScheduleDemoModal()" class="text-dark">Get in Touch !</a></h5>
                        <p class="text-muted mb-0">Get in touch and we will assist/guide you in every step of the way.
                        </p>
                        <div class="mt-2">
                            <a (click)="openScheduleDemoModal()" class="btn btn-sm btn-soft-primary">Schedule a demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Footer CTAs end -->