import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit, AfterViewInit {

  public jobsData: any = []
  public filterCategory: string = null
  public filteredJobsData: any = null
  selectedCategory = null
  selectedType = null
  selectedDate = null
  selectedExperience = null

  @ViewChild('captchaRefJL') public captchaRefJL: RecaptchaComponent;
  public captchaResponse: string = '';

  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .subscribe((params) => {
        if (params.category) {
          this.filterCategory = params.category
        }
      })
  }

  search(val: any) {
    if (!val) this.filteredJobsData = this.jobsData;

    var lstr = val.toLowerCase()
    this.filteredJobsData = this.jobsData.filter(item => {
      return item.title.toLowerCase().includes(lstr)
    });
  }

  filterBy() {
    this.filteredJobsData = this.jobsData.filter(item => {
      return (this.selectedCategory ? item.category.includes(this.selectedCategory) : true) &&
        (this.selectedType ? item.type === this.selectedType : true) &&
        (this.selectedExperience ? item.experience === this.selectedExperience : true) &&
        (this.selectedDate ? item.date_posted === this.selectedDate : true)
    })
  }

  filterByCategory(newcat) {
    this.selectedCategory = newcat
    this.filterBy()
  }

  filterByType(newtype) {
    this.selectedType = newtype
    this.filterBy()
  }

  filterByDate(newdate) {
    console.log(newdate)
    this.selectedDate = newdate
    this.filterBy()
  }

  filterByExperience(newexp) {
    this.selectedExperience = newexp
    this.filterBy()
  }

  resetFilters() {
    this.selectedCategory = null
    this.selectedType = null
    this.selectedDate = null
    this.selectedExperience = null
    this.filterBy()
  }

  ngAfterViewInit() {
    this.onGetJobsListFromServer()
  }

  onGetJobsListFromServer() {
    // Invoke captcha. This will invoke submit form API on resolving captcha key.
    this.captchaRefJL.execute()
  }

  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse
    this.getJobsListFromServer(this.captchaResponse)
  }

  getJobsListFromServer(captcha) {
    // 1. Get reCaptcha key (via recaptcha lib)
    // 2. Get QR Code
    var getQRCodeUrl = 'https://app.zvolv.com/rest/v17/decode/qr/34F5GP6YL35CN'
    var reqheaders = {
      businessDomain: 'sales'
    }
    var qrapidata = { "g-recaptcha-response": captcha }
    this.httpService.postConfig(getQRCodeUrl, JSON.stringify(qrapidata), reqheaders)
      .subscribe(
        (data) => {

          if (data && !data['error']) {
            var loginToken = data['data']['UserDetails']['loginToken']

            // 3. Get form submissions of 556390 - FormID job list master
            var getFormSubmissionsUrl = 'https://app.zvolv.com/rest/v17/lite/submissions?pagination={"limit":150,"offset":0}&filter=[{"operator":"=","value":556390,"column":"FormID"}]'
            var reqheaders = {
              'Content-Type': 'application/json',
              jwt: loginToken,
              LoginToken: loginToken,
              businessDomain: 'sales'
            }

            this.httpService.get(getFormSubmissionsUrl, reqheaders)
              .subscribe(
                (response) => {
                  if (!response['error']) {
                    var tempResponse = response['data']['elements']
                    for (var t = tempResponse.length - 1; t >= 0; t--) {
                      var status = (tempResponse[t].inputs.filter(s => s.label.includes('Status'))[0].value)
                      if (status != 'Closed') {
                        var tempJobObj = {
                          'id': tempResponse[t].formSubmissionId,
                          'title': (tempResponse[t].inputs.filter(s => s.label.includes('Title'))[0].value),
                          'location': (tempResponse[t].inputs.filter(s => s.label.includes('Location'))[0].value),
                          'category': (tempResponse[t].inputs.filter(s => s.label.includes('Category'))[0].value),
                          'type': (tempResponse[t].inputs.filter(s => s.label.includes('Type'))[0].value),
                          'experience': (tempResponse[t].inputs.filter(s => s.label.includes('Experience'))[0].value),
                          'date_posted': this.getFormattedDate(tempResponse[t].dateCreated)
                        }
                        this.jobsData.push(tempJobObj)
                      }
                    }

                    this.filterByCategory(this.filterCategory)
                  }
                },
                error => {
                  console.log("Error in fetching careers", error)
                }
              );
          }
        },
        error => {
          console.log("Error in fetching careers", error)
        }
      );
  }

  dateFormatOptions: any = { year: 'numeric', month: 'long', day: 'numeric' };
  getFormattedDate(dateStr) {
    var d = new Date(dateStr);
    return d.toLocaleDateString("en-US", this.dateFormatOptions)
  }
}
