import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(
    eventName: string,
    sendTo: string
  ) {
    gtag('event', eventName, {
      send_to: sendTo
    })
  }

}
