<!-- Hero Start -->
<section class="d-table w-100 h-100 overflow-hidden bg-dark pb-0" id="home">
    <div class="container-hero position-relative">
        <div class="row align-items-center pt-5">
            <div class="col-lg-6 col-md-6">
                <div class="title-heading">
                    <h1 class="heading mb-3">The future of Automation.</h1>
                    <h1 class="heading mb-3">PaaS Accelerating Enterprise Digital Transformation</h1>
                    <p class="para-desc text-muted">
                        End-end Hyper Automation requires a combination of BPM, RPA, AI/ML, Analytics, Integrations.
                    </p>
                    <p class="para-desc text-muted">
                        <span class="text-primary">Zvolv</span> brings the Power of Immediate Solutions into the hands of people moving your business forward!
                    </p>
                    <p class="para-desc text-muted">
                        <span class="text-primary">Zvolv</span> enables business process experts to collaborate closely with IT to build smart applications launched at the speed of thought.
                    </p>
                    <div class="mt-4 pt-2">
                        <a (click)="openScheduleDemoModal()" class="btn btn-primary">Schedule a demo</a><br>
                        <a (click)="gotoZvolvAppSignup()" class="text-light mb-2 ml-2 mt-3 mr-2 ultr"><b>Try
                                For Free <i class="mdi mdi-arrow-right"></i></b></a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="classic-saas-image position-relative bg-half-170">
                    <div class="bg-saas-shape position-relative">
                        <img src="assets/images/landing/flow.png" class="img-fluid mover mx-auto d-block"
                            alt="Zvolv - No-code Process Automation">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Section Start -->
<section class="section overflow-hidden bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2 pt-4">
                    <h2 class="title mb-4">Build Integrated, Intelligent Applications</h2>
                </div>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-md-4 col-12">
                <div class="features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/workflow.svg" class="avatar avatar-medium" alt="">
                    </div>

                    <div class="content mt-5">
                        <h4 class="title-2">No-code Process Builder</h4>
                        <p class="text-gray mb-0">Process champions can now capture, innovate and evolve processes in hours.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                <div class="features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/no-code.svg" class="avatar avatar-medium" alt="">
                    </div>

                    <div class="content mt-5">
                        <h4 class="title-2">Low-code Intelligent Bot Builder</h4>
                        <p class="text-gray mb-0">Data experts can build in predictions, analytics, and AI/ML intelligence in days.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5 mt-sm-0">
                <div class="features text-center">
                    <div class="image position-relative d-inline-block">
                        <img src="assets/images/icon/cloud.svg" class="avatar avatar-medium" alt="">
                    </div>

                    <div class="content mt-5">
                        <h4 class="title-2">Integration Hub, Smart APIs</h4>
                        <p class="text-gray mb-0">Application owners can orchestrate processes and integrate data across tools.</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h2 class="title mb-4">The Power of Unified Platform</h2>
                    <p class="text-gray para-desc mb-0 mx-auto">A Single Unified to Build Complex Integrated End-End Solutions</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center">

            <div class="col-md-7 col-12 mt-4 pt-2">
                <div class="tab-content mr-lg-4 saas-feature-shape-left position-relative" id="pills-tabContent">
                    <div class="tab-pane fade" id="collaboration" role="tabpanel" aria-labelledby="collab">
                        <img src="assets/images/product-screens/mobile.png" class="img-fluid mx-auto d-block" alt="">
                    </div>
                    <!--end teb pane-->

                    <div class="tab-pane fade show active" id="process" role="tabpanel" aria-labelledby="proc">
                        <img src="assets/images/product-screens/p0.png" class="img-fluid mx-auto d-block" alt="">
                    </div>
                    <!--end teb pane-->

                    <div class="tab-pane fade" id="insights" role="tabpanel" aria-labelledby="in-sights">
                        <img src="assets/images/product-screens/reports.png" class="img-fluid mx-auto d-block" alt="">
                    </div>
                    <!--end teb pane-->

                </div>
                <!--end tab content-->
            </div>

            <div class="col-md-5 mt-4 pt-2">
                <ul class="nav nav-pills bg-white nav-justified flex-column mb-0" id="pills-tab" role="tablist">
                    <li class="nav-item bg-light rounded-md">
                        <a class="nav-link rounded-md active" id="proc" data-toggle="pill" href="#process" role="tab"
                            aria-controls="process" aria-selected="false">
                            <div class="p-3 text-left">
                                <h5 class="title">Improve Efficiency</h5>
                                <ul class="list-unstyled">
                                    <li><i class="mdi mdi-chevron-right"></i>Reactive Workflows and Approvals</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Project and Document Management</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Data Integrations</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Intelligent Automation Bots</li>
                                </ul>
                            </div>
                        </a>
                        <!--end nav link-->
                    </li>
                    <!--end nav item-->

                    <li class="nav-item bg-light rounded-md mt-4">
                        <a class="nav-link rounded-md" id="collab" data-toggle="pill" href="#collaboration" role="tab"
                            aria-controls="collaboration" aria-selected="false">
                            <div class="p-3 text-left">
                                <h5 class="title">Drive Collection and Engagement</h5>
                                <ul class="list-unstyled">
                                    <li><i class="mdi mdi-chevron-right"></i>Task Management</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Real-time Communications</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Interactive Forms</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Mobile First Applications</li>
                                </ul>
                            </div>
                        </a>
                        <!--end nav link-->
                    </li>
                    <!--end nav item-->

                    <li class="nav-item bg-light rounded-md mt-4">
                        <a class="nav-link rounded-md" id="in-sights" data-toggle="pill" href="#insights" role="tab"
                            aria-controls="insights" aria-selected="false">
                            <div class="p-3 text-left">
                                <h5 class="title">Make Data Actionable and Derive Insights</h5>
                                <ul class="list-unstyled">
                                    <li><i class="mdi mdi-chevron-right"></i>Drill-down Reporting</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Prescriptive and Predictive Analytics
                                    </li>
                                    <li><i class="mdi mdi-chevron-right"></i>AI/ML Assisted Decision Making</li>
                                    <li><i class="mdi mdi-chevron-right"></i>Planning and Optimization</li>
                                </ul>
                            </div>
                        </a>
                        <!--end nav link-->
                    </li>
                    <!--end nav item-->
                </ul>
                <!--end nav pills-->
                <div class="mt-4 text-center">
                    <a [routerLink]="['/features']" class="btn btn-outline-primary">Features <i
                            class="mdi mdi-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Integrations section Start -->
<section class="section bg-light pt-2 pb-2">
    <div class="container-fluid">
        <div class="call-to-action call-to-action__pricing position-relative" style="height: 80vh;">
            <img src="assets/images/integrations/oracle.svg" alt=""
                class="slide-icon-decor slide-icon-decor--1 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/docusign.svg" alt=""
                class="slide-icon-decor slide-icon-decor--2 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/google-drive.svg" alt=""
                class="slide-icon-decor slide-icon-decor--3 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/zapier.svg" alt=""
                class="slide-icon-decor slide-icon-decor--4 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/sap.svg" alt=""
                class="slide-icon-decor slide-icon-decor--5 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/tableau.svg" alt=""
                class="slide-icon-decor slide-icon-decor--6 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/twilio.svg" alt=""
                class="slide-icon-decor slide-icon-decor--7 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/slack.svg" alt=""
                class="slide-icon-decor slide-icon-decor--8 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/visual-studio.svg" alt=""
                class="slide-icon-decor slide-icon-decor--9 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/dropbox.svg" alt=""
                class="slide-icon-decor slide-icon-decor--10 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/google-analytics.svg" alt=""
                class="slide-icon-decor slide-icon-decor--11 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/sheets.svg" alt=""
                class="slide-icon-decor slide-icon-decor--12 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/razorpay.svg" alt=""
                class="slide-icon-decor slide-icon-decor--13 position-absolute card p-2 shadow" height="48px">
            <img src="assets/images/integrations/salesforce.svg" alt=""
                class="slide-icon-decor slide-icon-decor--14 position-absolute card p-2 shadow" height="48px">

            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-4">
                        <div class="section-title text-center mb-4 pb-2 mt-100 mt-60" style="margin-top: 25vh;">
                            <h1 class="title mb-4">Build Integrated, Enterprise Applications with 
                                <span class="text-primary">Zvolv</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Clients start -->
    <section class="section pt-0 pb-0">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title">
                        <h4 class="mb-4">Many out-of-box built-in integrations, flexibility to build your own</h4>
                        <p class="text-gray para-desc mx-auto mb-0">
                            <span class="text-primary">Zvolv</span> provides off-shelf integrations with all the popular ERP, CRM, collaboration, and analytics tools. And with the low-code intelligent automation bot framework, you can build your custom integrations with web services, REST APIs, secure file transfers, or database-level access. Connect your people, processes, tools, and data silos with Zvolv to create impactful end-end intelligent applications.
                        </p>

                        <div class="watch-video mt-4">
                            <a href="https://app.zvolv.com/signup/create" class="btn btn-primary mb-2 mr-2">Get Started
                                <i class="mdi mdi-chevron-right"></i></a>
                            <a (click)="openScheduleDemoModal()" class="btn btn-outline-primary mb-2 mr-2">Request a
                                demo <i class="mdi mdi-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
    </section>
    <!-- Clients end -->
</section>
<!-- Integrations section Start -->

<!-- Feature Start -->
<section class="section bg-dark">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-12">
                <div>
                    <div class="section-title mb-4 pb-2 pt-5">
                        <h1 class="title font-weight-bold mb-0">No-code Process + Low-code Bot Automation</h1>
                        <h1 class="title font-weight-bold mb-0">
                            <span class="typed-element-pfeat text-primary"
                                data-elements="Analyze, Plan, Streamline, Optimize"></span>
                        </h1>
                        <h1 class="title font-weight-bold mb-0">
                            with one unified platform
                        </h1>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-12">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div
                                    class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0">
                                    <span class="h1 icon2 text-primary">
                                        <i class="mdi mdi-chart-bar-stacked"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Process Excellence & Innovation</h5>
                                        <p class="para text-gray mb-0">Visual tools to map and drive your processes. Different interface formats Gantt charts. Timeline views. Critical Paths. Kanban boards. BPMN diagrams. Drill-down reports.</p>
                                        <!-- <h6 class="text-muted tag mb-0">Branding</h6> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-4 pt-2">
                                <div
                                    class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0">
                                    <span class="h1 icon2 text-primary">
                                        <i class="mdi mdi-chart-timeline"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Peak Operational Efficiency</h5>
                                        <p class="para text-gray mb-0">Track, optimize, and automate the repeatable. A rich library of automation bots, or build your own in a powerful low-code development framework.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div
                                    class="card features fea-primary rounded p-4 bg-dark-block position-relative overflow-hidden border-0">
                                    <span class="h1 icon2 text-primary">
                                        <i class="mdi mdi-speedometer"></i>
                                    </span>
                                    <div class="card-body p-0 content">
                                        <h5>Better, Faster and Automated Decisions</h5>
                                        <p class="para text-gray mb-0">Drive data-driven decisions with bot-assisted predictions, analytics, and reporting. Integrate various data sources with simple widgets and flexible APIs.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-4 pt-2 text-center text-md-left">
                                <a [routerLink]="['/solutions']" class="btn btn-primary">Explore Usecases <i
                                        class="mdi mdi-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Feature End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h2 class="title mb-4">The <span class="text-primary">Zvolv</span> Model</h2>
                    <p class="text-gray para-desc mb-0 mx-auto">Delivering maximum ROI at lowest possible TCO</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-brain"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Intelligence</h5>
                        <p class="para text-gray mb-0">Zvolv does all the heavy lifting for setting up, training
                            and scaling your AI/ML and analytics applications.</p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-circuit"></i>
                    </span>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-channel"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Integrations</h5>
                        <p class="para text-gray mb-0">Easy to use, robust APIs and off-shelf interfaces to
                            multitude of industry standard tools - or build your own.</p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-channel"></i>
                    </span>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-sliders-v-alt"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Flexibility</h5>
                        <p class="para text-gray mb-0">Build end-end applications personalized to your processes
                            with wide range of customizations and dev tools.</p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-sliders-v-alt"></i>
                    </span>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-lock-alt"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Privacy</h5>
                        <p class="para text-gray mb-0">Your data on your servers, you own complete control.
                            Private-cloud, on-premise, multi-tenant hosting options.</p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-lock-alt"></i>
                    </span>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="mdi mdi-security"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Security</h5>
                        <p class="para text-gray mb-0">State-of-the-art security with encryption, MFA, mobile
                            virtualization, IMEI locks, GEO tagging and more.</p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-security"></i>
                    </span>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-4 mt-4 pt-2">
                <div
                    class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                    <span class="h1 icon2 text-primary">
                        <i class="uil uil-data-sharing"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Scalability</h5>
                        <p class="para text-gray mb-0">Unlimited users, unlimited data. Annual license fees based
                            on building blocks used to implement your processes.</p>
                    </div>
                    <span class="big-icon text-center">
                        <i class="uil uil-data-sharing"></i>
                    </span>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<!-- FAQ n Contact Start -->
<section class="section bg-animation-polygon polygon-gradient-2">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="media">
                    <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
                    <div class="media-body">
                        <h5 class="mt-0">Why do IT teams love <span class="text-primary">Zvolv</span>?</h5>
                        <p class="answer text-gray mb-0">
                            Zvolv brings in unrivaled agility in application building, customization, and maintenance,
                            offering a total cost of ownership that is unachievable with traditional solutions. All
                            this, while keeping IT fully in control of how the solutions are deployed, managed and
                            scaled. Zvolv does all the heavy lifting for setting up, training and scaling your
                            intelligent applications. Your data stays on your servers, you own complete control.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="media">
                    <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
                    <div class="media-body">
                        <h5 class="mt-0">Where are <span class="text-primary">Zvolv</span> apps hosted and how secure is
                            Zvolv?</h5>
                        <p class="answer text-gray mb-0">
                            Zvolv applications can be hosted on your private cloud servers or on-premise, with
                            state-of-the-art security features relevant to your applications - from encryption, MFA,
                            mobile virtualization, IP and IMEI locks, GEO tagging and more. With stringent vulnerability
                            assessment and penetration testing methodologies, and ground-up security features woven into
                            the architecture, The platform has enabled leading enterprises around the world to build
                            mission critical applications.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="media">
                    <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
                    <div class="media-body">
                        <h5 class="mt-0">How does <span class="text-primary">Zvolv</span> integrate with existing tools
                            and data sources?</h5>
                        <p class="answer text-gray mb-0">
                            Zvolv offers easy to use, robust APIs and off-shelf interfaces to multitude of industry
                            standard tools - or the ability to build your own with low-code developer tools. Zvolv
                            supports integrations via REST APIs, web services, secure file transfers and database level
                            integrations with a multitude of SQL and NoSQL data sources. Zvolv offers a granular access
                            to every task, project, form, report, document and automation bot.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="media">
                    <i class="mdi mdi-help-circle text-primary mr-2 mt-1"></i>
                    <div class="media-body">
                        <h5 class="mt-0">What developer tools are available to build on the <span
                                class="text-primary">Zvolv</span> platform?
                        </h5>
                        <p class="answer text-gray mb-0">
                            Build end-end applications personalized to your processes with wide range of customizations
                            and developer tools built into the Zvolv platform. The no-code process builder allows visual
                            coding of workflows, forms, dependencies, timelines and business logic. The low-code Python
                            IDE with access to pre-built bots, integrations and analytics functions allows granular
                            customization with ability to introduce AI, ML and NLP functionalities into your
                            applications.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Savings calculator Start -->
    <app-savings-calculator></app-savings-calculator>
    <!-- Savings calculator End -->

</section>
<!--end section-->
<!-- FAQ n Contact End -->

<!-- Shape Start -->
<div class="position-relative" style="z-index: -2;">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 75L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->