import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-download-manager-modal',
  templateUrl: './download-manager-modal.component.html',
  styleUrls: ['./download-manager-modal.component.scss']
})
export class DownloadManagerModalComponent implements OnInit {

  @Input()
  title: any = null

  @Input()
  description: any = null

  @Input()
  cslink: any = null

  @Input()
  csimagelink: any = null

  @Input()
  cstype: any = null

  @Input()
  saveToDisk = false

  @ViewChild('captchaRefDC') public captchaRefDC: RecaptchaComponent;

  downloadForm: FormGroup;
  submitted = false;
  showLoader = false;
  showSuccess = false
  showError = false
  errorMsg = null

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private httpService: HttpService) { }

  ngOnInit() {
    this.downloadForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subscribe: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.downloadForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.downloadForm.invalid) {
      return;
    }

    // Invoke captcha. This will invoke submit form API on resolving captcha key.
    this.captchaRefDC.execute()
  }

  onReset() {
    this.downloadForm.reset();
    this.submitted = false;
  }

  public captchaResponse: string = '';
  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse
    this.submitForm(this.captchaResponse)
  }

  resetVar() {
    this.showSuccess = false
    this.showError = false
    this.errorMsg = null
  }

  submitForm(captcha) {

    this.resetVar()
    this.showLoader = true

    // 1. Get reCaptcha key (via recaptcha lib)
    // 2. Get QR Code
    var getQRCodeUrl = 'https://app.zvolv.com/rest/v17/decode/qr/34F5GP6YL35CN'
    var reqheaders = {
      businessDomain: 'sales'
    }
    var qrapidata = { "g-recaptcha-response": captcha }
    this.httpService.postConfig(getQRCodeUrl, JSON.stringify(qrapidata), reqheaders)
      .subscribe(
        (data) => {

          this.showLoader = false

          if (data && !data['error']) {
            var loginToken = data['data']['UserDetails']['loginToken']

            // 3. Submit form
            var submitFormUrl = 'https://app.zvolv.com/rest/v17/5WGCU2Z62RZV3/forms/541228/submissions/'
            var reqheaders = {
              'Content-Type': 'application/json',
              jwt: loginToken,
              LoginToken: loginToken,
              businessDomain: 'sales'
            }
            var submitformapidata = {
              "FormData": {
                "type": "KEY_LABELS",
                "data": {
                  "Name": this.downloadForm.value.name,
                  "Email": this.downloadForm.value.email,
                  "Case Study Type": this.cstype,
                  "Case Study Link": this.cslink,
                  "Case Study Image Link": this.csimagelink,
                  "Subscribe to newsletter": this.downloadForm.value.subscribe
                }
              }
            }
            this.httpService.postConfig(submitFormUrl, JSON.stringify(submitformapidata), reqheaders)
              .subscribe(
                (data) => {

                  this.showLoader = false

                  if (data && data['error']) {
                    this.showError = true
                    this.errorMsg = data['message']
                  }
                  else {
                    this.showSuccess = true

                    if (this.saveToDisk) {
                      // Open file link
                      const a: any = document.createElement('a');
                      a.style = 'display: none';
                      document.body.appendChild(a);
                      a.href = this.cslink;
                      // if (this.cstype) {
                      //   a.download = this.cstype + ' - Zvolv Case Study.pdf';
                      // }
                      a.target = "_blank"
                      a.click();
                      this.dismiss()
                    }
                  }
                },
                error => {
                  this.showLoader = false
                  this.showError = true
                  this.errorMsg = error
                }
              );
          }
        },
        error => {
          this.showLoader = false
          this.showError = true
          this.errorMsg = error
        }
      );
  }

  dismiss() {
    this.activeModal.dismiss()
  }
}
