<!-- Hero Start -->
<section class="bg-half bg-dark d-table w-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> FAQs </h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start FAQ Section -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-lg-9 col-md-7 col-12">
                <div class="section-title" id="general">
                    <h4>General Questions</h4>
                </div>
                <div class="faq-content mt-4 pt-2">
                    <div class="accordion" id="accordionGeneral">
                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseone" class="faq position-relative"
                                aria-expanded="true" aria-controls="collapseone">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingfifone">
                                    <h6 class="title mb-0">How do business process owners benefit from Zvolv?</h6>
                                </div>
                            </a>
                            <div id="collapseone" class="collapse show" aria-labelledby="headingfifone"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Zvolv helps CXOs modernize their front lines and
                                        back offices by introducing AI and Automation into every business process that
                                        they manage and control. Workflow Automation to reduce errors and improve
                                        accuracy. Streamlined processes and integration across tools to drive
                                        synchronized and time bound efforts between teams. Intelligent automation bots
                                        to eliminate manual tasks and improve decision making.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsetwo" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsetwo">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingtwo">
                                    <h6 class="title mb-0">Why do IT teams love Zvolv?</h6>
                                </div>
                            </a>
                            <div id="collapsetwo" class="collapse" aria-labelledby="headingtwo"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Zvolv brings in unrivaled agility in application
                                        building, customization, and maintenance, offering a total cost of ownership
                                        that is unachievable with traditional solutions. All this, while keeping IT
                                        fully in control of how the solutions are deployed, managed and scaled. Zvolv
                                        does all the heavy lifting for setting up, training and scaling your intelligent
                                        applications. Your data stays on your servers, you own complete control.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsefour" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsefour">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingfour">
                                    <h6 class="title mb-0">How does the Zvolv pricing model work?</h6>
                                </div>
                            </a>
                            <div id="collapsefour" class="collapse" aria-labelledby="headingfour"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">We understand the pain of managing user licenses
                                        and exponentially growing costs when applications are widely launched both
                                        inside and outside your organizations. With Zvolv’s building block licensing
                                        model, you only pay for what you build, not how widely or often you use the
                                        tools. You can start with small processes and keep building on top as you build
                                        more confidence and drive user adoption. Check out our savings comparison tool.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsefive2" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsefive2">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingfive2">
                                    <h6 class="title mb-0">What customization flexibility exists with Zvolv?</h6>
                                </div>
                            </a>
                            <div id="collapsefive2" class="collapse" aria-labelledby="headingfive2"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">We understand that as organizations scale, the
                                        need for differentiation grows. Off-the-shelf solutions may seem attractive with
                                        deep domain specific features, but offer shallow customizations. Zvolv on the
                                        other hand offers you the ability to adapt your tools to your processes with a
                                        low-code Python environment to write your intelligent automation bots and custom
                                        integrations, at 10x the speed of traditional code development.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseten" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseten">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingten">
                                    <h6 class="title mb-0">How is Zvolv different from existing BPM and Workflow
                                        Management tools?</h6>
                                </div>
                            </a>
                            <div id="collapseten" class="collapse" aria-labelledby="headingten"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Zvolv is an integrated platform that combines
                                        no-code BPM and workflow capabilities with Intelligent bot-based automation,
                                        enabling you to automate end-to-end with applications that combine human and bot
                                        tasks. This helps address the last mile automation challenges that are often the
                                        most complex, need high adaptability and add the most value.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseeleven" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseeleven">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingeleven">
                                    <h6 class="title mb-0">What is the advantage of Zvolv's Intelligent Automation
                                        compared to traditional RPA tools?</h6>
                                </div>
                            </a>
                            <div id="collapseeleven" class="collapse" aria-labelledby="headingeleven"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Traditional RPA tools will help automate the
                                        repeatable and mundane data entry and file processing tasks, or legacy system
                                        integrations.
                                        Zvolv's Intelligent Automation bots help stretch your automation goals further
                                        beyond into the realms of data-driven planning, optimization, intelligent
                                        decision making and machine-learning driven prescriptive and predictive
                                        analytics.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsetwelve" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsetwelve">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingtwelve">
                                    <h6 class="title mb-0">Why is the Zvolv no-code platform better compared to
                                        traditional application development?</h6>
                                </div>
                            </a>
                            <div id="collapsetwelve" class="collapse" aria-labelledby="headingtwelve"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Unlike traditional development approaches which
                                        take armies of developers and months to launch, Zvolv applications are ready to
                                        add value now, with little to no dependence on developers or back-logged IT
                                        teams. Zvolv provides unrivaled agility in application customization, offering a
                                        total cost of ownership that is unachievable with traditional solutions, and at
                                        10x the speed.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsethirteen" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsethirteen">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingthirteen">
                                    <h6 class="title mb-0">How do I find out if Zvolv is the right fit for building my
                                        process automation solution?</h6>
                                </div>
                            </a>
                            <div id="collapsethirteen" class="collapse" aria-labelledby="headingthirteen"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Process innovation and automation are key to
                                        drive efficiency, agility and growth. Off-the-shelf tools will rarely meet your
                                        differentiated and evolving needs. And custom developed tools take ages to build
                                        and are obsolete faster than they can add value. Zvolv connects your people,
                                        processes and systems with intelligent automation, tailored to your needs. With
                                        Zvolv, you focus on the what, not the how. Let's start today.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded">
                            <a data-toggle="collapse" href="#collapsenine" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsenine">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingnine">
                                    <h6 class="title mb-0">How fast can applications be launched with Zvolv?</h6>
                                </div>
                            </a>
                            <div id="collapsenine" class="collapse" aria-labelledby="headingnine"
                                data-parent="#accordionGeneral">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Zvolv gives you the power to define your
                                        processes via tasks, dependencies, approvals, timelines, metrics used to measure
                                        each step, check lists and more. Add and enforce consistency in data, documents
                                        and decision-making. Typically, applications get configured and functionally
                                        deployed in under a week. Make your Zvolv apps intelligent and more impactful by
                                        configuring automation bots to tackle complex, data-driven, repetitive tasks, or
                                        with advanced AI/ML models. Effort may range from days to weeks depending on
                                        complexity.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-title mt-5" id="tech">
                    <h4>Platform Questions</h4>
                </div>

                <div class="faq-content mt-4 pt-3">
                    <div class="accordion" id="accordionPlatform">
                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsefive" class="faq position-relative"
                                aria-expanded="true" aria-controls="collapsefive">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingfive">
                                    <h6 class="title mb-0">Where are Zvolv apps hosted and how secure is Zvolv?</h6>
                                </div>
                            </a>
                            <div id="collapsefive" class="collapse show" aria-labelledby="headingfive"
                                data-parent="#accordionPlatform">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Zvolv applications can be hosted on your private
                                        cloud servers or on-premise, with state-of-the-art security features relevant to
                                        your applications - from encryption, MFA, mobile virtualization, IP and IMEI
                                        locks, GEO tagging and more. With stringent vulnerability assessment and
                                        penetration testing methodologies, and ground-up security features woven into
                                        the architecture, The platform has enabled leading enterprises around the world
                                        to build mission critical applications.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsesix" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsesix">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingsix">
                                    <h6 class="title mb-0">Can the Zvolv applications be white-labeled?</h6>
                                </div>
                            </a>
                            <div id="collapsesix" class="collapse" aria-labelledby="headingsix"
                                data-parent="#accordionPlatform">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Zvolv workspaces and Zapps are accessed via the
                                        web portal at your-workspace-name.zvolv.com, or via the Zvolv Android and iOS
                                        apps. Premium customers have the option to configure custom URLs for web
                                        portal access, as well as white-labeled applications for Android and iOS.
                                        Zvolv applications have many UI/UX configuration options to customize it to
                                        your branding scheme and desired usage internal and external to the
                                        organization.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseseven" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseseven">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingseven">
                                    <h6 class="title mb-0">What developer tools are available to build on the Zvolv
                                        platform?</h6>
                                </div>
                            </a>
                            <div id="collapseseven" class="collapse" aria-labelledby="headingseven"
                                data-parent="#accordionPlatform">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Build end-end applications personalized to your
                                        processes with wide range of customizations and developer tools built into the
                                        Zvolv platform. The no-code process builder allows visual coding of workflows,
                                        forms, dependencies, timelines and business logic. The low-code Python IDE with
                                        access to pre-built bots, integrations and analytics functions allows granular
                                        customization with ability to introduce AI, ML and NLP functionalities into your
                                        applications.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsethree" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsethree">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingthree">
                                    <h6 class="title mb-0">How does Zvolv integrate with existing tools and data
                                        sources?</h6>
                                </div>
                            </a>
                            <div id="collapsethree" class="collapse" aria-labelledby="headingthree"
                                data-parent="#accordionPlatform">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Zvolv offers easy to use, robust APIs and
                                        off-shelf interfaces to multitude of industry standard tools - or the ability to
                                        build your own with low-code developer tools. Zvolv supports integrations via
                                        REST APIs, web services, secure file transfers and database level integrations
                                        with a multitude of SQL and NoSQL data sources. Zvolv offers a granular access
                                        to every task, project, form, report, document and automation bot.</p>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapseeight" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapseeight">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingeight">
                                    <h6 class="title mb-0">Can Zvolv bots integrate third party AI/ML models and APIs?
                                    </h6>
                                </div>
                            </a>
                            <div id="collapseeight" class="collapse" aria-labelledby="headingeight"
                                data-parent="#accordionPlatform">
                                <div class="card-body px-2 py-4">
                                    <p class="text-gray mb-0 faq-ans">Make your Zvolv apps intelligent and more
                                        impactful by configuring automation bots with advanced predictive and
                                        prescriptive decision-support models leveraging all the data collected in the
                                        applications, as well as external sources. Zvolv does all the heavy lifting for
                                        setting up, training and scaling your AI/ML and analytics applications. The
                                        custom bot builder allows integrating any AI/ML model or third-party APIs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Have Question ? Get in touch!</h4>
                    <div class="mt-4 pt-2">
                        <a [routerLink]="['/contact-us']" class="btn btn-primary">Contact us <i
                                class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>
<!--end section-->
<!-- End FAQ Section -->