<!-- Testi Start -->
<section class="section bg-animation-polygon polygon-gradient-2 pb-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h2 class="title">Trusted by <span class="text-primary">Global</span> companies</h2>
                    <p>For rapid enterprise-wide transformational deployments</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-4 pt-2">
                <owl-carousel-o [options]="customOwlOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/swiggy.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <!-- <h6 class="text-muted mt-2">Global Top-5 Food Delivery Company</h6> -->
                                <p class="text-gray mt-2">" With the Zvolv platform we have been building a new app
                                    almost every other week - with very complex automations. There is not much we can’t
                                    do with Zvolv. "</p>
                                <h6 class="text-primary">Director of Process Excellence</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/lenskart.svg"
                                    class="img-fluid avatar avatar-md-sm mx-auto" alt="">
                                <!-- <h6 class="text-muted mt-2">Unicorn Eyewear Retailer</h6> -->
                                <p class="text-gray mt-2">" We haven’t left many stones unturned in our automation
                                    journey with Zvolv. Every process that can be integrated and automated, has been
                                    with this super capable platform. Loving it. "</p>
                                <h6 class="text-primary">Senior VP of Projects</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/titan.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <!-- <h6 class="text-muted mt-2">Global $10B+ Consumer Goods Company</h6> -->
                                <p class="text-gray mt-4">" Zvolv has helped manage projects in a new way - tracking
                                    progress without physical presence and resolving issues with ease. We are able to
                                    turnaround projects much faster with Zvolv! "</p>
                                <h6 class="text-primary">Head of Projects</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/future-group.svg"
                                    class="img-fluid avatar avatar-md-sm mx-auto" alt="">
                                <p class="text-gray mt-4">" Zvolv has brought us real-time visibility and structure to
                                    our new product development and innovation processes by tightly integrating stage
                                    gate with technology - helping us launch products faster and first time right. "</p>
                                <h6 class="text-primary">Head of IT Applications</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/kidzcare.svg"
                                    class="img-fluid avatar avatar-md-sm mx-auto" alt="">
                                <p class="text-gray mt-4">" We have been leveraging Zvolv to launch many apps for both
                                    internal as well as patient use. Integrated with our data systems, Zvolv apps have
                                    helped reduce significant manual effort in cross-center data collection,
                                    appointments, nurse triage and many other applications. A great team and platform to
                                    work with. "</p>
                                <h6 class="text-primary">COO</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/domino.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <p class="text-gray mt-4">" Zvolv has brought single screen visibility to our site
                                    selection and approval processes, With tightly enforced processes and more
                                    accountability, our turn around times have reduced by over 50%. "</p>
                                <h6 class="text-primary">VP of Business Development</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/jswone.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <p class="text-gray mt-4">" We partnered with Zvolv to build an end-end solution capable of supporting multiple facets of our operations, integrates well with other products and is flexible enough to support our evolving use cases.
                                    The Zvolv low-code product has allowed us to execute complex use cases in less time than it would have taken to build from ground up and has impressed us with out of the box capabilities. Most importantly the team is a pleasure to work with and has been accommodating with tight timelines and evolving requirements. "</p>
                                <h6 class="text-primary">Director, Projects</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/more.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <p class="text-gray mt-4">" We have used the Zvolv platform to build a workforce optimization
                                    solution that significantly reduced our store costs by removing slack through a planning and
                                    rostering utility. For a dynamic retail business like ours, enterprise applications need to be launched
                                    at scale and evolve rapidly. Zvolv's flexible BPM, RPA and AI/ML features and rapid deployment
                                    capabilities, combined with the platform's scalability and reliability makes it a potent
                                    combination to launch complex applications rapidly. "</p>
                                <h6 class="text-primary">Director of HR/Operations</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/MIAL.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <p class="text-gray mt-4">" Given the complex and dynamic nature of the Airport and airline processes, IT tools and support services need to evolve fast to keep up with changes in regulations, fluctuations in traffic and changing needs of partners.
                                    Zvolv's inherent ability to deliver automations at speed has enabled transformations in days that would have otherwise taken months to implement using traditional methods.
                                    We have addressed many complex problems with Zvolv, leading to significant operational savings and more enabling more efficient processes. "</p>
                                <h6 class="text-primary">Sr Manager, IT operations</h6>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>

    <!-- Partners start -->
    <section class="py-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/swiggy.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/abfrl.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/lenskart.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/titan.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/accenture.svg" class="avatar avatar-md-md" alt="">
                </div>

                <!-- <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                <img src="assets/images/client/domino.svg" class="avatar avatar-md-md" alt="">
            </div> -->

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/future-group.svg" class="avatar avatar-md-md" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- Partners End -->
</section>
<!-- Testi End -->