import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  public partnersData: any = []
  public filteredData: any = []

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.httpClient.get("assets/resources/partners.json").subscribe(data => {
      this.partnersData = data
      this.filteredData = data
    })
  }

  search(val: any) {
    if (!val) this.filteredData = this.partnersData;

    var lstr = val.toLowerCase()
    this.filteredData = this.partnersData.filter(item => {
      return item.name.toLowerCase().includes(lstr)
    }
    );
  }

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth" });
  }

}
