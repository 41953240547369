import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';
import { ScheduleEnterpriseDemoModalComponent } from 'src/app/shared/schedule-enterprise-demo-modal/schedule-enterprise-demo-modal.component';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(private modalService: NgbModal, public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
  }

  gotoZvolvAppSignup(): void {
    
    // Send data to Google analytics
    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/jemoCMb0lfABEOb10t4B");

    window.open("https://app.zvolv.com/signup/create", "_blank");
  }
  
  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
  }

  openScheduleEntDemoModal() {
    const modalRef = this.modalService.open(ScheduleEnterpriseDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
  }

}
