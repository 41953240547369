<section class="section bg-dark">
    <div class="container mt-5">
        <div class="card text-center overflow-hidden rounded shadow border-0">
            <div class="card-body pt-5">
                <div class="row text-center justify-content-center">
                    <div class="col-lg-10 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="title-heading mb-3">
                            <h1 class="heading text-dark mb-2">
                                Want a taste of <span class="text-primary">Zvolv</span>?
                            </h1>
                            <p class="text-muted">
                                Watch this 7-minute demo video to learn how it all works.
                            </p>
                        </div>
                        <div id="zvolv-intro-video" style="border-radius: 5px;overflow: hidden;">
                            <video class="rounded video" controls preload="metadata" width="100%" height="auto"
                                poster="assets/images/intro_vid_thumb.png">
                                <source src="assets/videos/Zvolv-intro-short.mp4" type="video/mp4">
                                Your browser does not support playing video.
                            </video>
                        </div>
                        <div class="row text-center mt-4">
                            <div class="col-lg-8 col-md-6 col-12 mt-sm-0 pt-sm-0 my-auto">
                                <a (click)="openScheduleDemoModal()" class="text-primary ultr" style="font-size: large;">
                                    <b>Want more? Schedule a 30-min demo with us.</b>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 mt-sm-0 pt-sm-0 my-auto">
                                <a (click)="gotoZvolvAppSignup()" class="text-secondary ultr" style="font-size: large;">
                                    <b>Try for free <i class="mdi mdi-arrow-right"></i></b>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Resources START -->
<!-- <section class="section pt-0 bg-dark">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="card text-center overflow-hidden rounded shadow border-0">
                    <div class="card-body py-5">
                        <div class="icon">
                            <img src="assets/images/icon/cloud.svg" class="avatar avatar-small" alt="">
                        </div>
                        <h4 class="mt-3"><a href="javascript:void(0)" class="title text-dark"> Unlimited Access</a></h4>
                        <p class="text-muted">It is a long established fact that a reader will be of a page reader will
                            be of a page when looking at its layout. </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="mdi mdi-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card text-center overflow-hidden rounded shadow border-0">
                    <div class="card-body py-5">
                        <div class="icon">
                            <img src="assets/images/icon/workflow.svg" class="avatar avatar-small" alt="">
                        </div>
                        <h4 class="mt-3"><a href="javascript:void(0)" class="title text-dark"> Our Courses</a></h4>
                        <p class="text-muted">It is a long established fact that a reader will be of a page when reader
                            will be of a page looking at its layout. </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="mdi mdi-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card text-center overflow-hidden rounded shadow border-0">
                    <div class="card-body py-5">
                        <div class="icon">
                            <img src="assets/images/icon/acceleration.svg" class="avatar avatar-small" alt="">
                        </div>
                        <h4 class="mt-3"><a href="javascript:void(0)" class="title text-dark"> Expert Teachers</a></h4>
                        <p class="text-muted">It is a long established fact that a reader will be of a page when reader
                            will be of a page looking at its layout. </p>
                        <a href="javascript:void(0)" class="text-primary read-more">Read More <i
                                class="mdi mdi-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- Resources END -->

<!-- Testi Start -->
<section class="section bg-animation-polygon polygon-gradient-1 pb-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h2 class="title">Trusted by <span class="text-primary">Global</span> companies</h2>
                    <p>For rapid enterprise-wide transformational deployments</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-4 pt-2">
                <owl-carousel-o [options]="customOwlOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/swiggy.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <!-- <h6 class="text-muted mt-2">Global Top-5 Food Delivery Company</h6> -->
                                <p class="text-muted mt-2">" With the Zvolv platform we have been building a new app
                                    almost every other week - with very complex automations. There is not much we can’t
                                    do with Zvolv. "</p>
                                <h6 class="text-primary">Director of Process Excellence</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/lenskart.svg"
                                    class="img-fluid avatar avatar-md-sm mx-auto" alt="">
                                <!-- <h6 class="text-muted mt-2">Unicorn Eyewear Retailer</h6> -->
                                <p class="text-muted mt-2">" We haven’t left many stones unturned in our automation
                                    journey with Zvolv. Every process that can be integrated and automated, has been
                                    with this super capable platform. Loving it. "</p>
                                <h6 class="text-primary">Senior VP of Projects</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/titan.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <!-- <h6 class="text-muted mt-2">Global $10B+ Consumer Goods Company</h6> -->
                                <p class="text-muted mt-4">" Zvolv has helped manage projects in a new way - tracking
                                    progress without physical presence and resolving issues with ease. We are able to
                                    turnaround projects much faster with Zvolv! "</p>
                                <h6 class="text-primary">Head of Projects</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/future-group.svg"
                                    class="img-fluid avatar avatar-md-sm mx-auto" alt="">
                                <p class="text-muted mt-4">" Zvolv has brought us real-time visibility and structure to
                                    our new product development and innovation processes by tightly integrating stage
                                    gate with technology - helping us launch products faster and first time right. "</p>
                                <h6 class="text-primary">Head of IT Applications</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/kidzcare.svg"
                                    class="img-fluid avatar avatar-md-sm mx-auto" alt="">
                                <p class="text-muted mt-4">" We have been leveraging Zvolv to launch many apps for both
                                    internal as well as patient use. Integrated with our data systems, Zvolv apps have
                                    helped reduce significant manual effort in cross-center data collection,
                                    appointments, nurse triage and many other applications. A great team and platform to
                                    work with. "</p>
                                <h6 class="text-primary">COO</h6>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi text-center border rounded mx-2">
                            <div class="card-body">
                                <img src="assets/images/client/domino.svg" class="img-fluid avatar avatar-md-sm mx-auto"
                                    alt="">
                                <p class="text-muted mt-4">" Zvolv has brought single screen visibility to our site
                                    selection and approval processes, With tightly enforced processes and more
                                    accountability, our turn around times have reduced by over 50%. "</p>
                                <h6 class="text-primary">VP of Business Development</h6>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>

    <!-- Partners start -->
    <section class="py-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/swiggy.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/abfrl.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/lenskart.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/titan.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/accenture.svg" class="avatar avatar-md-md" alt="">
                </div>

                <div class="col-lg-2 col-md-2 col-6 text-center py-4">
                    <img src="assets/images/client/future-group.svg" class="avatar avatar-md-md" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- Partners End -->
</section>
<!-- Testi End -->

<!-- Schedule demo start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Unleash the power of <span class="text-primary">Zvolv</span> for your
                        business</h4>
                    <p class="text-muted para-desc mx-auto mb-0">We can help evolve your enterprise processes, fast.
                        Let’s start building for free.</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10">
                <div class="form-group">
                    <div class="input-group mb-3">
                        <input [(ngModel)]="workEmail" name="email" id="email" type="email" class="form-control"
                            placeholder="Work email :" required="" aria-describedby="sdbtn">
                        <div class="input-group-append">
                            <button class="btn btn-primary submitBnt" (click)="openScheduleDemoModal()"
                                id="sdbtn">Schedule a
                                demo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Schedule demo End -->

<!-- Shape Start -->
<div class="position-relative mt-5" style="z-index: -2;">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 75L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->