import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { DownloadManagerModalComponent } from 'src/app/shared/download-manager-modal/download-manager-modal.component';

declare var $: any;

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, AfterViewInit {

  constructor(private meta: Meta, private route: ActivatedRoute, private modalService: NgbModal, public googleAnalyticsService: GoogleAnalyticsService) {
    this.meta.addTag({ name: 'description', content: 'Who are the Zvolv Customers? Dynamic business owners looking for simplest yet impactful intelligent automation solutions to run their organisations.' });
    this.meta.addTag({ name: 'keywords', content: 'Customer Success Stories' });
  }

  ngOnInit(): void {
    this.initPortfolio()

    $('.venobox').venobox({
      framewidth: '1280px',
      frameheight: '720px',
      autoplay: true,
      spinner: 'double-bounce',
      spinColor: '#2bb673'
    });
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      // console.log("My hash fragment is here => ", fragment)

      setTimeout(function () {

        var applicationsArray = ['expansion', 'auditing', 'innovation', 'planning', 'execution', 'operations', 'projects', 'optimization']
        var industriesArray = ['finance', 'sales', 'scm', 'hr', 'retail', 'healthcare', 'hr', 'services', 'manufacturing', 'fab', 'support', 'fmcg']

        if (applicationsArray.includes(fragment)) {
          // By Application (Tabbed page)
          $('.nav-pills a[href="#' + fragment + '"]').tab('show');

          // $('div#' + 'by-applications')[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          var el = document.getElementById("by-applications");
          window.scroll({ top: el['offsetTop'], behavior: 'smooth' });
        }

        if (industriesArray.includes(fragment)) {
          // By Industry (Isotope filter)
          var $container = $('.projects-wrapper');
          var $catnav = $('.cat-' + fragment)
          // $catnav.click();
          var $filter = $('#filter');
          $filter.find('a').removeClass('active');
          $catnav.addClass('active');
          $container.isotope({
            filter: '.' + fragment,
            animationOptions: {
              animationDuration: 750,
              easing: 'linear',
              queue: false,
            }
          });

          // $('div#' + 'by-industries')[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          var el = document.getElementById("by-industries");
          console.log(el, el.offsetTop)
          window.scroll({ top: el['offsetTop'], behavior: 'smooth' });
        }
      }, 200);

    })
  }

  initPortfolio() {
    var $container = $('.projects-wrapper');
    var $filter = $('#filter');
    $container.isotope({
      filter: '*',
      layoutMode: 'masonry',
      animationOptions: {
        duration: 750,
        easing: 'linear'
      }
    });
    $filter.find('a').click(function () {
      var selector = $(this).attr('data-filter');
      $filter.find('a').removeClass('active');
      $(this).addClass('active');
      $container.isotope({
        filter: selector,
        animationOptions: {
          animationDuration: 750,
          easing: 'linear',
          queue: false,
        }
      });
      return false;
    });
  }

  gotoZvolvAppSignup(): void {

    // Send data to Google analytics
    this.googleAnalyticsService.eventEmitter("conversion", "AW-466926310/jemoCMb0lfABEOb10t4B");

    window.open("https://app.zvolv.com/signup/create", "_blank");
  }

  workEmail = null
  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
    modalRef.componentInstance.email = this.workEmail
  }

  openDownloadCaseStudyModal(cslink, csimagelink, cstype) {
    const modalRef = this.modalService.open(DownloadManagerModalComponent, { backdrop: 'static', size: 'lg', windowClass: 'dark-modal' })
    modalRef.componentInstance.cslink = cslink
    modalRef.componentInstance.csimagelink = csimagelink
    modalRef.componentInstance.cstype = cstype
  }
}
