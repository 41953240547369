import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { FullPagesModule } from './pages/full-pages.module';
import { SeoPagesModule } from './seo-pages/seo-pages.module';


const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    loadChildren: () => FullPagesModule
  },
  {
    path: 'resources',
    component: FullLayoutComponent,
    loadChildren: () => SeoPagesModule
  },
  {
    path: 'docs',
    // component: FullLayoutComponent,
    loadChildren: () => import('./docs/docs.module').then(m => m.DocsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
