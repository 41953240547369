<!-- Hero Start -->
<section class="bg-half bg-dark d-table w-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Privacy Policy </h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="feature-item">

                    <p class="text-gray">
                        BY ACCESSING OR USING THIS PLATFORM, YOU UNCONDITIONALLY ACCEPT AND AGREE WITHOUT LIMITATION
                        OR QUALIFICATION TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE
                        WITH THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY, PLEASE DO NOT PROCEED FURTHER TO USE
                        THIS PLATFORM. This privacy policy ("policy") governs the privacy terms of the Zvolv
                        Platform ("Zvolv"), which is operated by Zestl Software Private Limited ("Zestl"). The Zvolv
                        Platform ("Platform") is an integrated online platform consisting of the Zvolv Mobile
                        Applications ("Mobile App"), the www.zvolv.com website and its sub-domains ("Website") and
                        various cloud-based services upon which the platform is built and published. The terms "we",
                        "our" and "us" refers to Zestl and its affiliates, and the term "you", "your" or the "users"
                        refers to you, the user of the Platform. Please note that those capitalized terms not
                        defined herein, shall have the same meaning ascribed to them in the Terms of Uses applicable
                        to the Platform.
                    </p>
                    <h5>
                        General
                    </h5>
                    <p class="text-gray">
                        This policy covers how we treat your information (including personal information) that we
                        collect and receive, including information related to your visit or use (including past
                        visits or use) of our Platform. You should read this policy before using the Platform. By
                        using or accessing the Platform, or any part of it, you agree and acknowledge that you have
                        read and understood the policy and consent to the collection, use and disclosure of your
                        information as described in this policy.
                    </p>
                    <br>
                    <h5>
                        STATUTORY PROVISION
                    </h5>
                    <p class="text-gray">
                        This policy is governed by and is compliant with the Information Technology (Reasonable
                        Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011,
                        (which is designed to protect personal information of the users of a Platform); and other
                        applicable rules and regulations related to privacy, in India.
                    </p>
                    <br>
                    <h5>
                        INFORMATION THAT WE COLLECT
                    </h5>
                    <p class="text-gray">
                        When you use the Platform or register with us, you will provide us with information
                        including, without limitation, your demographic information, your personal information.
                        Personal Information shall include but not be limited to personally identifiable information
                        such as your name, gender, age, address, password, email address, phone numbers and postal
                        address, billing or credit card information, search preferences, etc. that you share with
                        us, and which is not otherwise publicly available ("Personal Information"). You understand
                        and acknowledge that you are mandatorily required to disclose certain information such as
                        your name, email address and password for using the Platform, while the disclosure of the
                        other information is voluntary in nature. Further, during your access and use of the
                        Platform, we may learn and collect additional information from and about you. In the event
                        you participate in any interactive features of the Platform, participate in a survey,
                        contest, promotion, activity or event, apply for a job, request customer support,
                        communicate with us via third party social media sites or otherwise communicate with us, we
                        collect the data you submit for such participation. When you use or access the Platform, we
                        may also collect and store additional information which may or may not personally identify
                        you or be identifiable with other information collected. Such information includes, without
                        limitation, your internet protocol address, browser types, domain name, type of operating
                        system and other statistical data involving your use of the Platform and/or our services.
                        This information is collected passively using various technologies or may be provided by you
                        through communications with us.
                    </p>
                    <br>
                    <h5>
                        SOCIAL MEDIA
                    </h5>
                    <p class="text-gray">
                        You may wish to participate in the events hosted by Zestl on social media platforms. The
                        main aim of the social media platform is to facilitate and allow you to share content and
                        participate in such events. However, we cannot be held responsible if you share Personal
                        Information on social media platform that is subsequently used, misused or otherwise
                        misappropriated by another user, or any third party.
                    </p>
                    <br>
                    <h5>
                        COOKIES
                    </h5>
                    <p class="text-gray">
                        When you use our Platform, we may store some information on your computer or other device
                        used to access or use the Platform. This information will be in the form of a "cookie" or
                        similar file. Cookies are small encrypted files, that a Platform or its service provider
                        transfer to your device’s hard drive through your web browser that enables the Platform or
                        service provider’s systems to recognize your browser and capture and remember certain
                        information. We use cookies to track your usage, trends on the usage, and to improve the
                        Platform and the services we provide and analyze and assess changes to, successes and
                        failures of the Platform. These cookies remember information about your usage of the
                        Platform and help you navigate the Platform. We do not use cookies to spy on you or
                        otherwise invade your privacy.
                    </p>
                    <br>
                    <h5>
                        OTHER MONITORING PROGRAMS
                    </h5>
                    <p class="text-gray">
                        For security purposes and to ensure the availability of the Platform, we may also use
                        software programs and tools to monitor network traffic, to identify unauthorized attempts to
                        upload or change information, or otherwise cause damage to the Platform.
                    </p>
                    <br>
                    <h5>
                        CUSTOMER SERVICE
                    </h5>
                    <p class="text-gray">
                        We may also collect Personal Information when you interact with our customer service in
                        order to assist you and to improve the operation of the Platform and the services we offer.
                    </p>
                    <br>
                    <h5>
                        INTERACTIONS WITH SERVICE PROVIDERS LISTED ON THE PLATFORM
                    </h5>
                    <p class="text-gray">
                        The Platform facilitates you to connect with various service providers listed on the
                        Platform ("Service Providers"). In order to avail the services offered by these Service
                        Providers, you may be required to provide certain information, details or documents
                        ("Customer Information") to the Service Provider through the Platform. Disclosure of the
                        Customer Information shall be strictly limited to the respective Service Provider, with whom
                        you undertake business or interact. Zestl or the Platform shall ensure adequate security for
                        such Customer Information and shall not share the same with any third party without
                        obtaining your prior written consent. However, Zestl or the Platform does not take any
                        responsibility of the Service Providers and does not guarantee that the Service Provider
                        shall accord same level of protection to the Customer Information. You understand and
                        acknowledge that the Customer Information shall also be subject to the privacy policy of the
                        respective Service Provider, as may be listed on the Service Provider’s application.
                    </p>
                    <br>
                    <h5>
                        HOW WE USE YOUR PERSONAL INFORMATION
                    </h5>
                    <p class="text-gray">
                        The Personal Information that we collect is used to contact you, to create and administer
                        your accounts, for research, and to improve the quality and your experience of the Platform,
                        to improve our services, manage our relationship with you and develop our present and future
                        products and services and provide information of these products and services to you. We will
                        not sell, share or disclose your Personal Information with any third party without your
                        consent, except as provided for in this policy. We reserve the right to send you certain
                        communications relating to the Platform, such as service announcements, administrative
                        messages and information regarding existing and new services. You could opt-out from
                        receiving these communications, by writing us an email on support@zestl.com or by
                        unsubscribing to these communications, as the case may be. You may also provide us with
                        access to information which is already publicly available, or becomes publicly available to
                        us in the future. Further, we may also receive information about you from third parties
                        independent of your use of the Platform. You understand and acknowledge that any such
                        information we may collect cannot be accorded the protections of this policy. This policy
                        does not apply to the practices of other entities that we do not own or control or to people
                        that we do not employ or manage. You understand and acknowledge that in order to provide
                        better Services, we may either directly or through a trusted third party identify the
                        patterns of usage of the Platform and/or the Services by the users, with the help of
                        analytical tools developed either by us or by any such third party service providers.
                    </p>
                    <br>
                    <h5>
                        STORAGE AND DESTRUCTION
                    </h5>
                    <p class="text-gray">
                        We shall store all Personal and Customer Information in accordance with standard market
                        practices relating to storage of information and this may involve storage in servers and
                        databases owned and maintained by us and/or our, agents or Service Providers. We may also
                        retain the Personal and Customer Information once the purpose for which it is collected has
                        been completed and the same may be retained and/or destroyed in accordance with standard
                        market practices applicable to similar businesses. You shall be solely responsible for the
                        Personal and Customer Information shared by you on or through the Platform or otherwise with
                        us, including without limitation for its truthfulness and accuracy. The Personal and
                        Customer Information shall be retained, as long as your user account is active. The Personal
                        and Customer Information shall be retained to comply with our legal obligations, resolve
                        disputes, and enforce our agreements. Please note, however, that there might be latency in
                        deleting Personal and Customer Information from our servers and backed-up versions might
                        exist after deletion.
                    </p>
                    <br>
                    <h5>
                        ACCESS TO THE PLATFORM
                    </h5>
                    <p class="text-gray">
                        Our Platform is not aimed at children under the age of 18 and we will not collect, use,
                        provide or process in any other form any Personal Information of children under the age of
                        18, deliberately. We therefore ask you, if you are under the age of 18, to not send us your
                        Personal Information and not to visit our Platform. If you are under the age of 18 and you
                        nevertheless wish to ask a question or use this Platform in anyway which requires you to
                        submit your Personal Information, please get your parent or guardian to do so on your
                        behalf.
                    </p>
                    <br>
                    <h5>
                        ACCESS AND PROTECTION OF YOUR PERSONAL INFORMATION INCLUDING CUSTOMER INFORMATION
                    </h5>
                    <p class="text-gray">
                        Your Personal and Customer Information that is provided to us or collected by us shall be
                        protected in accordance with standard market practices applicable to similar businesses. You
                        have a right to access, modify or correct any Personal and Customer Information provided to
                        us by the use of the Platform. We respect your Personal and Customer Information and
                        endeavor to protect it on our Platform against loss, misuse or alteration by taking industry
                        standards security measures. To protect your Personal and Customer Information, we have
                        implemented adequate technology. Your Personal and Customer Information is securely stored
                        at servers hosted by Amazon Web Services, we may move your Personal and Customer Information
                        to other servers operated by other service providers, we will update the same in this
                        policy. Although we provide appropriate firewalls and protections, we cannot warrant the
                        security of your Personal and Customer Information shared with us, as our systems are not
                        hack proof. You understand that the Platform and the services and the products, may not be
                        interruption free. We do not provide any guarantee whatsoever with respect to the continuous
                        access to the Platform and the products and services. Data pilferage due to unauthorized
                        hacking, virus attacks, technical issues is possible and we take no liabilities or
                        responsibilities for it. You are responsible for all actions of your user account and
                        password. Therefore, we do not recommend that you disclose your password to any third party.
                        If you choose to share your user account and password or any Personal and Customer
                        Information with third parties, you are solely responsible for the same. If you lose control
                        of your password, you may lose substantial control over your Personal and Customer
                        Information and may be subject to legally binding actions.
                    </p>
                    <br>
                    <h5>
                        IDEMNIFICATION
                    </h5>
                    <p class="text-gray">
                        You shall indemnify and hold us, our subsidiaries, affiliates, officers, agents or other
                        partners, directors and employees, harmless from and against any claims and demand,
                        including attorneys' fees, made by any third party due to or arising out of or relating to
                    </p>
                    <ul class="list-unstyled text-gray">
                        <li>(a) the Personal Information received by us and contents that you submit or share with
                            the Platform;</li>
                        <li>(b) your violation of the Policy; or</li>
                        <li>(c) your violation of rights of other users.</li>
                    </ul>
                    <p></p>
                    <br>
                    <h5>
                        DISCLOSURE OF PERSONAL INFORMATION
                    </h5>
                    <p class="text-gray">
                        We shall not rent, sell, share or disclose your Personal Information with non-affiliated
                        companies or persons, except as provided in this policy or the applicable Terms of Uses of
                        the Platform, or to provide you with the requested products and services on the Platform, or
                        when we have your consent, or under the following circumstances: It is clarified that the
                        following circumstances shall not be applicable to the Customer Information shared by you,
                        through the Platform. We do not share the Customer Information with any third party, other
                        than the respective Service Provider. We provide your Personal Information to our trusted
                        partners, such as contractors and Service Providers. These partners may use your Personal
                        Information to help us communicate with you about our Platform and our other products and
                        services or help us provide, analyse, and improve our products and services including but
                        not limited to data storage, maintenance services, database management, web analytics,
                        payment processing and other services.; We provide your Personal Information to our advisors
                        and consultants for billing, business, legal and taxation purposes; We may disclose your
                        Personal Information pursuant to law, court orders, requests or orders of Governmental
                        authorities, or to exercise our legal rights or defend against legal claims. We believe it
                        is necessary to share your Personal Information in order to investigate, prevent, or take
                        action regarding illegal activities, suspected fraud, situations involving potential threats
                        to the safety of any person, violations of the applicable Terms of Uses of the Platform, or
                        as otherwise required by law. You agree and acknowledge that we may not inform you prior to
                        or after disclosures; We believe it is necessary to disclose your Personal Information to
                        enforce the applicable Terms of Uses or the policy of the Platform and/or reasonably
                        necessary to protect our rights and property or our officers, directors, shareholders,
                        employees or agents; We transfer your Personal Information if we are acquired by / we
                        acquire or merge with another company or transfer a part of our business to a third party.
                        Any third party or resultant entity that receives your Personal Information pursuant to a
                        merger, demerger or business transfer shall have the right to continue to use your Personal
                        Information. In the event of such a sale or transfer, we will reasonably notify you of the
                        same. We may publish any testimonials that you have provided about the Platform and such
                        testimonials and other marketing, promotions and advertisements may include your Personal
                        Information that you have provided to us. We may publish your Personal Information as may be
                        required to perform the services you have engaged us to perform.
                    </p>
                    <br>
                    <h5>
                        THIRD PARTY LINKS
                    </h5>
                    <p class="text-gray">
                        We may have links to other websites on our Platform. We shall not be responsible for the
                        privacy policies and practices of other websites. Such links are subject to their respective
                        terms of use and any additional guidelines and privacy information provided in relation to
                        that use on their website.
                    </p>
                    <br>
                    <h5>
                        ANONYMIZED DATA
                    </h5>
                    <p class="text-gray">
                        We may also use your Personal Information collected to create aggregate anonymized data. We
                        shall ensure that such anonymized data will not be directly identifiable to you or to the
                        Personal Information shared with us. We may use this anonymized data for any purpose
                        including but not limited to research, analytics, and studying usage patterns and to improve
                        our products and services and develop new offerings. By using this Platform, you provide us
                        the right to use your Personal Information to create anonymized data and use it for our
                        business purposes.
                    </p>
                    <br>
                    <h5>
                        LIMITATION OF LIABILITY
                    </h5>
                    <p class="text-gray">
                        Zestl shall not be liable for any damages, direct or indirect, incidental or consequential,
                        and all liabilities (including for negligence, loss or damage such as loss of revenue,
                        unavailability of system or loss of data) and for use of or inability to use products or
                        services of any kind that is offered or to provide indemnity or any other remedy to you or
                        any third party. We make reasonable efforts to ensure that this Platform is free from
                        defects or viruses. It is your responsibility to ensure that you use the correct devices for
                        accessing and using the Platform and to protect from anything that may damage it.
                    </p>
                    <br>
                    <h5>
                        RESERVATION OF RIGHTS
                    </h5>
                    <p class="text-gray">
                        All rights not expressly granted in this policy are reserved by Zestl and its licensors, as
                        the case may be. Nothing contained in this policy shall be construed as conferring by
                        implication, estoppels or otherwise any license or right under any copyright, patent,
                        trademark or other intellectual property right of Zestl or any other person or to user.
                    </p>
                    <br>
                    <h5>
                        CHANGES TO THIS POLICY
                    </h5>
                    <p class="text-gray">
                        Amendments to this policy will be posted to this Platform and will be effective when posted.
                        Your continued use of this Platform following the posting of any amendment, modification, or
                        change shall constitute your acceptance of the amendment. This policy may also be modified
                        by the terms and conditions of the specific product or service of the Platform that you are
                        using. We request you to check the applicable terms and conditions of the Platform to
                        ascertain if any additional considerations are involved with regard to your Personal and
                        Customer Information.
                    </p>
                    <br>
                    <h5>
                        GOVERNING LAWS AND DISPUTES
                    </h5>
                    <p class="text-gray">
                        Governing Laws. This Agreement shall be construed and governed by the laws of India without
                        regard to principles of conflict of laws. Dispute Resolution. Any dispute arising, between
                        you and Zestl shall be submitted to the arbitration to be conducted in Pune, India in
                        English language, in accordance with the rules and regulations of the Arbitration and
                        Conciliation Act, 1996 (as amended from time to time), by a sole arbitrator, who shall be
                        appointed by Zestl and the award made in pursuance thereof shall be binding on the parties.
                        The dispute resolution and arbitration process mentioned in this Section will not prohibit
                        Zestl from approaching the courts for appropriate interim reliefs. The courts in Pune, India
                        shall have an exclusive jurisdiction over any disputes arising under this policy.
                    </p>
                    <br>
                    <h5>
                        DATA PROTECTION OFFICER
                    </h5>
                    <p class="text-gray">
                        If you believe that information we hold about you is incorrect or out of date, or if you have concerns or further queries about how we are handling your Personal Information or any problem or complaint about such matters, please contact our Data Protection Officer whose name and email address are as follows:<br>
                        Name: Jaideep Verma<br>
                        Email: <a href="mailto:DPO@zestl.com">DPO@zestl.com</a>
                    </p>                        
                    <br>
                    <h5>
                        QUESTIONS AND SUGGESTIONS
                    </h5>
                    <p class="text-gray">
                        If you have questions, suggestions, or require to contact us as per this policy, or wish to
                        make a complaint, please contact us at <a href="mailto:support@zestl.com">support@zestl.com</a>
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->