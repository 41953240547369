import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Zvolv - No-code Process Automation';

  constructor(private titleService: Title, private metaService: Meta, private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Google analytics
        gtag('config', 'G-Z2S151LKDH',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    })

    // Set query params in local storage...
    // localStorage.clear()
    this.activatedRoute.queryParams.subscribe(params => {
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          localStorage.removeItem(key)
          localStorage.setItem(key, params[key])
        }
      }
    });

  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();

    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data) {
            return child.snapshot.data;
          }
          return { 'title': appTitle }
        })
      ).subscribe((data: any) => {
        if (data['title'])
          this.titleService.setTitle(data['title']);
        if (data['description'])
          this.metaService.updateTag({ name: 'description', content: data['description'] })
      });
  }
}
