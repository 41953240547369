import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { CareersComponent } from './careers/careers.component';
import { JobDetailComponent } from './careers/job-detail/job-detail.component';
import { JobListComponent } from './careers/job-list/job-list.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CustomersComponent } from './customers/customers.component';
import { FaqComponent } from './faq/faq.component';
import { FeaturesComponent } from './features/features.component';
import { HomeComponent } from './home/home.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { IntroComponent } from './intro/intro.component';
import { NewsroomComponent } from './newsroom/newsroom.component';
import { BecomeAPartnerComponent } from './partners/become-a-partner/become-a-partner.component';
import { PartnersComponent } from './partners/partners.component';
import { PlatformComponent } from './platform/platform.component';
import { PricingCaseStudyComponent } from './pricing-case-study/pricing-case-study.component';
import { PricingComponent } from './pricing/pricing.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ScheduleDemoComponent } from './schedule-demo/schedule-demo.component';
import { SecurityComponent } from './security/security.component';
import { TermsComponent } from './terms/terms.component';
import { WhyZvolvComponent } from './why-zvolv/why-zvolv.component';
import { ZvolvVsAlternativesComponent } from './zvolv-vs-alternatives/zvolv-vs-alternatives.component';
import { EulaComponent } from './eula/eula.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Zvolv - No-code Process Automation',
      description: 'Zvolv is a no-code, intelligent automation platform that enables building enterprise applications in days. Zvolv applications help drive continuous process improvement and innovation, and tackle complex automation problems that existing BPM or RPA tools are in-capable of addressing.',
      ogUrl: 'https://www.zvolv.com'
    }
  },
  {
    path: 'why-zvolv',
    component: WhyZvolvComponent,
    data: {
      title: 'Why Zvolv | Zvolv Hyper Automation Low-Code Platform | Zvolv'
    }
  },
  {
    path: 'platform',
    component: PlatformComponent,
    data: {
      title: 'Zvolv Platform | Zvolv Hyper Automation Low-Code Platform | Zvolv'
    }
  },
  {
    path: 'features',
    component: FeaturesComponent,
    data: {
      title: 'Zvolv - Features'
    }
  },
  {
    path: 'customers',
    component: CustomersComponent,
    data: {
      title: 'Zvolv - Customers'
    }
  },
  {
    path: 'solutions',
    component: CustomersComponent,
    data: {
      title: 'Zvolv - Solutions'
    }
  },
  {
    path: 'pricing',
    component: PricingComponent,
    data: {
      title: 'Zvolv - Pricing'
    }
  },
  {
    path: 'pricing-case-study',
    component: PricingCaseStudyComponent,
    data: {
      title: 'Zvolv - Pricing Case Study'
    }
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {
      title: 'Zvolv - FAQs'
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: 'Zvolv - About Us'
    }
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: {
      title: 'Zvolv - Contact Us'
    }
  },
  {
    path: 'terms',
    component: TermsComponent,
    data: {
      title: 'Zvolv - Terms and Conditions'
    }
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      title: 'Zvolv - Privacy Policy'
    }
  },
  {
    path: 'security',
    component: SecurityComponent,
    data: {
      title: 'Zvolv - Security'
    }
  },
  {
    path: 'schedule-demo',
    component: ScheduleDemoComponent,
    data: {
      title: 'Zvolv - Schedule a demo'
    }
  },
  {
    path: 'zvolv-vs-alternatives',
    component: ZvolvVsAlternativesComponent,
    data: {
      title: 'Zvolv vs alternatives'
    }
  },
  {
    path: 'integrations',
    component: IntegrationsComponent,
    data: {
      title: 'Zvolv - Integrations'
    }
  },
  {
    path: 'intro',
    component: IntroComponent,
    data: {
      title: 'Zvolv - Introduction'
    }
  },
  {
    path: 'partners',
    component: PartnersComponent,
    data: {
      title: 'Zvolv - Partners'
    }
  },
  {
    path: 'careers',
    children: [
      {
        path: '',
        component: CareersComponent,
      },
      {
        path: 'job-list',
        component: JobListComponent,
      },
      {
        path: 'job-detail',
        component: JobDetailComponent,
      },
      {
        path: 'job-detail/:jobId',
        component: JobDetailComponent,
      }
    ],
    data: {
      title: 'Zvolv - Careers'
    }
  },
  {
    path: 'partners/become-a-partner',
    component: BecomeAPartnerComponent,
    data: {
      title: 'Zvolv - Partner Request'
    }
  },
  {
    path: 'newsroom',
    component: NewsroomComponent,
    data: {
      title: 'Zvolv - Newsroom'
    }
  },
  {
    path: 'EULA',
    component: EulaComponent,
    data: {
      title: 'Zvolv - Newsroom'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FullPagesRoutingModule { }
