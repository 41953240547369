import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Typed from 'typed.js';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ScheduleDemoModalComponent } from 'src/app/shared/schedule-demo-modal/schedule-demo-modal.component';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    nav: false
  }

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    const options = {
      strings: ['The Speed of Change', 'The Power of DIY', 'The Need for Simplicity', 'Eliminating the Mundane', 'Strength of Data', 'Deliverying Value'],
      typeSpeed: 100,
      backSpeed: 100,
      showCursor: true,
      cursorChar: '|',
      loop: true
    };

    const typed = new Typed('.typed-element-about', options);
  }


  openScheduleDemoModal() {
    const modalRef = this.modalService.open(ScheduleDemoModalComponent, { backdrop: 'static', size: 'lg', windowClass: 'dark-modal' })
  }

}
