<!-- Hero Start -->
<section class="bg-half-170 bg-dark d-table w-100">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading" *ngIf="jobDetails">
                    <h2 class="title text-white title-dark mb-0"> {{jobDetails.title}} </h2>
                    <h6 class="text-primary mt-2"><i class="mdi mdi-map-marker text-warning me-2 mr-1"></i>{{jobDetails.location}}</h6>
                    <a class="btn btn-primary mt-4" (click)="openApplicationFormModal()"><b>Apply Now <i
                                class="mdi mdi-send"></i></b></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Hero End -->

<!-- Job Detail Start -->
<section class="mt-5 pt-0">
    <div class="container">
        <div class="row" *ngIf="jobDetails">
            <div class="col-lg-8 col-md-7 col-12">

                <div class="mt-4 mt-md-5">
                    <h5>The Role: </h5>
                    <div *ngIf="jobDetails.description" class="rte" [innerHTML]="jobDetails.description"></div>
                </div>
                <div class="mt-4 mt-md-5">
                    <h5 class="mt-4">Responsibilities and Duties: </h5>
                    <div *ngIf="jobDetails.responsibilities" class="rte" [innerHTML]="jobDetails.responsibilities">
                    </div>
                </div>

                <div class="mt-4 mt-md-5">
                    <h5 class="mt-4">Qualifications: </h5>
                    <div *ngIf="jobDetails.qualifications" class="rte" [innerHTML]="jobDetails.qualifications"></div>
                </div>

                <div class="mt-4 mt-md-5">
                    <h5 class="mt-4">Preferred Skills and Knowledge: </h5>
                    <div *ngIf="jobDetails.skills" class="rte" [innerHTML]="jobDetails.skills"></div>
                </div>

                <div class="mt-4">
                    <a class="btn btn-outline-primary" (click)="openApplicationFormModal()"><b>Apply Now <i
                                class="mdi mdi-send"></i></b></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-5 col-12">
                <div class="card sidebar sticky-bar bg-light rounded shadow border-0">
                    <div class="card-body widget border-bottom">
                        <h5 class="mb-0">Job Information</h5>
                    </div>

                    <div class="card-body">
                        <div class="d-flex widget align-items-center" *ngIf="jobDetails.type">
                            <i data-feather="user-check" class="fea icon-ex-md me-3"></i>
                            <div class="flex-1">
                                <h6 class="widget-title mb-1">Employee Type:</h6>
                                <p class="text-primary mb-0">{{jobDetails.type}}</p>
                            </div>
                        </div>

                        <div class="d-flex widget align-items-center mt-3" *ngIf="jobDetails.location">
                            <i data-feather="map-pin" class="fea icon-ex-md me-3"></i>
                            <div class="flex-1">
                                <h6 class="widget-title mb-1">Location:</h6>
                                <p class="text-primary mb-0">{{jobDetails.location}}</p>
                            </div>
                        </div>

                        <div class="d-flex widget align-items-center mt-3" *ngIf="jobDetails.category">
                            <i data-feather="monitor" class="fea icon-ex-md me-3"></i>
                            <div class="flex-1">
                                <h6 class="widget-title mb-1">Job Category:</h6>
                                <p class="text-primary mb-0">{{jobDetails.category}}</p>
                            </div>
                        </div>

                        <div class="d-flex widget align-items-center mt-3" *ngIf="jobDetails.experience">
                            <i data-feather="briefcase" class="fea icon-ex-md me-3"></i>
                            <div class="flex-1">
                                <h6 class="widget-title mb-1">Experience:</h6>
                                <p class="text-primary mb-0">{{jobDetails.experience}}</p>
                            </div>
                        </div>

                        <!-- <div class="d-flex widget align-items-center mt-3" *ngIf="jobDetails.salary">
                            <i data-feather="dollar-sign" class="fea icon-ex-md me-3"></i>
                            <div class="flex-1">
                                <h6 class="widget-title mb-1">Salary:</h6>
                                <p class="text-primary mb-0">{{jobDetails.salary}}</p>
                            </div>
                        </div> -->

                        <div class="d-flex widget align-items-center mt-3" *ngIf="jobDetails.date_posted">
                            <i data-feather="clock" class="fea icon-ex-md me-3"></i>
                            <div class="flex-1">
                                <h6 class="widget-title mb-1">Date posted:</h6>
                                <p class="text-primary mb-0 mb-0">{{jobDetails.date_posted}}</p>
                            </div>
                        </div>

                        <div class="d-flex widget align-items-center ml-3 mt-4">
                            <a class="btn btn-outline-primary" (click)="openApplicationFormModal()"><b>Apply Now <i
                                        class="mdi mdi-send"></i></b></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <div>
                        <h5>About Us</h5>
                    </div>
                    <p class="text-gray">
                        <span class="text-primary">Zvolv</span> is a no-code intelligent process automation platform
                        that enables enterprises to
                        leverage the power of BPM, RPA, AI/ML and advanced analytics to build integrated
                        applications in days;
                    </p>
                    <p class="text-gray">
                        At Zvolv, we believe anyone within an enterprise should be able to design, build and
                        deploy trusted enterprise-grade business applications at rapid speed. This is key to
                        overcoming the <b>The Great Digitisation Challenge</b> we are facing in the post-pandemic era.
                        We are passionate about enabling these enterprise applications that enhance and
                        transform how companies interact with their own employees, customers, partners, and
                        other stakeholders.
                    </p>
                    <p class="text-gray">
                        As one of the fastest growing startups in India, we are hiring the very best and are
                        committed to creating an inclusive work environment that provides every employee the
                        respect and access to equal opportunity.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Job Detail End -->

<re-captcha #captchaRefJD="reCaptcha" (resolved)="resolved($event)" siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ"
    size="invisible" badge="inline" name="cf_hiddenRecaptcha" id="cf_hiddenRecaptcha">
</re-captcha>

<!-- Shape Start -->
<div class="position-relative mt-5" style="z-index: -2;">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 75L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->