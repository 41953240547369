import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit, AfterViewInit {

  public jobsData: any = []
  public jobsTypes: any = []
  public jobsHashMap: any = {}

  @ViewChild('captchaRefCT') public captchaRefCT: RecaptchaComponent;
  public captchaResponse: string = '';

  constructor(private httpService: HttpService) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.onGetJobsListFromServer()
  }

  onGetJobsListFromServer() {
    // Invoke captcha. This will invoke submit form API on resolving captcha key.
    this.captchaRefCT.execute()
  }

  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse
    this.getJobsListFromServer(this.captchaResponse)
  }

  getJobsListFromServer(captcha) {
    // 1. Get reCaptcha key (via recaptcha lib)
    // 2. Get QR Code
    var getQRCodeUrl = 'https://app.zvolv.com/rest/v17/decode/qr/34F5GP6YL35CN'
    var reqheaders = {
      businessDomain: 'sales'
    }
    var qrapidata = { "g-recaptcha-response": captcha }
    this.httpService.postConfig(getQRCodeUrl, JSON.stringify(qrapidata), reqheaders)
      .subscribe(
        (data) => {

          if (data && !data['error']) {
            var loginToken = data['data']['UserDetails']['loginToken']

            // 3. Get form submissions of 556384 - FormID Career types master
            var getFormSubmissionsUrl = 'https://app.zvolv.com/rest/v17/lite/submissions?pagination={"limit":150,"offset":0}&filter=[{"operator":"=","value":556384,"column":"FormID"}]'
            var reqheaders = {
              'Content-Type': 'application/json',
              jwt: loginToken,
              LoginToken: loginToken,
              businessDomain: 'sales'
            }

            this.httpService.get(getFormSubmissionsUrl, reqheaders)
              .subscribe(
                (response) => {
                  if (!response['error']) {
                    var tempResponse = response['data']['elements']
                    for (var t = tempResponse.length - 1; t >= 0; t--) {
                      this.jobsTypes.push(
                        {
                          'title': (tempResponse[t].inputs.filter(s => s.label.includes('Title'))[0].value),
                          'description': (tempResponse[t].inputs.filter(s => s.label.includes('Description'))[0].value)
                        }
                      )
                    }
                  }
                },
                error => {
                  console.log("Error in fetching careers", error)
                }
              );

            // 4. Get form submissions of 556390 - FormID job list master
            var getFormSubmissionsUrl = 'https://app.zvolv.com/rest/v17/lite/submissions?pagination={"limit":150,"offset":0}&filter=[{"operator":"=","value":556390,"column":"FormID"}]'

            this.httpService.get(getFormSubmissionsUrl, reqheaders)
              .subscribe(
                (response) => {
                  if (!response['error']) {
                    var tempResponse = response['data']['elements']
                    for (var t = tempResponse.length - 1; t >= 0; t--) {
                      var status = (tempResponse[t].inputs.filter(s => s.label.includes('Status'))[0].value)
                      if (status != 'Closed') {
                        var tempJobObj = {
                          'id': tempResponse[t].formSubmissionId,
                          'title': (tempResponse[t].inputs.filter(s => s.label.includes('Title'))[0].value),
                          'location': (tempResponse[t].inputs.filter(s => s.label.includes('Location'))[0].value),
                          'category': (tempResponse[t].inputs.filter(s => s.label.includes('Category'))[0].value)
                        }
                        this.jobsData.push(tempJobObj)

                        if (!this.jobsHashMap.hasOwnProperty(tempJobObj.category)) {
                          this.jobsHashMap[tempJobObj.category] = 0
                        }

                        this.jobsHashMap[tempJobObj.category] = this.jobsHashMap[tempJobObj.category] + 1
                      }
                    }
                  }
                },
                error => {
                  console.log("Error in fetching careers", error)
                }
              );
          }
        },
        error => {
          console.log("Error in fetching careers", error)
        }
      );
  }

}
