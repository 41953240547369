import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SavingsCalculatorModalComponent } from '../savings-calculator-modal/savings-calculator-modal.component';

@Component({
  selector: 'app-savings-calculator',
  templateUrl: './savings-calculator.component.html',
  styleUrls: ['./savings-calculator.component.scss']
})
export class SavingsCalculatorComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openSavingsCalculatorModal() {
    const modalRef = this.modalService.open(SavingsCalculatorModalComponent, { backdrop: 'static', size: 'xl', windowClass: 'dark-modal' })
  }
}
