<!-- Hero Start -->
<section class="d-table w-100 overflow-hidden bg-dark" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="title-heading text-center mt-4 mt-md-5 pt-md-5">
                    <h1 class="heading mb-3">Zvolv Careers</h1>
                    <p class="para-desc mx-auto text-muted mb-4">Ready to embrace the challenge?</p>
                    <p *ngIf="jobsData.length>0" class="para-desc mx-auto text-muted mb-4"><span
                            class="text-primary">{{jobsData.length}}</span>
                        Opportunities Available
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Hero End -->

<!-- Start Jobs -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
                <div class="sidebar p-4 rounded shadow">

                    <!-- Categories -->
                    <div class="widget mb-4 pb-4 border-bottom">
                        <h5 class="widget-title">Categories</h5>
                        <div class="mt-4 mb-0">
                            <select class="form-select form-control" aria-label="Default select example"
                                [(ngModel)]="selectedCategory" (change)="filterByCategory($event.target.value)">
                                <option selected [ngValue]="null">All Jobs</option>
                                <option>Engineering</option>
                                <option>Customer Success</option>
                                <option>Sales</option>
                                <option>Marketing</option>
                                <option>Global Support</option>
                            </select>
                        </div>
                    </div>
                    <!-- Categories -->

                    <!-- Date Posted -->
                    <!-- <div class="widget mb-4 pb-4 border-bottom">
                        <h5 class="widget-title">Date Posted</h5>
                        <div class="mt-4">
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" [value]="1" name="flexRadioDefault" id="oneday"
                                    (change)="filterByDate($event.target.value)">
                                <label class="form-check-label" for="oneday">Last 24 hours</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                    id="sevenday">
                                <label class="form-check-label" for="sevenday">Last 7 days</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                    id="fourteenhour">
                                <label class="form-check-label" for="fourteenhour">Last 14 days</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                    id="onemonth">
                                <label class="form-check-label" for="onemonth">Last 30 days</label>
                            </div>
                        </div>
                    </div> -->
                    <!-- Posted -->

                    <!-- Job Type -->
                    <div class="widget mb-4 pb-4 border-bottom">
                        <h5 class="widget-title">Job Type</h5>
                        <div class="mt-4">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="Contract Base"
                                    [(ngModel)]="selectedType" (change)="filterByType($event.target.value)"
                                    id="contract" name="jltype">
                                <label class="form-check-label" for="contract">Contract Base</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="Freelancer"
                                    [(ngModel)]="selectedType" (change)="filterByType($event.target.value)"
                                    id="freelancer" name="jltype">
                                <label class="form-check-label" for="freelancer">Freelancer</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="Full Time"
                                    [(ngModel)]="selectedType" (change)="filterByType($event.target.value)"
                                    id="fulltime" name="jltype">
                                <label class="form-check-label" for="fulltime">Full Time</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="Part Time"
                                    [(ngModel)]="selectedType" (change)="filterByType($event.target.value)"
                                    id="parttime" name="jltype">
                                <label class="form-check-label" for="parttime">Part Time</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="Remote" [(ngModel)]="selectedType"
                                    (change)="filterByType($event.target.value)" id="remote" name="jltype">
                                <label class="form-check-label" for="remote">Remote</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="Intership"
                                    [(ngModel)]="selectedType" (change)="filterByType($event.target.value)" id="intern"
                                    name="jltype">
                                <label class="form-check-label" for="intern">Intership</label>
                            </div>
                        </div>
                    </div>
                    <!-- Job Type -->

                    <!-- Experience -->
                    <div class="widget">
                        <h5 class="widget-title">Experience</h5>
                        <div class="mt-4">
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" value="Fresher"
                                    [(ngModel)]="selectedExperience" (change)="filterByExperience($event.target.value)"
                                    name="flexRadioDefault2" id="fresher">
                                <label class="form-check-label" for="fresher">Fresher</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" value="1+ Years"
                                    [(ngModel)]="selectedExperience" (change)="filterByExperience($event.target.value)"
                                    name="flexRadioDefault2" id="oneyear">
                                <label class="form-check-label" for="oneyear">1+ Years</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" value="2+ Years"
                                    [(ngModel)]="selectedExperience" (change)="filterByExperience($event.target.value)"
                                    name="flexRadioDefault2" id="twoyear">
                                <label class="form-check-label" for="twoyear">2+ Years</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" value="3+ Years"
                                    [(ngModel)]="selectedExperience" (change)="filterByExperience($event.target.value)"
                                    name="flexRadioDefault2" id="3year">
                                <label class="form-check-label" for="3year">3+ Years</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" value="5+ Years"
                                    [(ngModel)]="selectedExperience" (change)="filterByExperience($event.target.value)"
                                    name="flexRadioDefault2" id="fiveyear">
                                <label class="form-check-label" for="fiveyear">5+ Years</label>
                            </div>
                            <div class="form-check mb-0">
                                <input class="form-check-input" type="radio" value="10+ Years"
                                    [(ngModel)]="selectedExperience" (change)="filterByExperience($event.target.value)"
                                    name="flexRadioDefault2" id="tenyear">
                                <label class="form-check-label" for="tenyear">10+ Years</label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex widget align-items-center mt-4">
                        <a class="btn btn-sm btn-outline-primary" (click)="resetFilters()">Reset</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-8 col-12">

                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 pull-right">
                        <!-- SEARCH -->
                        <div class="card border-0 sidebar mr-2">
                            <div class="card-body p-0">
                                <div class="widget">
                                    <div id="search2" class="widget-search mb-0">
                                        <form role="search" method="get" id="searchform" class="searchform">
                                            <div>
                                                <input type="text" class="border rounded" name="s" id="s"
                                                    placeholder="Search for job title"
                                                    (keyup)="search($event.target.value)">
                                                <input type="submit" id="searchsubmit" value="Search">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- SEARCH -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="job-box bg-light d-md-flex align-items-center border-0 shadow rounded p-4 mb-4 mt-4 position-relative"
                            *ngFor="let job of filteredJobsData;">
                            <div class="ms-md-4 mt-4 mt-sm-0">
                                <a [routerLink]="['/careers/job-detail/'+job.id]" class="text-dark h5">{{job.title}}</a>
                                <h5 class="text-gray">{{job.category}}</h5>
                                <ul class="list-unstyled mb-0 mt-2">
                                    <li class="d-lg-inline text-gray h6 mb-0 me-lg-2 mr-4"><i
                                            class="uil uil-map-marker text-primary mr-1"></i>{{job.location}}</li>
                                </ul>
                                <div class="mt-2">
                                    <span class="badge bg-soft-primary mr-1"
                                        *ngFor="let tag of job.tags;">{{tag}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Jobs -->

<re-captcha #captchaRefJL="reCaptcha" (resolved)="resolved($event)" siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ"
    size="invisible" badge="inline" name="cf_hiddenRecaptcha" id="cf_hiddenRecaptcha">
</re-captcha>

<!-- Shape Start -->
<div class="position-relative mt-5" style="z-index: -2;">
    <div class="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 75L2160 0H2880V200H0V125H720Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->