<!-- Footer Start -->
<footer class="footer pb-0">
        <div class="container-fluid mb-0">
                <div class="bg-dark-decor-ltr d-none d-md-block position-absolute opacity-0-5"></div>
        </div>
        <div class="container mb-0">
                <div class="row">
                        <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 class="text-light footer-head">Applications</h4>
                                <ul class="list-unstyled footer-list mt-4">
                                        <li><a [routerLink]="['/solutions']" fragment="expansion" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Expansion</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="auditing" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Auditing</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="innovation" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Innovation</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="planning" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Planning</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="execution" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Execution</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="operations" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Operations</a></li>
                                </ul>
                        </div>

                        <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 class="text-light footer-head">Industries</h4>
                                <ul class="list-unstyled footer-list mt-4">
                                        <li><a [routerLink]="['/solutions']" fragment="retail" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Retail</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="healthcare" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Healthcare</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="hr" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        HR</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="finance" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Finance</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="manfacturing"
                                                        class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                                                        Manufacturing</a></li>
                                        <li><a [routerLink]="['/solutions']" fragment="fab" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        F&B</a></li>
                                </ul>
                        </div>

                        <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 class="text-light footer-head">Company</h4>
                                <ul class="list-unstyled footer-list mt-4">
                                        <li><a [routerLink]="['/platform']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Platform</a></li>
                                        <li><a [routerLink]="['/features']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Features</a></li>
                                        <li><a [routerLink]="['/why-zvolv']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Why Zvolv?</a></li>
                                        <li><a [routerLink]="['/partners/become-a-partner']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Become A Partner</a></li>
                                        <!-- <li><a [routerLink]="['/partners']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Partners</a></li> -->
                                        <li><a [routerLink]="['/careers']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Careers & Culture</a></li>
                                        <li><a href="https://zvolv.com/blog" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Blog</a></li>
                                        <!-- <li><a [routerLink]="['/newsroom']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Press & Media</a></li> -->
                                        <li><a [routerLink]="['/about-us']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        About Us</a></li>
                                        <!-- <li><a [routerLink]="['/zvolv-vs-alternatives']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Zvolv vs Alternatives</a></li> -->
                                </ul>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 class="text-light footer-head">Support</h4>
                                <ul class="list-unstyled footer-list mt-4">
                                        <li><a [routerLink]="['/faq']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        FAQs</a></li>
                                        <li><a href="https://app.zvolv.com/help" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Documentation</a></li>
                                        <li><a [routerLink]="['/docs/api']" target="_blank" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>Zvolv API</a>
                                        </li>
                                        <li><a [routerLink]="['/contact-us']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Contact Us</a></li>
                                        <!-- <li><a [routerLink]="['/security']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Security</a></li> -->
                                        <li><a [routerLink]="['/terms']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Terms
                                                        of Services</a></li>
                                        <li><a [routerLink]="['/privacy']" class="text-foot"><i
                                                                class="mdi mdi-chevron-right mr-1"></i>
                                                        Privacy Policy</a></li>
                                </ul>
                        </div>

                        <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h4 class="text-light footer-head">Newsletter</h4>
                                <p class="mt-4">Sign up and receive the latest product news and interesting case studies
                                        via emails.</p>
                                <form [formGroup]="subscribeNewsletterForm">
                                        <div class="form-row">
                                                <div class="col-12">
                                                        <div class="foot-subscribe form-group position-relative">
                                                                <label>Email <span class="text-danger">*</span></label>
                                                                <i
                                                                        class="mdi mdi-email-outline icons text-muted mx-auto my-auto"></i>
                                                                <input type="email" formControlName="email"
                                                                        placeholder="Your email:"
                                                                        class="form-control pl-5"
                                                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                                <div *ngIf="submitted && f.email.errors"
                                                                        class="invalid-feedback">
                                                                        <div *ngIf="f.email.errors.required">Email is
                                                                                required</div>
                                                                        <div *ngIf="f.email.errors.email">Email must be
                                                                                a valid email
                                                                                address</div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                        <re-captcha #captchaRefSub="reCaptcha" (resolved)="resolved($event)"
                                                siteKey="6LfhJOYUAAAAANf49mZen4T1O33iEHS2QGgNfGTJ" size="invisible"
                                                badge="inline"></re-captcha>

                                        <div *ngIf="!showSuccess" class="text-center">
                                                <button class="btn btn-primary btn-block" (click)="onSubmit()"
                                                        [disabled]="showLoader">Subscribe <i *ngIf="showLoader"
                                                                class="mdi mdi-loading mdi-spin"></i></button>
                                        </div>
                                        <div *ngIf="showSuccess" class="alert alert-success mt-2" role="alert">
                                                Thank you for subscribing us.
                                                <button type="button" class="float-right close" aria-label="Close"
                                                        (click)="showSuccess = false;onReset()">
                                                        <span aria-hidden="true">&times;</span>
                                                </button>
                                        </div>
                                        <div *ngIf="showError" class="alert alert-danger mt-2" role="alert">
                                                <button type="button" class="float-right close" aria-label="Close"
                                                        (click)="showError = false">
                                                        <span aria-hidden="true">&times;</span>
                                                </button>
                                                Error while submitting form. Please try again.
                                                <div *ngIf="errorMsg">
                                                        {{errorMsg}}
                                                </div>
                                        </div>
                                </form>
                                <ul class="list-unstyled social-icon social mb-0 mt-4 text-center">
                                        <li class="list-inline-item mr-1"><a
                                                        href="https://www.linkedin.com/company/zestl" class="rounded"><i
                                                                class="mdi mdi-linkedin"></i></a></li>
                                        <li class="list-inline-item mr-1"><a
                                                        href="https://www.facebook.com/Zvolv-875246689503202"
                                                        class="rounded"><i class="mdi mdi-facebook"></i></a></li>
                                        <li class="list-inline-item"><a href="https://twitter.com/ZestlAI"
                                                        class="rounded"><i class="mdi mdi-twitter"></i></a></li>
                                </ul>
                                <div class="text-center">
                                        <a class="mt-3" href="https://www.g2.com/products/zvolv/reviews"><img
                                                        style="height: 50px;" alt="Zvolv on G2"
                                                        src="assets/images/marketing/g2.png"></a>
                                        <a class="mt-3" href="https://www.capterra.com/p/181482/Zvolv/"
                                                style="display: block"><img style="height: 50px;"
                                                        alt="Zvolv on Capterra"
                                                        src="https://assets.capterra.com/badge/3ff83b08ddcd96c2f5a03e583addf9ea.png"
                                                        border="0"></a>
                                </div>
                        </div>

                </div>

                <div class="row mt-5">
                        <div class="col-lg-12 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                <h5 class="title text-white font-weight-bold mb-0"><img src="assets/images/logo-zvolv-light.png"
                                                height="24" alt="">
                                        About Zvolv</h5>
                                <p class="mt-1">
                                        Zvolv is a no-code process automation platform that leverages intelligent
                                        automation to tackle the last mile digital transformation challenges that your
                                        existing ERP, BPM or RPA tools cannot. With Zvolv, process champions can now
                                        innovate, automate and evolve applications 10x faster than legacy IBPMS suites
                                        or custom development, with no dependence on back-logged IT teams. Zvolv lets
                                        you integrate human like decision making capabilities and orchestrate processes
                                        across your people, data and systems, with automation that delivers in days and
                                        evolves in hours.
                                </p>
                                <!-- <span class="text-primary typed-element-footer"></span> -->
                        </div>
                </div>
        </div>
</footer>
<!--end footer-->
<footer class="footer footer-bar">
        <div class="container text-center">
                <div class="row align-items-center">
                        <div class="col-sm-12">
                                <p class="mb-0">© 2021 Zestl Software</p>
                        </div>
                </div>
        </div>
</footer>
<!--end footer-->
<!-- Footer End -->