<!-- Hero Start -->
<section class="d-table w-100 overflow-hidden bg-dark" id="home">
    <div class="container position-relative">
        <div class="row align-items-center pt-5">
            <div class="col-lg-7 col-md-6">
                <div class="title-heading">
                    <h1 class="heading mb-3">Introducing the new era of Intelligent Automation for everyone</h1>
                    <p class="para-desc text-gray">
                        Your processes, your tools, your data – Make it better with Zvolv’s enterprise-grade digital
                        transformation.
                    </p>
                    <div class="mt-4 pt-2">
                        <a (click)="openScheduleDemoModal()" class="btn btn-primary">Schedule a demo</a><br>
                        <a (click)="gotoZvolvAppSignup()" class="text-light mb-2 ml-2 mt-3 mr-2 ultr"><b>Try
                                For Free <i class="mdi mdi-arrow-right"></i></b></a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="classic-saas-image position-relative bg-half-170">
                    <div class="bg-saas-shape position-relative">
                        <img src="assets/images/landing/why-zvolv.png" class="mover mx-auto d-block" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h2 class="title mb-4"><span class="text-primary">Zvolv</span> Integrations</h2>
                    <p class="text-gray">
                        Zvolv enables you to unify your data, orchestrate processes across tools, extend and customize
                        your systems to automate your unique processes, end to end.
                    </p>
                    <p class="text-gray">
                        Zvolv provides a huge library of ready to use integration widgets and sample bots for the most
                        popular tools you may already be accustomed to, as well as the flexibility to write your own
                        bots for interfacing to custom APIs and legacy tools.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h2>Zvolv for Messaging tools</h2>
                    <h4 class="text-gray">
                        Slack, Teams, WhatsApp
                    </h4>
                    <p class="para-desc text-gray">
                        Integrate your Zvolv workflows with tools that you already use for day to day communications.
                        See your task lists, complete tasks, raise and respond to escalations, get notified on delays
                        and deviations, get daily performance summaries and other operational reports, and much more.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h2>Zvolv for ERP/CRM tools</h2>
                    <h4 class="text-gray">
                        SAP HANA, MS Dynamics CRM / Navision, Oracle DB, Salesforce Agile CRM, Sugar, MySQL, PostgreSQL,
                        SQLserver
                    </h4>
                    <p class="para-desc text-gray">
                        Unify data across your processes and systems of records. Push or pull data as essential, via
                        real-time or scheduled interfaces. Zvolv supports REST APIs, SOAP web services, SFTP, as well as
                        direct database interfaces.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h2>Zvolv for DMS tools</h2>
                    <h4 class="text-gray">
                        Dropbox, Box, Google Drive, S3, Sharepoint
                    </h4>
                    <p class="para-desc text-gray">
                        Integrate your document related workflows with your existing document storage systems. With
                        Zvolv, managing your document revisions and approvals, tagging and extracting data from your
                        documents for efficiency sorting and searching, and enforcing access policies becomes a breeze.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h2>Zvolv for PM tools</h2>
                    <h4 class="text-gray">
                        Trello, JIRA, Wrike, Zoho, Asana, MS Flow, Basecamp, Monday
                    </h4>
                    <p class="para-desc text-gray">
                        Use the full-features and extremely intuitive project and task management features built into
                        Zvolv, or interface with your PM tools of choice to trigger and acknowledge tasks and tickets,
                        pull data from different tools into Zvolv dashboards, or use Zvolv intelligent automation to
                        orchestrate activities across tools.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h2>Zvolv for BI and analytics tools</h2>
                    <h4 class="text-gray">
                        Tableau, Power BI
                    </h4>
                    <p class="para-desc text-gray">
                        Zvolv’s drill-down analytics and reporting functionalities give you great insights from data
                        collected in your Zvolv apps. Go beyond with scheduled or real-time data-dumps from Zvolv into
                        AWS S3 or other interface data layers, with custom defined formats that work with your existing
                        BI tool implementations.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h2>Zvolv for RPA tools</h2>
                    <h4 class="text-gray">
                        UI Path, Automation Anywhere
                    </h4>
                    <p class="para-desc text-gray">
                        Zvolv enables you to orchestrate your human and bot driven activities to drive time-bound
                        execution. Zvolv IA bots can handle many complex data-mining, analytics or machine learning
                        based tasks, as well as support interfaces with your existing RPA tools to leverage data-entry
                        and document-extraction automation.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h2>Zvolv for Payments</h2>
                    <h4 class="text-gray">
                        Stripe, Razor-pay
                    </h4>
                    <p class="para-desc text-gray">
                        Zvolv applications can integrate payment gateways of your choice.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h2>Zvolv for Communications</h2>
                    <h4 class="text-gray">
                        Twilio, SMS providers, Hubspot, AWS SES, Mailchimp
                    </h4>
                    <p class="para-desc text-gray">
                        Leverage your existing service providers and SaaS subscriptions to send emails and SMS’s from
                        your Zvolv applications.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h2>Zvolv for SSO</h2>
                    <h4 class="text-gray">
                        AD, Okta, Onelogin
                    </h4>
                    <p class="para-desc text-gray">
                        Zvolv supports many industry-standard authentication solutions.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h2>Zvolv for Social Media</h2>
                    <h4 class="text-gray">
                        Facebook, Twitter, LinkedIn
                    </h4>
                    <p class="para-desc text-gray">
                        Zvolv applications can drive or analyse your social media campaigns.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/feature/zapps.png" class="img-fluid shadow rounded" alt="">
            </div>
            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="ml-lg-5">
                    <h2>Zvolv for Others</h2>
                    <h4 class="text-gray">
                        Docusign, Zapier, Google sheets, Google forms, Smartsheets, Airtable, Calendly, Google Calendar,
                        Zenput, Ariba, Freshdesk
                    </h4>
                    <p class="para-desc text-gray">
                        With Zvolv, your employees don’t need to change the way they already operate. Zvolv can fill in
                        the missing functionality across tools, bridge the gaps and automate the mundane. Integrate
                        hundreds of apps without writing any with our ready to use connectors.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="mr-lg-5">
                    <h2>Open REST APIs and real-time Web-hooks</h2>

                    <p class="para-desc text-gray">
                        Zvolv provides access to a broad range of functionality via industry standard RESP APIs with
                        JSON payload and simple yet extremely secure authentication protocols. Contact sales to get
                        access to our published API documentation.
                    </p>
                </div>
            </div>

            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/feature/drill-down-analytics.png" class="img-fluid shadow rounded" alt="">
            </div>
        </div>
    </div>
</section>

<!-- Footer CTAs -->
<section>
    <div class="container mt-100 mt-60">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="media align-items-center shadow rounded p-4 features">
                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                        <i class="uil uil-rocket"></i>
                    </div>
                    <div class="content ml-4">
                        <h5 class="mb-1"><a (click)="gotoZvolvAppSignup()" class="text-dark">Get Started !</a></h5>
                        <p class="text-gray mb-0">Let’s get started with building your first application with Zvolv.
                        </p>
                        <div class="mt-2">
                            <a (click)="gotoZvolvAppSignup()" class="btn btn-sm btn-soft-primary">Start My Free
                                Trial</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="media align-items-center shadow rounded p-4 features">
                    <div class="icons m-0 rounded h2 text-primary text-center px-3">
                        <i class="uil uil-envelope-check"></i>
                    </div>
                    <div class="content ml-4">
                        <h5 class="mb-1"><a (click)="openScheduleDemoModal()" class="text-dark">Get in Touch !</a></h5>
                        <p class="text-gray mb-0">Get in touch and we will assist/guide you in every step of the way.
                        </p>
                        <div class="mt-2">
                            <a (click)="openScheduleDemoModal()" class="btn btn-sm btn-soft-primary">Schedule a demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Footer CTAs end -->