<!-- Hero Start -->
<section class="bg-half-260 bg-dark d-table w-100"
    style="background: url('assets/images/pricing-case-study.svg') no-repeat center center;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-heading text-center mt-4">
                    <h4 class="text-muted">Pricing Case Study</h4>
                    <h2 class="title-dark text-white mb-3">Supplier and Vendor Management
                    </h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Hero End -->

<!-- Hero Start -->
<!-- <section class="bg-half bg-dark d-table w-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Pricing Case Study </h4>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <p>
                    Supplier/vendor integration and tracking is a popular use case built on Zvolv. It’s a process that
                    varies across industries and standardized tools do not meet requirements. ERP systems handle all the
                    organized documents and data such as POs and payments. But most of the day-day interactions and
                    co-ordination happens over calls, emails, scores of spreadsheets and adhoc tools. As a result high
                    level visibility is lacking, real-time escalations are hard to come by, impact to plans are not
                    predictable. Automating such a process requires the below components:
                </p>
                <div class="text-center mt-4 mb-4">
                    <img src="assets/images/pricing-case-study.svg" height="300px" alt="">
                </div>
                <div>
                    Lets say application is the following:
                    <ul>
                        <li>Forms and workflows (data collection and approvals) for vendor on-boarding</li>
                        <li>Automation to classify vendors, track history and performance, route relevant information to
                            ERP systems</li>
                        <li>Vendor communication (approvals, additional data requests)</li>
                        <li>Vendor portal and/or mobile application to review RFQs and PO information</li>
                        <li>Forms and workflows (purchase requests, approvals) for internal buyers</li>
                        <li>Automation to identify PR types and shortlist vendors</li>
                        <li>Internal portal and/or mobile app for buyers to review PRs, issue RFQs to chosen vendors
                        </li>
                        <li>Vendor response tracking for RFQs, PO acceptance or changes</li>
                        <li>Internal dashboards and reports to track PRs, POs, vendors and payments</li>
                        <li>Bi-directional integration with ERP systems to pull and push vendor and PO data as necessary
                        </li>
                        <li>Vendor apps to update status on order execution post PO (raw materials received? designs
                            finalized? quality checks complete? goods ready to dispatch?)</li>
                        <li>Automation to escalate delays on vendor execution relative to agreed timelines</li>
                        <li>Escalation dashboards and reports to notify stakeholders on impact to plans due to vendor
                            delays</li>
                    </ul>
                </div>

                <div class="faq-content mt-4 pt-2">
                    <div class="accordion" id="accordionCustDev">
                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsecust" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsecust">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingthree">
                                    <h5 class="title mb-0">Custom development</h5>
                                </div>
                            </a>

                            <div id="collapsecust" class="collapse" aria-labelledby="headingthree"
                                data-parent="#accordionCustDev">
                                <div class="card-body p-5">
                                    <p>
                                        A custom development project to build this series of workflows and automations
                                        would require a team of 6+ resources working for 4+ months.
                                    </p>
                                    <div class="mt-4">
                                        <p>Here is a typical work breakdown:</p>
                                        <ul>
                                            <li>1 week for setting up dev and prod servers, development framework,
                                                login/authentication, sprint and test plans</li>
                                            <li>1 week for database schema design, setting up encryption, redundancy,
                                                snapshots, log-rotates and other data maintenance</li>
                                            <li>1 week for designing and coding vendor and buyer forms</li>
                                            <li>1 week for workflows and approval forms and logic</li>
                                            <li>3 weeks for mobile app development and testing with dashboards, tasks,
                                                forms, authentication and security setups (x 2 for Android and iOS)</li>
                                            <li>3 weeks for ERP integration and testing</li>
                                            <li>1 week for setting up user roles, assignments, emails, notifications,
                                                escalation policies</li>
                                            <li>2 weeks for end to end packaging and contextual testing and bug fixes
                                            </li>
                                            <li>1 week for production server go-live setup, security, scalability
                                                configurations and review</li>
                                            <li>3 weeks for UATs, roleplays, accommodating user feedback and change
                                                requests</li>
                                        </ul>
                                        <h5 class="pl-4">Total 20 man-months of effort</h5>
                                    </div>

                                    <div class="mt-4">
                                        <p>Resources required:</p>
                                        <ul>
                                            <li>1 business analyst/project manager</li>
                                            <li>1 web developer versed in Angular, HTML, CSS</li>
                                            <li>1 mobile developer well versed in Android and iOS development</li>
                                            <li>1 database and server engineer well versed with PHP or NodeJS and cloud
                                                setups and dev-ops</li>
                                            <li>2 test engineers</li>
                                        </ul>
                                        <h5 class="pl-4">Minimum 6 resources required</h5>
                                    </div>

                                    <div class="mt-4">
                                        <p>
                                            Time to launch: 4+ months from initiation to go-live with optimal sprint
                                            planning and resource allocation. Typical execution delays, bug rates and
                                            revisions would add another month.
                                            Total development cost: At a nominal hourly rate of $50 per resource, plus
                                            additional costs for subscription tools, dev servers, travel etc, this
                                            project would cost $170k to launch.
                                            Yearly operations and maintenance costs: With typical cloud servers and
                                            databases, and a partial dev-ops and technical support resource, recurring
                                            annual cost would be ~$20k.
                                            Additional change requests per year: Assuming 5 man-weeks of effort for
                                            incremental change requests per year (including business analyst, project
                                            manager, coding and testing resources), would amount to $10k
                                            Total estimated cost of ownership for 3 years: $250k
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion" id="accordionZvolvDev">
                        <div class="card border-0 rounded mb-2">
                            <a data-toggle="collapse" href="#collapsezvolv" class="faq position-relative collapsed"
                                aria-expanded="false" aria-controls="collapsezvolv">
                                <div class="card-header border-0 bg-light p-3 pr-5" id="headingz">
                                    <h6 class="title mb-0">With <span class="text-primary">Zvolv</span></h6>
                                </div>
                            </a>

                            <div id="collapsezvolv" class="collapse" aria-labelledby="headingz"
                                data-parent="#accordionZvolvDev">
                                <div class="card-body p-4">
                                    <div>
                                        <h5>JOIN THE NOCODE REVOLUTION</h5>
                                        <p>The Zvolv no-code automation platform offers an alternative that is unrivaled
                                            in speed to launch, cost of ownership and customization flexibility.</p>
                                    </div>
                                    <div class="mt-4">
                                        <p>The same application built on Zvolv would require:</p>
                                        <ul>
                                            <li>50-75 Zblocks. A Zblock is a building block, can be a human task, data
                                                form, automation bot, dashboard widget or integration database.</li>
                                            <li>3 Zapps. A Zapp is an individual workflow application, made by logically
                                                and contextually interconnecting Zblocks.</li>
                                            <li>1 Workspace servers across vendor and buyer web-portals and
                                                white-labeled mobile apps. </li>
                                        </ul>
                                    </div>
                                    <div class="mt-4">
                                        <p>
                                            Zvolv’s extensive feature set includes built-in task and workflow
                                            management, dynamic form and logic builder, an extensive bot library for
                                            custom automation, a rich set of dashboards and drill-down analytics tools.
                                            <br>
                                            Zvolv’s enterprise license provides flexible block based licensing, custom
                                            automation support, a variety of built in business and developer tools,
                                            hypercare support and more.
                                            <br>
                                            With intuitive, visual process development tools, Zvolv enables configuring
                                            this application in under a week, with the ability to go-live in under a
                                            month.
                                            <br>
                                            Including private cloud hosting, 1 month of configuration and low-code
                                            custom bot development support, and 1 month of hypercare post launch, this
                                            application would cost under $50k to develop and launch on Zvolv.
                                            <br>
                                            Recurring annual license fees would be contingent on the number of building
                                            blocks consumed and would include managed private cloud servers with dev-ops
                                            support with enterprise SLAs, and on-demand hypercare and bot development
                                            support for on-going application evolution.
                                            <br>
                                            Additionally Zvolv offers the ability to launch your application in stages
                                            to manage the change better, build up user confidence, iterate more often
                                            and add advanced AI/ML intelligence on top as required.
                                        </p>
                                    </div>

                                    <div class="mt-4">
                                        <h5>Total Cost</h5>
                                        <p><b>Time to launch:</b> Under a month</p>
                                        <p><b>Total development cost:</b> Under $50k</p>
                                        <p><b>Yearly operations and maintenance costs:</b> Included in Zvolv annual
                                            building block based license, estimated under $25k for sample application
                                        </p>
                                        <p><b>Additional change requests per year:</b> Self-help features allow zero
                                            cost changes, with expert bot-building help and hypercare support available
                                            on demand.</p>
                                        <p><b>Total estimated cost of ownership for 3 years:</b> $100k</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4 pl-4">
                    <h5>Total savings:</h5>
                    <p>
                        <span class="text-primary">70%</span> on cost to launch<br>
                        <span class="text-primary">60%</span> on total 3 year cost of ownership<br>
                        <span class="text-primary">4x</span> faster to deploy<br>
                    </p>
                </div>
                <div class="text-center mt-4">
                    <p>Interested in revolutionizing your operations and saving every buck?</p>
                    <button class="btn btn-primary">Get Started</button>
                </div>
            </div>
        </div>
    </div>
</section>