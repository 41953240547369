import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-job-apply',
  templateUrl: './job-apply.component.html',
  styleUrls: ['./job-apply.component.scss']
})
export class JobApplyComponent implements OnInit {

  @Input()
  jobTitle: any = null

  @ViewChild('captchaRefDC') public captchaRefDC: RecaptchaComponent;

  applicationForm: FormGroup;
  submitted = false;
  showLoader = false;
  showSuccess = false
  showError = false
  errorMsg = null

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private httpService: HttpService) { }

  ngOnInit(): void {
    this.applicationForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      resume: ['', [Validators.required]],
      resumeFileSource: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.applicationForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.applicationForm.invalid) {
      return;
    }

    // Invoke captcha. This will invoke submit form API on resolving captcha key.
    this.captchaRefDC.execute()
  }

  onReset() {
    this.applicationForm.reset();
    this.submitted = false;
  }

  public captchaResponse: string = '';
  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse
    this.submitForm(this.captchaResponse)
  }

  resetVar() {
    this.showSuccess = false
    this.showError = false
    this.errorMsg = null
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.applicationForm.patchValue({
        resumeFileSource: file
      });
    }
  }

  submitForm(captcha) {

    this.resetVar()

    // 1. Get reCaptcha key (via recaptcha lib)
    // 2. Get QR Code
    var getQRCodeUrl = 'https://app.zvolv.com/rest/v17/decode/qr/34F5GP6YL35CN'
    var reqheaders = {
      businessDomain: 'sales'
    }
    var qrapidata = { "g-recaptcha-response": captcha }

    this.showLoader = true
    this.httpService.postConfig(getQRCodeUrl, JSON.stringify(qrapidata), reqheaders)
      .subscribe(
        (response) => {
          this.showLoader = false

          if (response && response['error']) {
            this.showError = true
            this.errorMsg = response['message']
          }
          else {
            var loginToken = response['data']['UserDetails']['loginToken']

            // 3. Upload Resume/CV on s3 first
            var fileSource = this.applicationForm.get('resumeFileSource').value
            const formData = new FormData();
            formData.append('file', fileSource);

            var uploadFileUrl = 'https://app.zvolv.com/rest/v17/5WGCU2Z62RZV3/5WGCU2Z62RZV3/fileupload/'
            var reqheadersf = {
              jwt: loginToken,
              LoginToken: loginToken,
              businessDomain: 'sales'
            }

            this.showLoader = true
            this.httpService.postConfig(uploadFileUrl, formData, reqheadersf)
              .subscribe(
                (response) => {
                  this.showLoader = false
                  if (response && response['error']) {
                    this.showError = true
                    this.errorMsg = response['message']
                  }
                  else {
                    var fileObjForAPI = [{
                      "media_name": fileSource.name,
                      "media_size": fileSource.size,
                      "media_type": fileSource.type,
                      "media": response['data'].file_url.url
                    }]

                    // 4. Submit form. Use link generated from previous call and send here.
                    // 556411 - Form ID job applications master (Name, Email, Contact Number, Resume, Applied For)
                    var submitFormUrl = 'https://app.zvolv.com/rest/v17/5WGCU2Z62RZV3/forms/556411/submissions/'
                    var reqheaders = {
                      'Content-Type': 'application/json',
                      jwt: loginToken,
                      LoginToken: loginToken,
                      businessDomain: 'sales'
                    }
                    var submitformapidata = {
                      "FormData": {
                        "type": "KEY_LABELS",
                        "data": {
                          "Name": this.applicationForm.value.name,
                          "Email": this.applicationForm.value.email,
                          "Contact Number": this.applicationForm.value.phone,
                          "Resume": fileObjForAPI,
                          "Applied For": [
                            {
                              "view": this.jobTitle,
                              "select": this.jobTitle,
                              "extraLabels": []
                            }
                          ]
                        }
                      }
                    }

                    this.showLoader = true
                    this.httpService.postConfig(submitFormUrl, JSON.stringify(submitformapidata), reqheaders)
                      .subscribe(
                        (response) => {
                          this.showLoader = false

                          if (response && response['error']) {
                            this.showError = true
                            this.errorMsg = response['message']
                          }
                          else {
                            // Form submitted successfully
                            this.showSuccess = true
                          }
                        },
                        error => {
                          this.showLoader = false
                          this.showError = true
                          this.errorMsg = error
                        }
                      );
                  }
                },
                error => {
                  this.showLoader = false
                  this.showError = true
                  this.errorMsg = error
                }
              );
          }
        },
        error => {
          this.showLoader = false
          this.showError = true
          this.errorMsg = error
        }
      );
  }

  dismiss() {
    this.activeModal.dismiss()
  }

}
